footer {
  width: 100%;
  text-align: center;
  background-color: $white;
  padding: $su * 3 0;
  
  @include media-query('lap') {
    padding-bottom: $su * 8;
  }
  
  .social {
    border-bottom: solid 1px $black;
    max-width: 1040px;
    margin: 0 auto;
    padding-bottom: $su * 3;
    
    p {
      @include font-size(23px);
    }    
    
    ul {
      @extend .inline-list;
    }
    
    li {
      width: 70px;
      height: 70px;
      padding: 0;
      margin: 0 $su / 2;
      border-radius: $su / 2;
      overflow: hidden;
      display: block;
    }
    
    svg {
      width: 100%;
      height: 100%;
      position: relative;
    }
    
    .facebook path {
      fill: #3460a1;
    }
    
    .twitter path {
      fill: #28aae1;
    }
    
    .linkedin path {
      fill: #136d9d;
    }
  }
  
  .menus {
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    padding-top: 2 * $su;
    text-align: left;
    
    p {
      color: #595959;
      @include font-size(17px);
    }
    
    ul {
      margin: 0 0 $su * 2 0;
      padding: 0;
      list-style-type: none;
    }
    
    li {
      padding: 3px 0;
    }
    
    a {
      color: #2a3e87;
      font-weight: 300;
    }
  }
}