/*
**   General styles
*/

.profile--crew, .profile--messaging, .profile--owner {  
  @include media-query('phablet') {
    padding-left: $su;
    padding-right: $su;
  }
}




.profile--favourites {
  .favourite {
    .td--profile {
      width: 20%;
    }
    
    .td--notes {
      width: 60%;
    }
    
    .td--actions {
      width: 20%;
      
      p {
        margin: 0;
      }
    }
  }
  
  .favourite + .favourite {
    border-top: solid 1px $silver;
  }
}



/*
**   CV
*/

ul.cv--icons {
  list-style-type: none;
  margin: 0;
}

.cv--icons li {
  margin-bottom: $su / 4;
  padding-left: $su * 1.5;
}

.cv--icons .cv--icon-view {
  background: url('../../img/icons/icon-cv-view.png') no-repeat 0 50%;
}
.cv--icons .cv--icon-delete {
  background: url('../../img/icons/icon-cv-delete.png') no-repeat 2px 50%;
}
.cv--icons .cv--icon-privacy {
  background: url('../../img/icons/icon-cv-privacy@2x.png') no-repeat 0 50%;
  background-size: 17px 17px;
}




/*
**   References
*/

.reference {
  background-color: #f6f6f6;
  margin-bottom: $su;
  
  &.reference--status-1 {
    background-color: #fcf4e1;
  }
  &.reference--status-2 {
    background-color: #f2fdf2;
  }
  
  .reference--header {
    color: #fff;    
    background-color: #4c687a;
    padding: 1px;
    
    p {
      margin: 0;
      line-height: 24px;
    }
  }
  
  .reference--status p {
    padding-left: $su;
  }
  
  .status--pending {
    color: #e69f07;
  }
  .status--validated {
    color: #13c900;
  }
  
  .reference--ordinal {
    font-weight: 700;
    text-align: center;
  }
  
  .reference--delete {
    text-align: right;
    
    a {
      width: 24px;
      height: 24px;
      font-family: sans-serif;
      background-color: white;
      color: black;
      font-weight: bold;
      text-align: center;
      line-height: 24px;
      display: inline-block;
    }
  }
  
  .reference--content {
    padding: $su;
    
    p {
      text-align: center;
    }
    
    .label {
      font-weight: bold;
      display: block;
    }
  }
  
  .reference--request-button {
    padding-bottom: $su;
    text-align: center;
  }
  
  .reference--request-form {
    padding: $su;
    background: #ffedd5;
    
    input[type="email"] {
      width: 90%;
    }
    
    .buttons {
      text-align: center;
      padding-bottom: 0;
    }
  }
  
  
//  
//  & + .reference {
//    margin-top: $su;
//    padding-top: $su;
//    border-top: solid 1px $light-gray;
//  }  
//  
//  p.reference--info {
//    color: $teal;
//    margin-bottom: 0;
//    
//    span {
//      font-weight: 700;
//    }
//  }
//  
//  p.reference--description {
//    margin-top: halve($su);
//    padding: halve($su) $su;
//    color: $teal;
//    background-color: $modal-light-gray;
//  }
//
//  p.status-indicator {
//    font-style: normal;
//    padding-left: $su;
//    padding-right: $su;
//    margin: 0 0 quarter($su) 0;
//  }
//  
//  .reference--header {
//    padding: 0 halve($su) quarter($su) 0;
//
//    p {
//      color: $black;
//      margin: 0;
//
//      span {
//        color: $gray;
//      }
//    }
//
//    p.status--confirmed {
//      color: $white !important;
//      float: right;
//    }
//  }
//
//  > p {
//    padding: quarter($su) halve($su) quarter($su) 0;
//    font-style: italic;
//  }
}

.reference--preview {
  margin-bottom: $su;
  
  h3 {
    @include font-size(18px);
    color: $blue;
    margin-bottom: halve($su);
  }
  
  .info {
    padding-top: $su;
    
    @include media-query('phablet') {
      padding-top: 0;
      padding-left: $su;
    }
  }
}

.form--reference {  
  label {
    font-weight: 700;
    padding-bottom: quarter($su);
  }
  
  .entry {
    margin-bottom: $su;
  }
}





.profile h2.user-level--current {
  @include font-size(30px);
  font-weight: 700;
  margin-top: 0;
  margin-bottom: $su * 2;
  
  .level {
    text-transform: uppercase;
    color: $bright-blue;
  }
}

.user-level--features {
  text-align: left;
  padding: 0 halve($su);
  
  @include media-query('lap') {
    text-align: center;    
  }
  
  .banner {
    min-height: 200px;
    border: solid 1px $gray;
    border-radius: 10px;
    padding: $su;
    margin-bottom: $su;
    box-sizing: border-box;
    text-align: center;
    
    img {
      margin-bottom: halve($su);
    }
  }
  
  ul {
    list-style-type: none;
    margin: 0 0 $su 0;    
    
    li {
      min-width: 51%;
      padding: 5px 0;
      display: inline-block;
      
      &.can {
        background: url('../../img/feature-tick.gif') no-repeat left;
        padding-left: 25px;
      }
      
      &.unlimited {
        
      }
    }
  }
}

.payment-history {
  border: solid 1px $dark-blue;
  
  thead {
    td {
      background-color: $dark-blue;
      color: $white;
    }
  }
  
  td {
    padding: 3px $su / 2;
  }
  
  tr:nth-child(even) {
    background-color: #f5f5f5;
  }
}




/*
**   Profile Modal
*/

.profile--modal {
  position: relative;
  max-width: 880px;
  margin: 0 auto;
  padding: 0 $su 0 $su;
  background-color: $white;
  box-sizing: border-box;

  > .tab-contact {
    padding: quarter($su) quarter($su) + 2;
    background-color: $bright-blue;
    color: $white;
    font-weight: bold;
    display: inline-block;
    padding: quarter($su) $su;
    cursor: pointer;
    z-index: 15;
    position: absolute;
    top: 0;
    right: 3 * $su;

    @include media-query('phablet') {
      top: 2 * $su;
      right: 3 * $su;
    }

    @include media-query('lap') {
      display: none;
    }
  }
}

.profile--modal-menu {    
  width: 100%;
  max-width: 880px - (2 * $su);
  z-index: 11;
  display: none;
  
  @include media-query('phablet') {
    display: block;
  }
  
  @include media-query('lap') {
    position: fixed;
    top: 0;
  }
  
  .info-tabs {
    width: 100%;
    max-width: 100%;
    background: url('../../img/black-80.png');
    padding-left: $su;
    
    li {
      display: inline-block;
      margin-left: $su - 5px;
      color: $modal-light-gray;
      padding: halve($su) 0;
    }    
  }
  
  li.tab-contact {
    display: none;
    position: absolute;
    top: 0px;
    right: 7px;
    
    @include media-query('lap') {
      display: inline-block;
    }
    
    span {
      background-color: $bright-blue;
      color: $white;
      font-weight: bold;
      padding: quarter($su) $su;
      cursor: pointer;      
    }
  }

  .close {
    width: 2 * $su;
    height: 1.5 * $su;
    background-color: $white;
    position: absolute;
    cursor: pointer;
    z-index: 11;
    text-align: center;
    top: 0px;
    right: 0px;
    display: none;
    
    @include media-query('lap') {
      width: 2 * $su;
      height: 2 * $su;
      border-radius: 0 10px 10px 0;   
      top: $su;
      right: -3 * $su;
      display: inline-block;
    }
    
    img {
      max-width: 40%;
      margin-top: 20%;
      margin-right: 2px;
      
      @include media-query('lap') {
        max-width: 60%;
      }
    }
  }
}

.profile--modal-header {
  width: 100%;
  z-index: 10;
//  position: fixed;
  position: relative;
//  padding-top: $su;
  background-color: $white;
  
  @include media-query('lap') {
    height: 340px;
  }
  
  .bg {
    height: 100%;
    background-size: cover;
  }

  .close {
    width: 2 * $su;
    height: 1.5 * $su;
    background-color: $white;
    position: absolute;
    cursor: pointer;
    z-index: 11;
    text-align: center;
    top: 0px;
    right: 0px;
    
    @include media-query('lap') {
      width: 2 * $su;
      height: 2 * $su;
      border-radius: 0 10px 10px 0;   
      top: $su;
      right: -3 * $su;
      display: none;
    }
    
    img {
      max-width: 40%;
      margin-top: 20%;
      margin-right: 2px;
      
      @include media-query('lap') {
        max-width: 60%;
      }
    }
  }

  .overlay {
    background: url('../../img/black-50.png');
    padding: quarter($su) 0 halve($su) 0;
    
    @include media-query('lap') {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    h1 {
      color: $white;
      margin-bottom: 0;
    }

    p {
      color: $white;
      margin: 0;

      span {
        color: $silver;
      }
    }
  }
  
  .profile-name {
    padding: halve($su) 0 0 2 * $su;
    
    @include media-query('lap') {
      padding: halve($su) 0 quarter($su) 0;
    }
    
    h1 {
      @include font-size(36px);
      font-weight: 500;      
    }
  }
  
  .profile-status {
    padding: 0 0 halve($su) 2 * $su;
    
    @include media-query('lap') {
      padding: 0 0 halve($su) 0;
    }
  }
  
  .profile-image {
    width: 206px;
    width: 226px;
    height: 206px;
    height: 226px;
    background-color: $modal-light-gray;
    border: solid 3px $black;
    position: absolute;
    bottom: $su * -2;
    left: $su;
    z-index: 11;
    overflow: hidden;
    display: none;
    
    img {
      width: 100%;
      max-width: 100%;
    }
    
    @include media-query('lap') {
      display: block;
    }
  }
}

.profile--modal-crew {
  .profile--modal-header .bg {
    background: url('../../img/bg-crew-modal-default-2.jpg') no-repeat top left;
    background-size: cover;      
  }
}

.profile--modal-content {
  padding-right: 2 * $su;
}

.profile-badges {
  text-align: right;   
  padding-bottom: $su;
  
  @include media-query('phablet') {
    padding-bottom: 0;
    margin-bottom: -1 * $su;
    min-height: $su;
  }
  
  @include media-query('desk') {
    margin-top: -2 * $su;
    min-height: 3 * $su;
  }

  .badge {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase;
    margin-left: $su;
    
    a {
      @include body-font;  
      @include font-size(10px);
      font-weight: 700;
      display: block;
    }
    
    img {
      margin-bottom: 0;
//      max-height: 25px;
    }
        
    img.badge--locked {
      opacity: 0.5;
    }
    
    a.badge--locked {
      color: $mid-gray;
    }
  }
}

.profile--modal-body {
  box-sizing: border-box;
  z-index: 5;
  background-color: $modal-light-gray;
//  padding: 380px $su $su $su;
  padding: $su;
  
  @include media-query('lap') {
    padding-top: 3 * $su;
  }
  
  h3 {
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 0;
    background-color: $modal-dark-gray;
    color: $white;
    padding: halve($su) (1.5 * $su);

    @include media-query('phablet') {
      min-width: 206px;
      min-width: 226px;
      display: inline-block;
    }
  }

  p.entry  {
    .label {
      color: $black;
      
      &.top {
        vertical-align: top;
      }
    }

    .value {
      color: $dark-gray;
    }
    
    &.underline {
      border-bottom: solid 1px $modal-light-gray;
      padding-bottom: halve($su);
      margin-bottom: halve($su);
    }
  }
  
  div.entry {
    label {
      color: $black;
    }
  }
  
  .btn.close {
    width: 200px;
    margin: 0 auto;
    display: block;
  }

  .info-tabs {
    @include media-query('lap') {
      padding-left: $su;
    }      

    li {
      color: $gray;
      text-transform: uppercase;
      padding-left: $su;
      border-top: solid 1px transparent;
      border-bottom: solid 1px transparent;

      &.active {
        color: $black;
        border-color: $silver;
      }
    }
  }
      
  .info-page {
    padding-bottom: $su; 
    
    .content {
      background-color: $white;
      border-top: solid 1px $black;
      border-bottom: solid 1px $black;
      padding: $su 1.5 * $su;
    }
  }

  p.advert-title {
    color: $black;
    margin-bottom: halve($su);
    @include font-size(16px);
  }
  
  .profile--thumbnails {
    a {
      height: 3 * $su;
      border: solid 1px $black;
      margin-right: $su;
      display: inline-block;

      img {
        max-height: 100%;
      }      
    }
  }

  .qualification {
//    background-color: $light-gray;
    padding-bottom: quarter($su);

    p {
      margin-bottom: 0;
      text-align: center;

      @include media-query('lap') {
        text-align: left;
      }
    }

    .qualification--date {
    }

    .qualification--title {
    }

    .qualification--status {
    }

    .qualification--status-complete {
      color: $green;
    }

    .qualification--status-incomplete {
      color: $orange;
    }
  }
    
  .qualification + .qualification {
    border-top: solid 1px $modal-light-gray;   
    padding-top: quarter($su);
  }

  .log {
//    border: solid 1px $light-gray;
    margin-bottom: halve($su);
    
    .log--header {
//      background-color: $light-gray;
      @include media-query('phablet') {
        text-align: center;    
      }      

      p {
        margin-bottom: 0;
        color: $black;
      }

      .log--date {
        @include media-query('lap') {
          text-align: left;
        }
      }

      .log--category {
//        font-weight: bold;
      }

      .log--duration {
        @include media-query('lap') {
          text-align: right;
        }
      }
    }

    > p {
      padding: quarter($su) 0;
      margin: 0;
    }
  }
  
  .log + .log {
    border-top: solid 1px $modal-light-gray;
    padding-top: halve($su);
  }

  .skills {
    list-style: none;
    margin: 0;

    li {
      background-color: $light-gray;    
      margin: 0 quarter($su) quarter($su) 0;   
      padding: quarter($su) halve($su);
      display: inline-block;
    }
  }
  
  .reference--form-intro {
    margin-top: $su;
    margin-bottom: 0;
    color: $black;
  }

  .reference--form-trigger {
    cursor: pointer;
  }

  .reference {
    margin-bottom: $su;
    border-bottom: solid 1px $light-gray;
    border-top: none;
    padding-top: 0;
    background-color: transparent;

    .reference--header {
      background-color: transparent;
      padding: 0 halve($su) quarter($su) 0;

      p {
        color: $black;
        margin: 0;

        span {
          color: $gray;
        }
      }
    }

    > p {
      padding: quarter($su) halve($su) quarter($su) 0;
      font-style: italic;
    }
  }
  
  .reference--form {
    padding-left: halve($su);
    margin: $su 0 double($su) 0;
    display: none;
    
    .entry {
      padding-bottom: $su;
      
      .error {
        margin-bottom: 0;
      }
    }
    
    .error-main {
      display: none;
    }
  }
  
  .team-member {
    p {
      text-align: center;
    }
    
    .image {
      overflow: hidden;
      border-radius: 15px;
    }
  }

  .review {
    margin-bottom: $su;
    
    .review--title {
      font-style: italic;
      margin-bottom: 0;   
      color: $bright-blue;
    }
    
    .review--review {
      margin-bottom: 0;      
    }
    
    .review--date {
      border-top: solid 1px $modal-light-gray;
      margin-top: halve($su);
      padding-top: halve($su);
      color: $gray;    
    }
    
    .review--reviewer {
      text-align: center;
    }
    
    .review--image {
      width: 80%;
      height: 80%;
      margin: 0 auto halve($su) auto;
      overflow: hidden;
      border-radius: 15px;
    }
  }
  
  .review--form-intro {
    margin-top: $su;
    margin-bottom: quarter($su);
    padding-top: $su;
    border-top: solid 1px $modal-light-gray;
    color: $black;
  }
  
  .review--form-subintro {
    margin-bottom: 0;
  }

  .review--form-trigger {
    cursor: pointer;
  }
  
  .review--form {
    margin: $su 0 double($su) 0;
    display: none;
    
    .entry {
      padding-bottom: $su;
      
      .error {
        margin-bottom: 0;
      }
    }
    
    .error-main {
      display: none;
    }
  }
  
  .application--form {
    h4 {
      margin-bottom: 0;
    }
    
    .entry {
      padding-bottom: 2 * $su;
    }
    
    .certificate {    
      padding-bottom: quarter($su);
      
      select {
        width: auto;
      }
    }
    
    .suitability {
      select {
        width: auto;
      }
    }
    
    .notes {
      p {
        width: 90%;
      }
    }
  }

  .spotlight {
    margin: 0 0 $su 0;

    .question {
      font-weight: 400;
      margin-bottom: 0;
      color: $black;
    }

    .answer {
      font-style: normal;
    }
  }
  
  .contact {
    max-width: 600px;
    
    p {
      @include font-size(19px);
      padding: halve($su) 0;
      margin-bottom: quarter($su);
    }
    
    .label {
      background-color: $bright-blue;
      border: solid 1px $bright-blue;
      color: $white;
      padding-left: 50px;
    }
    
    .value {
      border: solid 1px $bright-blue;
      padding-left: $su;
      padding-right: $su;
      color: $black;
    }
  }

  .contact--phone .label {
    background-image: url('../../img/icon-button-phone.png');
    background-repeat: no-repeat;
    background-position: 10px 50%;
  }
  .contact--skype .label {
    background-image: url('../../img/icon-button-skype.png');
    background-repeat: no-repeat;
    background-position: 10px 50%;
  }
  .contact--url .label {
    background-image: url('../../img/icon-button-url.png');
    background-repeat: no-repeat;
    background-position: 10px 50%;
  }
}

#ModalContactForm {
  padding-bottom: 2 * $su;
//  margin-bottom: 2 * $su;
//  border-bottom: solid 1px $modal-light-gray;

  .error-main {
    display: none;
  }
  
  .btn--send {
    margin-top: quarter($su);
    padding-left: 50px;
    background-image: url('../../img/icon-button-send.png');
    background-repeat: no-repeat;
    background-position: 10px 50%;
  }
  
  .countdown {
    padding-right: 0;
  }
  
  .countdown--explanation {
    background-color: $modal-light-gray;
    margin-right: 0;
  }
  
  .countdown--close span {
    color: $dark-gray;
  }
}

#ModalContent.profile {
  overflow-y: auto;
  background-color: transparent;
}

#ModalApplicationForm {  
  .countdown {
    padding-right: 0;
  }
  
  .countdown--explanation {
    background-color: $modal-light-gray;
    margin-right: 0;
  }
  
  .countdown--close span {
    color: $dark-gray;
  }
}


/*
**   Single
*/

.profile--single {
  @extend .floating-box;
      
  .section {
    margin-top: $su;
  }
  
  .section-privacy {
    margin-top: 3 * $su;
  }
  
  .label {
    vertical-align: top;
  }
  
  img.profile-photo {
    width: 100%;
    max-width: 10 * $su;
  }
  
  span.skill + span.skill {
    border-left: solid 1px $silver;
    margin-left: 5px;
    padding-left: 5px;
  }
  
  span.qualification + span.qualification {
    border-left: solid 1px $silver;
    margin-left: 5px;
    padding-left: 5px;
  }
    
  .job + .job {
    margin-top: $su;
    padding-top: $su;
    border-top: solid 1px $silver;
  }
}