section.dashboard {
  
  
  ul.listing {
    list-style-type: none;
    margin: 0;
    padding: 0;
    
    li {
      position: relative;
    }
  }
}

.box--dashboard {
  background-color: transparent;
  padding: 0;
  
  
  .heading {
    background-color: rgba(0, 0, 0, 0.3);
    background-repeat: no-repeat;
    background-position: 15px center;
    padding: 0 $su 0 50px;
    margin: 0;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    height: 1.5 * $su;
    line-height: 1.5 * $su;
  }
  
  .content {
    background-color: $white;
//    padding: $su / 2;
    padding: 0;
  }
  
  .buttons {
    padding: $su $su $su / 2 $su;
    text-align: center;
  }
  
  .listing {
    li {
//      padding: $su / 2;
      padding: 0;
      
      border-bottom: solid 1px $modal-light-gray;
      
      &:hover {
        background-color: $dashboard-hover-color;
      }
    }
  }
  
  .listing--info {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .empty--title {
    @include font-size(17px);
    font-weight: 700;
    color: $black;
    margin-bottom: $su;
    padding-top: $su;
    text-align: center;
  }
  
  .empty--subtitle {
    margin: 0 15%;
    padding-bottom: $su;
    text-align: center;
  }
}
 
.box--dashboard-profile {  
  .bg {
    width: 100%;
    height: 50%;
    min-height: 180px;
    background-color: transparent;
    background-size: cover;
    background-position: center;
    position: absolute; 
    top: 0;
    z-index: 1;
    
    @include media-query('below-mob') { 
      background-image: none !important;
      background-color: #fff !important;
    }
  }
  
  .bg-white {
    width: 100%;
    height: 50%;
    background-color: $white;
    position: absolute; 
    bottom: 0;
    z-index: 2;
  }
  
  .bg-transparent {
    width: 100%;
    height: 85%;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute; 
    bottom: 0;
    z-index: 1;
    display: none;
    
    @include media-query('phablet') {
      display: block;
    }
  }
  
  .heading {
    height: auto;
    @include font-size(25px);
    padding: $su / 2 0 $su / 2 $su;
    
    span {
      font-weight: 400;
      text-transform: none;
    }
  }
  
  .content {
    background-color: $modal-light-gray;
    position: relative;
    padding: 0 0 $su 0;
    
    > .layout {
      z-index: 5;
      position: relative;
    }    
    
    .pad {
      padding: 0 $su;
      
      @include media-query('phablet') {
        padding: 0;
      }
    }
    
    .edit-bg {
      position: absolute;
      top: 7px;
      right: 0;
      text-align: right;
      cursor: pointer;
      margin: 0;
      padding: 3px 15px;
      z-index: 5;
      display: none;
      
      @include media-query('desk') {
        display: block;
      }
      
      span {
        display: none;
        margin-right: $su / 2;
        vertical-align: middle;
        color: $white;
      }
      
      img {
        vertical-align: middle;
      }
      
      &:hover {
        background-color: rgba(0,0,0, 0.5);
        
        span {
          display: inline-block;
        }
      }
    }
  }
  
  .image {
    text-align: center;
  }
  
  .avatar {
    width: 80%;
    max-width: 240px;
    margin: $su auto;
    position: relative;
    
    .profile-photo {
      width: 100%;
      border-radius: $su !important;
      border: solid 1px $white;
    }
    
    .edit {
      position: absolute;
      bottom: 15px;
      right: 1px;
      text-align: right;
      color: $white;
      cursor: pointer;
      margin: 0;
      padding: 3px 10px 3px 15px;
      
      span {
        display: none;
        margin-right: $su / 2;
        vertical-align: middle;
      }
      
      img {
        vertical-align: middle;
      }
      
      a, a:hover {
        color: $white;
      }
    }
    
    &:hover .edit {
      background-color: rgba(0,0,0, 0.5);
        
      span {
        display: inline-block;
      }
    }
  }
  
  h1 {
    @include font-size(29px);
    font-weight: 700;
    color: $gray-blue;
    margin: $su / 2 0 0 0;
  }
  
  .stats {
    height: 180px;
    position: relative;
    
    ul {
      @extend .inline-list;     
      position: absolute;
      bottom: $su;
    }
    
    li {
      width: 4 * $su;
      height: 4 * $su;
      border-radius: 2 * $su;
      background-color: $white;
      text-align: center;
      vertical-align: middle;
      margin-right: $su;
      @include font-size(12px);
      line-height: 1.0em;
    }
    
    span {
      display: block;
    }
    
    .count {
      @include font-size(31px);
      line-height: 1.0em;
      color: $bright-blue;
      margin-top: 8px;
    }
    
    .title {
      @include font-size(15px);
      line-height: 1.2em;
      font-weight: 400;
      color: $black;
    }
  }
  
  ul.links {
    @extend .inline-list;
    display: inline-block;
    vertical-align: top;
    
    @include media-query('phablet') {
      width: 50%;
    }
    
    li {
      width: 100%;
      padding-top: $su / 4;
      padding-bottom: $su / 4;
    }
    
    a {
      padding-left: 25px;
      background-position: 0 center;
      background-repeat: no-repeat;
      display: block;
    }
      
    .edit-advert a {
      background-image: url('../../img/icons/icon-dashboard-advert.png');
    }
    .edit-profile a {
      background-image: url('../../img/icons/icon-dashboard-profile.png');
    }
    .edit-photos a {
      background-image: url('../../img/icons/icon-dashboard-photos.png');
    }
    .view-advert a {
      background-image: url('../../img/icons/icon-dashboard-view.png');
    }
    .view-inbox a {
      background-image: url('../../img/icons/icon-dashboard-inbox.png');
    }
    .share-profile a {
      background-image: url('../../img/icons/icon-dashboard-share.png');
    }
    .upgrade a {
      background-image: url('../../img/icons/icon-dashboard-premium.png');
    }
  }  
}


// Latest matches

.box--dashboard-latest {
  .heading {
    background-image: url('../../img/icons/icon-menu-latest.png');
  }
  
  .content {
//    min-height: 700px;    
    padding: $su / 2;
  }
  
  .intro {
    padding-bottom: $su;
    border-bottom: solid 1px $silver;
  }
  
  .listing {
    li {      
      border-bottom: solid 1px $silver;
      padding-bottom: $su / 2;
      display: block;
    }
  }
  
  p {
    margin: 0;
  }
  
  .username {
    font-weight: 700;
  }
  
  .checkin {
    font-size: 0.9em;
    color: $black;
  }
}


// Candidates
 
.box--dashboard-candidates {
  .heading {
    background-image: url('../../img/icons/icon-menu-candidates.png');
  }
  
  li {
    width: 100%;
  }
  
  .read-0 { 
    background-color: $light-blue;
  }
  
  p {
    margin: 0;
  }
  
  .username {
    font-weight: 700;
  }
}


// Messages
 
.box--dashboard-messages {
  .heading {
    background-image: url('../../img/icons/icon-menu-messaging.png');
  }
  
  li {
    width: 100%;
  }
  
  .read-0 { 
    background-color: $light-blue;
    
    .date {
      background: url('../../img/icon-message-unread.png') no-repeat left center;      
    }
  }

  .read-1 {
    .date {
      background: url('../../img/icon-message-read.png') no-repeat left center;
    }
  }
  
  p {
    margin: 0;
  }
  
  .username {
    font-weight: 700;
  }
  
  .date {
    padding-left: $su;
  }
}


// Alerts
 
.box--dashboard-alerts {
  .heading {
    background-image: url('../../img/icons/icon-menu-alerts.png');
  }
  
  li {
    width: 100%;
  }
  
  .read-0 { 
    background-color: $light-blue;
  }
  
  p {
    margin: 0;
  }
  
  .username {
    font-weight: 700;
  }
}


// History
 
.box--dashboard-history {
  .heading {
    background-image: url('../../img/icons/icon-menu-history.png');
  }
  
  li {
    width: 100%;
  }
  
  p {
    margin: 0;
  }
  
  .username {
    font-weight: 700;
  }
}


// Favourites
 
.box--dashboard-favourites {
  .heading {
    background-image: url('../../img/icons/icon-menu-favourites.png');
  }
  
  li {
    width: 100%;
  }
  
  p {
    margin: 0;
  }
  
  .username {
    font-weight: 700;
  }
}