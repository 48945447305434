h1, .alpha,
h2, .beta,
h3, .gamma,
h4, .delta,
h5, .epsilon,
h6, .zeta {
	// mixin for bold font here
	@include header-font();
	color: $black;
	// 24px is too large - open sans font margin fix
	margin-bottom: 16px;

	/* removing text decoration from all headline links */
	a {
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}

h4, .delta,
h3, .gamma {
	@include font-size(12px, 1.5);
	font-weight: 700;
}

h3, .gamma {
	@include font-size(14px, 1.5);
}

.title {
	@include font-size(24px);
	font-family: $header-font;
	font-weight: 700;
}
.title--small {
	@include font-size(15px);
	font-family: $header-font;
	font-weight: 700;
}