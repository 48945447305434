.btn {
  @include font-size(17px);
  
  &:disabled {
    opacity: 0.3;
  }
}

.btn--small {
  @include font-size(13px);
}

.btn--blue, .btn--primary {
  background-color: $bright-blue;
  color: $white;
  
  &:hover, &:focus {
    background-color: $hover-blue;
    border-color: $hover-blue;
  }
}

.btn--primary {
  border: solid 1px $white;
}

.btn--white, .btn--secondary {
  background-color: $white;
  color: $bright-blue;
  border-color: $bright-blue;
  
  &:hover, &:focus {
    color: $mid-blue;
    border-color: $mid-blue;
  }
}

.btn--bold {
  border-width: 2px;
  font-weight: 700;
  text-transform: uppercase;
}

.btn--dark {
  background-color: $dark-blue;
  border-color: $dark-blue;
  color: $white;
}

.btn--outline-bright {
  color: $bright-blue;
  border-color: $bright-blue;
}

.btn--outline-white {
  color: $white;
  border-color: $white;
  background-color: transparent;
}

.btn--gray {
  background-color: $gray;
  border-color: $gray;
}

.btn--save {
//  padding-left: 2 * $su;
//  background-image: url('../../img/icon-button-save.png');
//  background-repeat: no-repeat;
//  background-position: halve($su) 50%;
  
  &.btn--small {
//    background-size: 15px 15px;
  }
}

.btn--send {
  padding-left: 2.5 * $su;
  background-image: url('../../img/icons/icon-button-send@2x.png');
  background-size: 26px 17px;
  background-repeat: no-repeat;
  background-position: halve($su) 50%;
  
  &.btn--small {
    background-size: 15px 15px;
  }
}

.btn--rotate {
  background-color: $white;
  background-image: url('../../img/icon-rotate.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  border-color: $bright-blue;
  border-width: 2px;
  box-sizing: border-box;
  color: $bright-blue;
  display: inline-block;
  font-weight: 700;
  padding-left: 40px;
  
  &:hover {
    color: $bright-blue;
  }
  
  &.btn--rotate-icon {
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-position: center center;
    background-size: 16px 16px;
    padding: 0;
  }
}

.btn--photo-delete {
  width: 32px;
  height: 32px;
  line-height: 32px;
  padding: 0;
  background: url('../../img/icon-photo-delete.png') no-repeat center center;
  border: solid 2px $gray;
}

.btn--pad {
  padding-left: 3 * $su;
  padding-right: 3 * $su;
}