/*------------------------------------*\
    #TABLES
\*------------------------------------*/

// Predefine the variables below in order to alter and enable specific features.
$inuit-table-namespace:         $inuit-namespace !default;

$inuit-table-padding--compact:  quarter($inuit-base-spacing-unit) !default;
$inuit-table-padding--cosy:     halve($inuit-base-spacing-unit) !default;
$inuit-table-padding--comfy:    $inuit-base-spacing-unit !default;

$inuit-table-border-width:      1px !default;
$inuit-table-border-style:      solid !default;
$inuit-table-border-color:      #ccc !default;

$inuit-enable-table--fixed:     false !default;
$inuit-enable-table--compact:   false !default;
$inuit-enable-table--cosy:      false !default;
$inuit-enable-table--comfy:     false !default;
$inuit-enable-table--cells:     false !default;
$inuit-enable-table--rows:      false !default;
$inuit-enable-table--columns:   false !default;





.#{$inuit-table-namespace}table,
%#{$inuit-table-namespace}table {
    width: 100%;
}





@if ($inuit-enable-table--fixed == true) {

    /**
     * Force tables into having equal-width columns.
     */
    .#{$inuit-table-namespace}table--fixed,
    %#{$inuit-table-namespace}table--fixed {
        table-layout: fixed;
    }

}





@if ($inuit-enable-table--compact == true) {

    /**
     * Tables with very tightly packed cells.
     */
    .#{$inuit-table-namespace}table--compact,
    %#{$inuit-table-namespace}table--compact {
    
        th,
        td {
            padding: $inuit-table-padding--compact;
        }
    
    }

}





@if ($inuit-enable-table--cosy == true) {

    /**
     * Lightly packed cells.
     */
    .#{$inuit-table-namespace}table--cosy,
    %#{$inuit-table-namespace}table--cosy {
    
        th,
        td {
            padding: $inuit-table-padding--cosy;
        }
    
    }

}





@if ($inuit-enable-table--comfy == true) {

    /**
     * Tables with very widely padded cells.
     */
    .#{$inuit-table-namespace}table--comfy,
    %#{$inuit-table-namespace}table--comfy {
    
        th,
        td {
            padding: $inuit-table-padding--comfy;
        }
    
    }

}





@if ($inuit-enable-table--cells == true) {

    /**
     * Add borders around a `table`.
     */
    .#{$inuit-table-namespace}table--cells,
    %#{$inuit-table-namespace}table--cells {
    
        th,
        td {
            border: $inuit-table-border-width $inuit-table-border-style $inuit-table-border-color;
        }
    
    }

}





@if ($inuit-enable-table--rows == true) {

    /**
     * Add borders only to `table`’s rows.
     */
    .#{$inuit-table-namespace}table--rows,
    %#{$inuit-table-namespace}table--rows {
    
        &,
        th,
        td {
            border: 0 $inuit-table-border-style $inuit-table-border-color;
        }
    
    }
    
    .#{$inuit-table-namespace}table--rows,
    %#{$inuit-table-namespace}table--rows {
        border-top-width: $inuit-table-border-width;
    
        th,
        td {
            border-bottom-width: $inuit-table-border-width;
        }
    }

}





@if ($inuit-enable-table--columns == true) {

    /**
     * Add borders only to `table`’s columns.
     */
    .#{$inuit-table-namespace}table--columns,
    %#{$inuit-table-namespace}table--columns {
    
        &,
        th,
        td {
            border: 0 $inuit-table-border-style $inuit-table-border-color;
        }
    }
    
    .#{$inuit-table-namespace}table--columns,
    %#{$inuit-table-namespace}table--columns {
        border-left-width: $inuit-table-border-width;
    
        th,
        td {
            border-right-width: $inuit-table-border-width;
        }
    }

}
