.font-regular {
  font-family: $font--primary;
  font-weight: 400;
}

.font-bold {
  font-family: $font--primary;
  font-weight: 600;
}


h1 {
  @include header-font();
  @include font-size(41px);
  line-height: 1.1em;
  
  a:hover {
    text-decoration: none;
  }

  .separator {
    padding: 0 halve($su);
  }
}

h2 {
  @include header-font;
  @include font-size(18px);
  font-weight: 700;
  color: $menu-selected-color;
}

h3 {
  @include header-font;
  font-weight: 400;
  margin-bottom: 0;
}

h1 + h2 {
  margin-top: double($su);
}

p {
  margin-bottom: 15px;
    
  &.welcome {
    font-family: Verdana;
    font-weight: 300;
    font-size: 3.0em;
    text-align: center;
    color: silver;
    margin: 100px 100px 0 100px;
  }
  
  &.large {
    @include font-size(15px);
  }
  
  &.small {
    @include font-size(11px);
  }
}

.strong {
  font-weight: bold;
}

.highlight {
  font-weight: bold;
  color: $bright-blue;
}

.underline {
  text-decoration: underline;
}

.blue {
  color: $bright-blue;
}

.black {
  color: $black;
}

.face-gray {
  color: $mid-gray !important;
  
  li, a {
    color: $mid-gray !important;
  }
}

.face-white {
  color: $white !important;
  
  li, a {
    color: $white !important;
  }
}

.face-red {
  color: $red !important;
  
  li, a {
    color: $red !important;
  }
}

.face-blue {
  color: $blue !important;
  
  li, a, .face-red & {
    color: $blue !important;
  }
}

.text--info {
  .highlight {
    color: $black;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }
  
  ul {
    list-style-type: none;
    margin: 0;

    li:before {
      content: "- ";
    }
  }
}

ul.dashed {
  list-style-type: none;
  margin: 0;

  li {
    padding-left: halve($su);
    background: url('../../img/bullet-dash.gif') no-repeat 0 10px;
  }
  
  &.padded li {
    padding-left: 2 * $su;
    line-height: 1.7em;
  }
}

ul.ticks {
  list-style-type: none;
  margin: 0;
  
  li {
    padding: 3px 0 3px 25px;
    background: url('../../img/list-tick.png') no-repeat 0 7px;
  }
}

.box.profile {
  h1 {
    @include font-size(31px, 35px);
    border-bottom: solid 1px $gray;
  }
  
  h2 {
    @include font-size(18px);
    padding-bottom: 0;
    margin-bottom: halve($su);
  }
  
  .preface {
    @include font-size(11px, 11px);
    text-transform: uppercase;
    margin: 0;
  }
}

.box--membership {
  p, li {
    color: $font-black;
  }
  
  .alternative {
    p, li {
      color: $font-black;
    }
  }
  
  .premium-title {
    @include header-font;
    @include font-size(25px);
    height: 70px;
    line-height: 70px;
    font-weight: 700;
    color: $pale-green;
    padding-left: 100px;
    margin-bottom: $su;
    background: url('../../img/premium-shield.png') no-repeat 0 0;
  }
  
  .premium-heading {
    @include font-size(25px);
    color: #5db173;
    padding-left: 104px;
    padding-top: 25px;
    background: url('../../img/premium-heading.png') no-repeat 0 0;
    min-height: 79px;
    
    span {
      font-weight: 700;
    }
  }
  
  .verification-heading {
//    @include header-font;
    @include font-size(25px);
    height: 47px;
    line-height: 47px;
    font-weight: 300;
    color: #5db173;
    padding-left: 70px;
    margin-bottom: $su;
    background: url('../../img/verification-shield-green.png') no-repeat 0 0;
  }
  
  .verification-title {
    @include header-font;
    @include font-size(21px);
    font-weight: 700;
    color: $mid-gray;
    margin-top: 3 * $su;
    padding-left: 50px;
    background: url('../../img/verification-shield.png') no-repeat 0 0;
  }
    
  .alternative .verification-title {
    margin-top: 0;
  }
}



/*
** Payment success
*/

.payment-success {
  text-align: center;
  padding: 3 * $su 6 * $su;
  
  h1 {    
    @include header-font;
    @include font-size(25px);
    font-weight: 700;
    color: $pale-green;
    padding-top: 50px;
    margin-bottom: $su;
    background: url('../../img/verification-shield-green.png') no-repeat center 0;
  }
  
  p {
    color: $teal;
  }
  
  .btn {
    @include font-size(13px);
    font-weight: 700;
    text-transform: uppercase;
    border-width: 2px !important;
    margin-top: 2 * $su;
  }
}