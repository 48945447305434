.message-listing {
  .message {
    background-color: $white;
    padding: $su;
    margin-bottom: halve($su);
    
    background-image: url('../../img/message-new.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    
    @include media-query('phablet') {
      padding-left: 0;
      /*padding-right: 0;*/
    }
    
    p {
      margin: 0;
    }
    
    .td {
      vertical-align: middle;
      display: inline-block;
      padding: 0;
    }
    
    .td--mark {
      display: none;
      
      @include media-query('phablet') {
        width: 5%;
        text-align: center;
        display: inline-block;
      }
    }
    .td--message {
      width: 100%;
      padding-top: $su;
      padding-bottom: $su;
      
      @include media-query('phablet') {
        width: 50%;
        padding-top: 0;
        padding-bottom: 0;
      }      
    }
    .td--profile {
      width: 100%;
      
      @include media-query('phablet') {
        width: 30%;
      }      
      
      img {
        width: 3 * $su;
        margin-right: $su;
        float: left;
      }
    }
    .td--delete {
      width: 100%;
      text-align: center;
      
      @include media-query('phablet') {
        width: 15%;
      }
      
      a {
        text-align: center;
        display: inline-block;
      }
    }
    
    a.delete {
      width: 2.5 * $su;
      height: 2.5 * $su;
      border-radius: 1.5 * $su;
      border: solid 1px $light-gray;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      
      img {
        width: 12px;
        height: 12px;
        margin: 50% auto;
        transform: translateY(-50%);
        display: block;
      }
    }
  }
  
  .message--read {
    background-image: none;
  }
  
  .message--sent {
    background-image: none;
    
    .td--status {
      @include media-query('phablet') {
        width: 15%;
      }      
      
      span {
        font-weight: bold;
        padding-left: $su;
      }
      
      .read {
        background: url('../../img/icon-read.gif') no-repeat 0 center;
      }
      
      .unread {
        background: url('../../img/icon-unread.gif') no-repeat 0 center;
      }
    }
  }
  
  .message-btn--view {
    text-align: left;
    
    @include media-query('phablet') {
      text-align: right;
      padding-bottom: $su-small;
    }
    
    a {
      @extend .btn;
      @extend .btn--small;
      padding-left: $su-huge;
      padding-right: $su-huge;
            
      @include media-query('phablet') {     
        padding-left: $su;
        padding-right: $su;
      }
    }
  }
  
  .message-btn--delete {
    text-align: right;
    
    a {
      @extend .btn;
      @extend .btn--small;
      color: $gray;
      background-color: transparent;
      border-color: transparent;
      padding-left: 0;
      padding-right: 0;
      
      &:hover, &:focus {
        color: lighten($gray, 20%);
      }
            
      @include media-query('phablet') {     
        margin-right: 15px
      }
    }
  }
}

.message--headers {
  margin-bottom: 0;
  padding: $su 2 * $su;
  @extend .form-transparency;
    
  p {
    margin: 0;
    color: $black;
    @include font-size(21px);
    
    span {
      width: 100px;
      display: inline-block;
    }
  }
}

.message--content {
  border: solid 1px $pale-blue;
  margin-bottom: $su;
  
  p {
    padding: halve($su) $su;
  }
  
  .subject {
    background-color: $light-gray;
    border-top: solid 1px $light-blue;
    border-bottom: solid 1px $modal-light-gray;
    margin-bottom: halve($su);
  }
}

.message--actions {
  padding: $su 2 * $su;
  text-align: right;
  
  a {
    color: rgba(255, 255, 255, 0.5);
    margin-left: 2 * $su;
    display: inline-block;
  }
  
  a.btn {
    color: $white;
  }
  
  .fieldset-heading--primary & {
    display: none;
    
    @include media-query('phablet') {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.message--buttons {
  text-align: right;
}

.message--history {
  max-height: 400px;
  overflow-y: scroll;
//  padding-right: 2 * $su;
//  padding-left: 2 * $su;
  padding: $su 2 * $su 0 2 * $su;
  background-color: $light-gray;
  
  h3 {
    text-align: center;
    margin-bottom: halve($su);
  }
  
  .message {
    margin-bottom: $su;
    
    .status.read-0 {
      color: $red;
    }
  }
  
  p {
    margin: 0;
  }
  
  .description {
    max-width: 600px;
    padding: halve($su) $su;
    box-sizing: border-box;
    border-radius: $su;
    display: inline-block;
  }
  
  .byline {
//    font-size: 0.9em;
  }
  
  .date {
    color: $black;
    text-align: center;
  }
  
  img {
    width: 60px;
  }
}

.message--reply {
  background-color: $light-gray;
  
  .entry {
    padding: halve($su) $su 0 $su;
    margin-bottom: 0;
  }  
  
  textarea {
    width: 100%;
    border: solid 5px $silver;
    border-radius: 5px;
    
//    &::placeholder {
//      @include font-size(27px);
//    }
  }
  
  .buttons {
    margin: halve($su) 0 0 $su;
    padding-bottom: $su;
  }
}

.message--compose {
  background-color: $light-gray;
  margin-bottom: 0;
  padding: 0;
  
  .entry {
    padding: halve($su) $su 0 $su;
    margin-bottom: 0;
  }  
  
  textarea, input[type="text"] {
    width: 100%;
    border: solid 5px $silver;
    border-radius: 5px;
  }
  
  .buttons {
    margin: halve($su) 0 0 $su;
    padding-bottom: $su;
  }
}
  
#MessageTo {
  padding: $su $su 0 $su;
}

.message--you {
  text-align: right;
  
  .description {
//    margin-left: 4 * $su;
    margin-top: halve($su);
    background-color: $bright-blue;
    color: $white;
    text-align: left;
  }
  
  .description {
    margin-right: 10px;
  }
  
  .byline {
    margin-right: 80px;
  }
  
  img {
    float: right;
  }
}

.message--them {
  .description {
    margin-top: halve($su);
//    margin-right: 4 * $su;
    background-color: $modal-light-gray;
  }
  
  .description {
    margin-left: 10px;
  }
  
  .byline {
    margin-left: 80px;
  }
  
  img {
    float: left;
  }
}

.message--info {
  p {
    margin: 0;
  }
}

.message--address-book {  
  .contact {
    margin-botom: halve($su);
    cursor: pointer;
    border: solid 2px transparent;
    padding: $su / 4;    
    
    p {
      @include font-size(15px);
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }
    
    &:hover {
      border-color: $silver;
    }
    
    &.active {
      background-color: $light-gray;
      border-color: $silver;
    }
  }
  
  .contact--image {
    width: $su * 1.5;
    height: $su * 1.5;
    margin-right: halve($su);
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

.form--messaging {
  .entry {
    padding-bottom: halve($su);
  }
}

a.messaging-back {
  background: url('../../img/icon-back-applications.png') no-repeat 0px center;
  padding-left: 24px;
  @include font-size(19px);
}

#InboxForm {
  .buttons {
    padding: $su;
  }
}

#MessageTo {
  .to {
    @include font-size(21px);
    margin: 0;
  }
  
  .info {
    img {
      display: none;
    }
    
    p {
      @include font-size(21px);
    }
  }
}

.message-tabs {
  li {
  }
  
  a {
    text-align: center;
    background-color: $silver;
    display: block;
    padding: $su / 2 0;
    text-transform: uppercase;
    color: $black;
  }
  
  li:hover a {
    opacity: 0.9;
  }
    
  li.active a,
  li.active:hover a {
    background-color: $white;
    opacity: 1;
  }
}

.message-pagination {
  padding: $su;
  
  p {
    font-weight: 700;
    color: $black;
  }
  
  ul.pagination {
    list-style-type: none;
    margin: 0;
  }
  
  li {
    margin-right: 2px;
    display: inline-block;
    
    a, span {
      width: $su * 2;
      height: $su * 2;
      line-height: $su * 2;
      text-align: center;
      display: block;
      background-color: $white;
    }
    
    &.active {
      span {
        font-weight: 700;
        color: $white;
        background-color: $bright-blue;
      }
    }
  }
}



/*   Countdown   */

.countdown {
  text-align: right;
  padding-right: $su;
  
  > div {
    display: inline-block;
    vertical-align: bottom;
  }
    
  p {
    margin: 0;
    text-align: right;
  }
  
  .timer {
    margin-left: $su;
  }
  
  .countdown--number {
    background-color: $red;
    color: $white;
    text-align: center;
    display: inline-block;
    margin-left: $su / 4;
    padding: $su / 4 $su / 3;
    
    span {     
      padding: 0;
      display: block;
    }
    
    .number {
      @include font-size(30px); 
      line-height: 1.0;
    }
    
    .name {
      @include font-size(11px);
    }
  }
  
  #ApplicationForm & {
    padding-right: 0;
    margin-top: $su;
  }
}
  
.countdown--explanation {
  display: none;
  background-color: $white;
  padding: $su;
  margin-top: $su;
  margin-right: $su;
  
  p {
    margin-bottom: 0;
  }
  
  .countdown--close {
    margin: 0 0 $su $su;
    text-align: right;
    float: right;
    position: relative;
    top: -10px;
    
    span {
//      padding: $su / 4 $su;
//      border: solid 1px $silver;
    }
  }
}



/*   Applications   */

.application--headers {
  margin-bottom: 0;
  padding: $su 2 * $su;
  @extend .form-transparency;
    
  p {
    margin: 0;
    color: $black;
    @include font-size(21px);
    
    span {
      display: inline-block;
    }
    
    a {
      color: inherit;
    }
  }
}

.application--history {
  padding: $su 2 * $su;
  background-color: $light-gray;
  
  h3 {
    text-align: center;
    margin-bottom: halve($su);
  }
  
  .message {
    margin-bottom: $su;
  }
  
  p {
    margin: 0;
  }
  
  .description {
    padding: halve($su) 0;
    margin-bottom: $su * 2;
    box-sizing: border-box;
    border-radius: $su;
    display: inline-block;
  }
  
  p.status {
    font-size: 1.1em;
    font-weight: 700;
    
    .read {
      color: #6cdf69;
    }
    
    .unread {
      color: #df6969;
    }
  }
  
  .byline {
//    font-size: 0.9em;
  }
  
  .date {
    color: $black;
    text-align: center;
  }
  
  img {
    width: 60px;
  }
}