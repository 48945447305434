.box.checkin {
  @extend .floating-box;
  text-align: center;
  margin-top: 2 * $su !important;
  padding-top: 2 * $su;
  padding-bottom: 2 * $su;
//  background-color: $pale-blue;
  background-color: rgba(0,0,0,0.5);
    
  h1 {
    @include header-font();
    @include font-size(31px);
    line-height: 1.1em;
//    color: $dark-blue;
    color: $white;
    
    @include media-query('flag') {
      @include font-size(41px);
    }
  }
  
  p.intro {
    @include font-size(19px);
    line-height: 1.4em;
    margin: 0;
    color: $white;
  }
  
  > p {
    color: $white;
  }    
  
  label {
    width: 40%;
    text-align: right;
    padding-right: $su;
    @include font-size(15px);
    display: inline-block;
    vertical-align: middle;
  }
  
  select, input[type="text"] {
    width: 60%;
    display: inline-block;
    box-sizing: border-box;
  }
  
  .entry {
    margin-bottom: halve($su);
  }
  
  .checkbox {
    margin-top: 2 * $su;
    
    input[type="checkbox"] {
      position: absolute; 
      left: 0;
      top: 5px;
    }
    
    select {
      width: 4 * $su;
      margin-left: $su;
    }
    
    div {
      width: 60%;
      @include font-size(15px);
      text-align: left; 
      display: inline-block;
      vertical-align: top;
//      padding-left: $su;
      position: relative;
    }
    
    label {
      vertical-align: top;
      padding-top: 11px;
    }
    
    & + .checkbox {
      margin-top: 0;
      
      label {
        opacity: 0;
      }      
    } 
    
    span {
      cursor: pointer;
    }
  }
  
  input[type="image"] {
    margin: 2 * $su 0 $su 0;
  }
  
  .thanks {
    @include font-size(21px);
    font-weight: 700;
    margin: 3 * $su 0 3 * $su 0;
    color: $black;
  }
  
  .checkin-controls {
    margin-top: $su / 2;
    border-top: solid 1px $silver;
    position: relative;
  }
  
  .edit-link {
    @include font-size(15px);
    line-height: 24px;
    padding: 0;
    margin-top: $su / 2;
    display: inline-block;
    
    a {
      line-height: 24px;
    }
    
    img {
      margin-right: $su / 2;
    }
  }
  
  .view-link {
    @include font-size(15px);
    line-height: 24px;
    padding: 0;
    margin-top: $su / 2;
    margin-left: $su * 2;
    display: inline-block;
    
    a {
      line-height: 24px;
    }
    
    img {
      margin-right: $su / 2;
    }
  }
  
  .hide-link {
    @include font-size(15px);
    line-height: 24px;
    padding: 0;
    margin-top: $su / 2;
    margin-left: $su * 2;
    display: none;
    
    a {
      line-height: 24px;
    }
    
    img {
      margin-right: $su / 2;
    }
  }
  
  .job-status {
    margin-top: $su / 2;
  }
  
  .job-edit-container {
    text-align: center;
    
    @include media-query('flag') {
      text-align: left;
    }
  }
  
  .job-status-container {
    text-align: center;
    
    @include media-query('flag') {
      text-align: right;
    }
  }
}

.form--checkin {
  width: 100%;
  margin: 0 auto;  
  
  .submit-button {
    @include body-font();
    @include font-size(19px);
    background-color: $bright-blue;
    background-image: url('../../img/checkin-tick-white.png');
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: calc(50% - 60px) 50%;
    color: $white;
    width: 80%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: block;
    border: none;
    margin: 0 auto $su auto;
    
    &:hover {
      background-color: $hover-blue;
    }
  }
  
}

.checkin-explanation {
  @extend .floating-box;
  text-align: center;
  padding-bottom: $su * 3;
  
  .handle {
    color: $white;
    border: solid 1px $white;
    margin: 0 auto;
    padding: $su / 2 $su * 2;
    display: inline-block;
    position: relative;
    top: $su;
    z-index: 2;
    background-color: $deep-blue;
    
    &:hover {
      background-color: $bright-blue;
    }
  }
  
  .content {
    padding: $su * 2;
    border: solid 1px $white;
    background-color: rgba(0,0,0,0.3);
    color: $white;
    display: none;
    
    @include media-query('phablet') {
      padding: $su * 2 $su * 3;
    }
  }
  
  p {
    margin: 0;
    padding: 0;
  }
  
  p + p {
    margin-top: $su;
  }
}



/*
**  Listings
*/

.checkin-job {
  text-align: left;
//  border: solid 1px $silver;
  background-color: $white;
  position: relative;
  margin-bottom: $su;
  
  h2 {
    margin: 0;
    font-weight: normal;
    @include font-size(19px);
    margin-bottom: $su / 4;
    color: $font-black;
    
    span {
      color: inherit;
    }
  }
  
  p {
    margin: 0;
    color: $font-black;
  }
  
  span {
    color: $label-gray;
  }
  
  .job-status {
    color: $white;
    border-radius: 15px;
    cursor: pointer;
    padding: 0;
    line-height: 30px !important;
    height: 30px;
    width: 90px !important;
    text-transform: uppercase;
    display: inline-block;
//    position: absolute;
//    right: $su;
//    bottom: $su;
//    right: 0;
//    bottom: 0;
    background-image: url('../../img/checkin-toggle.png');
    background-repeat: no-repeat;
    background-position: 1px 1px;
    
    &.job-status-live {
      background-color: $bright-blue;
      text-align: right;
      padding-right: 15px;
    }
    
    &.job-status-disabled {
      background-color: $red;
      text-align: left;
      padding-left: 15px;
      background-position: right 1px;
    }
  }
  
  .checkin-job--info {
    padding: $su;
  }
}
  
.checkin-description {
  display: none;

  &.active {
    display: block;
  }
}


.checkin-crew {
  text-align: left;
//  border: solid 1px $silver;
  background-color: $white;
  position: relative;
  margin-bottom: $su;
  
  h2 {
    margin: 0;
    font-weight: normal;
    @include font-size(19px);
    margin-bottom: $su / 4;
    color: $font-black;
    
    span {
      color: inherit;
    }
  }
  
  p {
    margin: 0;
    color: $font-black;
  }
  
  span {
    color: $label-gray;
  }
  
  .edit-link {
    @include font-size(15px);
    line-height: 24px;
    padding: 0;
    margin-top: $su;
    
    a {
      line-height: 24px;
    }
    
    img {
      margin-right: $su / 2;
    }
  }
  
  .job-status {
    color: $white;
    border-radius: 15px;
    cursor: pointer;
    padding: 0;
    line-height: 30px !important;
    height: 30px;
    width: 90px !important;
    text-transform: uppercase;
    display: inline-block;
//    position: absolute;
//    right: $su;
//    bottom: $su;
//    right: 0;
//    bottom: 0;
    background-image: url('../../img/checkin-toggle.png');
    background-repeat: no-repeat;
    background-position: 1px 1px;
    
    &.job-status-live {
      background-color: $bright-blue;
      text-align: right;
      padding-right: 15px;
    }
    
    &.job-status-disabled {
      background-color: $red;
      text-align: left;
      padding-left: 15px;
      background-position: right 1px;
    }
  }
  
  .checkin-crew--info {
    padding: $su;
  }
}



/*
**  Check In Bar
*/

#CheckinBar {
  background-color: $aqua;
  margin-bottom: $su;
  padding: halve($su);
  min-height: 50px;
  color: $white;
  @include font-size(17px);

  @include media-query('desk') {
    background: url('../../img/checkin-tick-white.png') no-repeat halve($su) halve($su) $aqua;
  }
  
  .checkin--intro {
    border-right: solid 1px $white;
    padding-left: 4 * $su;
    display: none;
    
    @include media-query('desk') {
      display: inline-block;
    }
  }
  
  .checkin--options {
    padding-left: $su;
  }
  
  .checkin--submit {
    float: right;
    text-align: right;
  }
  
  p {
    margin: 0;
  }
  
  select {
    height: 34px;
    box-sizing: border-box;
    color: $dark-blue;
    padding: quarter($su);
    @include font-size(15px);
  }
  
  .u-inline-block select {
    margin-top: 4px;
  }
  
  .label, .success {
    margin-right: $su;
    display: inline-block;
    vertical-align: top;
    line-height: 2 * $su;
  }
  
  .success {
    font-weight: 700;
  }
  
  .options {
    display: inline-block;
    vertical-align: top;
    
    p {
      display: block;
      padding-top: 5px;
    }
    
    span {
      color: $black;
      padding-right: $su;
    }
    
    select {
      width: 5 * $su;
    }
    
    p + p {
    }
    
    option {
      color: $dark-blue;
    }
  }
  
  input[type="image"] {
    border: solid 2px $white;
  }
}