html.welcome {
  background: none;
}

body#Welcome {
  width: 100%;
  height: 100% !important;
  background: url('../../img/bg-welcome.jpg') no-repeat center center;
  background-size: cover; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-attachment: fixed;
  margin-bottom: 0;
      
  .introduction {
    width: 90%;
    margin: 10% auto 0 auto;
    text-align: center;
    color: #000;
    
    @include media-query ('desk') {      
      width: 870px;
    }
  }
  
  h1 {
    font-family: 'Overlock', sans-serif;
    font-size: 32px;
    color: #000;
    
    @include media-query ('lap') {
      font-size: 50px;
    }
  }
  
  h2 {
    font-family: 'Overlock', sans-serif;
    font-size: 23px;
    color: #000;
    margin: 0 5%;
    
    @include media-query ('lap') {
      font-size: 50px;
    }
  }
  
  p {
    font-family: 'Overlock', sans-serif;
    font-size: 17px;
        
    &.large {
      width: 90%;
      font-size: 24px;
      margin: 0 auto 15px auto;
    }
    
    @include media-query ('lap') { 
      font-size: 21px;
      
      &.large {
        font-size: 34px;
        width: 460px;
        margin-bottom: 30px;
      }   
      
      &.small {
        font-size: 17px;
      }      
    }
  }
  
  input.welcome {
    width: 134px;
    height: 37px;
    font-family: 'Overlock', sans-serif;
    font-size: 18px;
    color: #fff;
    background: url('../../img/button-welcome.png') no-repeat;
    border: none;
    padding-right: 15px;
  }
  
  input.start {
    width: 165px;
    height: 46px;
    font-family: 'Overlock', sans-serif;
    font-size: 23px;
    color: #fff;
    background: url('../../img/button-start.png') no-repeat;
    border: none;
    padding-right: 18px;
    
    @include media-query ('lap') {
      margin-top: 30px;
    }    
  }
    
  #ModalContent {
    min-height: 100%;
    background: url('../../img/bg-welcome-modal.png');
    position: relative;
    top: 0; left: 0;
    
    @include media-query('lap') {
      width: 870px;
      min-height: 450px;
//      top: 10%;
      left: 50%;
      margin-left: -435px;
      margin-bottom: 30px;
      margin-top: 5%;
      border-radius: 10px;      
      position: relative;
    }
  }
}

.conversation {
  max-width: 800px;
  min-height: 400px;
  margin: 0 auto;
  padding: 20px 0 30px 0;
  text-align: center;
  
  h2 {
    font-weight: 400;
    font-size: 2.7em;
    color: #293D87;
    text-align: center;
    margin: 0.5em 0 0.8em;
  }
  
  p.large {
    font-family: 'Overlock', sans-serif;
    font-size: 1.4em;
    line-height: 1.8em;
    text-align: center;
    width: 70%;
    margin: 1.5em auto 2em auto;
  }
  
  p.notification {
    margin-bottom: 30px;
  }
  
  ul.notification {
    text-align: left;
  }
  
  label {
    font-family: 'Overlock', sans-serif;
    font-size: 23px;
    color: #000;
    margin: 25px 0 10px 0;
  }
  /*
  form * {
    z-index: 2;
    position: relative;
  }
  */
  .buttons {
    min-height: 37px;
    text-align: center;
    
    @include media-query ('lap') {
      width: 93%;
      direction: rtl;
      text-align: right;  
    }
    
    &.center {
//      direction: rtl;
      text-align: center;
    }
    
    input {
      padding: 6px 18px;
      border: none;
    }
    
    .next {
      width: 134px;
      height: 37px;
      font-family: 'Overlock', sans-serif;
      font-size: 18px;
      color: #fff;
      background: url('../../img/button-welcome.png') no-repeat;
      border: none;
      padding-right: 35px;
      text-align: center;
      text-transform: uppercase;
      
      &:disabled {
        opacity: 0.5;
      }
    }
    
    .back {
      background-color: transparent;
      border: none;
      font-weight: 700;
      color: #0069ff;
      
      @include media-query('lap') {
        float: left;
      }
    }
    
    .skip {
      font-size: 0.8em;
      background-color: transparent;
      color: gray;
      margin-right: 20px;
    }
    
    .complete {
      padding: 8px 24px 8px 12px;
    }
    
    .hidden {
      display: none;
    }
  }
  
  .error {
    font-size: 0.9em;
    text-align: center;
    color: red;
  }

  input[type="text"],
  select {
  /*  width: 60%;*/
    width: 240px;
  }
    
  select#dob_day {
    width: 60px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  select#dob_month {
    width: 120px;
    margin: 0 10px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  select#dob_year {
    width: 80px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  
  div.selectable {
    border: solid 2px transparent;
    padding: 20px 15px 30px 15px;
    cursor: pointer;
    
    &:hover {
      border: dashed 2px #5F8EFC;
    }
      
    &.selected {
      border: solid 2px #6bd563;
    }
  }
  
  div.genders {
    margin-top: 10px;
  }
  
  div.gender {
    width: 40%;
    margin: 0 4% 15px 4%;
    padding-bottom: 0;
    text-align: center;
    display: inline-block;
    box-sizing: border-box;
    
    &.male {      
      @include media-query ('lap') {
        margin-right: 2%;
        margin-left: 10%;
      }
    }
    
    &.female {
      margin-left: 2%;
    }
    
    img {
      max-width: 80%;
      margin-bottom: 5px;
      
      @include media-query ('lap') {
        max-width: 140px;
      }
    }
    
    p {
      margin-bottom: 5px;
    }
    
    @include media-query ('lap') {
      width: 25%;
      margin: 0 10% 15px 10%;      
    }
  }
  
  div.teams {
    margin-top: 10px;
  }
  
  div.team {
    width: 40%;
    margin: 0 4% 15px 4%;
    padding-bottom: 0;
    text-align: center;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    
    &.male {
      @include media-query ('lap') {
        margin-right: 2%;
        margin-left: 10%;
      }
    }
    
    &.female {
      margin-left: 2%;
    }
    
    .image {
      height: 110px;
      
      @include media-query ('flag') {
        height: 160px;
      }
    }
    
    img {
      max-width: 80%;
      margin-bottom: 5px;
      vertical-align: middle;
      display: inline-block;
      
      @include media-query ('phablet') {
        max-width: 140px;
      }
    }
    
    .filler {
      width: 1px;
      height: 100%;
      max-height: 110px;        
      vertical-align: middle;
      display: inline-block;
      
      @include media-query ('flag') {
        max-height: 160px;        
      }
    }
    
    p {
      margin-bottom: 5px;
    }
    
    @include media-query ('lap') {
      width: 25%;
      margin: 0 2% 15px 2%; 
    }
  }
 
  div.miles {
    margin-top: 10px;
  }
  
  div.mile {
    width: 140px;
    height: 140px;
    margin: 0 5px 15px 5px;
    padding: 0;
    background: url('../../img/icon-miles.png') no-repeat center center;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    
    p {
      color: #00a2ff;
      font-size: 14px !important;
      margin-bottom: 25px;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
    }
    
    .filler {
      width: 1px;
      height: 100%;
      vertical-align: middle;
      display: inline-block;
    }
  }

  div.dob {
    text-align: center;
    
    label {
      margin-bottom: 10px;
    }
    
    input[type="text"] {
      width: 50px; 
      text-align: center;
      margin: 0 5px;
      display: inline-block;
    }
    
    input[type="text"]#dob_year {
      width: 100px;
      display: inline-block;
    }
  }
  
  div.language {
    .add {
      width: 240px;
      font-family: 'Overlock', sans-serif;
      font-size: 17px;
      background-color: #00A2FF;
      color: #fff;
      border: none;
      text-transform: uppercase;
      margin: 5px auto 0 auto;
      padding: 5px 0;
      border-radius: 0;
      -webkit-appearance: none;
      display: block;
    }
  }

  div.language + div.language {
    margin-top: 5px;
  }
  
  select#sea_miles {
    width: 300px;
  }
  
  div.job {
    .add {
      width: 240px;
      font-family: 'Overlock', sans-serif;
      font-size: 17px;
      background-color: #00A2FF;
      color: #fff;
      border: none;
      text-transform: uppercase;
      margin: 5px auto 0 auto;
      padding: 5px 0;
      border-radius: 0;
      -webkit-appearance: none;
      display: block;
    }
  }

  div.job + div.job {
    margin-top: 5px;
  }
  
  input#advert_title {
    width: 90%;
    padding: 0.4em 0.6em;
    margin: 0 auto;
    
    @include media-query ('lap') {
      width: 700px;
    }
  }  
  
  textarea#advert_description {
    width: 90%;
    height: 150px;
    padding: 0.4em 0.6em;
    margin: 0 auto;
    
    @include media-query ('lap') {
      width: 700px;   
    }
  }  
  
}