.tab-bar {
  position: relative;
    
  @include media-query('phablet') {
    height: 30px;
  }
  
  &::after {
    @include media-query('phablet') {
      content: "";
      width: 100%;
      height: 20px;
      background-color: $white;
      position: absolute;
      bottom: 0;
      display: block;
      z-index: 1;
    }
  }
  
  &.no-fill {
    height: 30px;
  }
  
  &.no-fill::after {
    display: none;
  }
  
  fieldset + & {
    height: 30px + $su;
    padding-top: $su;
    @extend .form-transparency;
    box-sizing: border-box;
  }
  div.buttons + & {
    height: 30px + $su;
    padding-top: $su;
    @extend .form-transparency;
    box-sizing: border-box;
  }
  &.run-on {
    height: 30px + $su;
    padding-top: $su;
    @extend .form-transparency;
    box-sizing: border-box;
  }
  
  .form-transparency & {
    background-color: transparent;
  }
  
  ul {
    margin: 0;
    padding: 0 0 0 $su;
    list-style-type: none;
    z-index: 2;
    position: relative;
    
    @include media-query('phablet') {
      height: 30px;
    }
    
    &.dip {
      top: 20px;
    }
  }
  
  li {
    @include font-size(18px);
    line-height: 30px;
    padding: 0 $su;
    display: inline-block;
    
    &.active {
      background-color: $white;
      border-left: solid 1px $modal-light-gray;
      border-right: solid 1px $modal-light-gray;
    }
    
    &.title {
      background-color: $white;
      color: $menu-selected-color;
      border-left: solid 1px $modal-light-gray;
      border-right: solid 1px $modal-light-gray;
    }
  }  
  
}

.tab-bar-links {
  @extend .form-transparency;
    
  @include media-query('phablet') {
    height: 45px;
  }
  
  &::after {
    height: 15px;
  }
  
  li {
    @include font-size(15px);
    line-height: 30px;
    background: rgba(255, 255, 255, 0.7);
    vertical-align: top;
    text-transform: uppercase;
      
    @include media-query('phablet') {
      height: 30px;
    }
    
    &.active {      
      @include media-query('phablet') {
        height: 45px;
        line-height: 35px;
      }
      
      a {
        color: $font-color;
      }
    }
  }
  
  a {
    color: $bright-blue;
  }
}