.promotion {
  .promotion--title {
    background-color: $promotion-blue;
    background-image: url('../../img/promotion-premium-icon@2x.png');
    background-repeat: no-repeat;
    background-size: 45px 40px;
    background-position: $su $su / 2;
    color: $white;
    padding: $su $su $su 80px;
    
    p {
      margin: 0;
      @include font-size(17px);
    }
  }
  
  .promotion--content {
    background: linear-gradient(#183d72, #0097d5);
    color: $white;
    padding: $su;
    
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    
    li + li {
      margin-top: $su / 3;
    }
    
    li:before {
      content: "-";
      margin-right: $su / 2;
    }
  }
  
  .promotion--buttons {
    a, input[type="submit"] {
      background-color: $promotion-blue;
      border: solid 1px $promotion-blue;
      color: $white;
      padding: $su / 2 $su * 2;
      margin-top: $su;
      display: inline-block;
      box-sizing: border-box;
      font-weight: 700;
      margin-right: $su;
      
      &:hover {
        background-color: lighten($promotion-blue, 5%);
      }
      
      &.transparent {
        background-color: transparent;
        color: $promotion-blue;
        font-weight: 400;
        
        &:hover {
          background-color: $promotion-transparent-hover;
        }
      }
    }
    
    form {
      display: inline-block;
    }
  }
}



/* For the viewer pages */

section.viewer-premium {
  margin-bottom: 0;
  padding-bottom: 0;
  
  .promotion--title {
    background-image: none;
    padding: 0;
    
    .viewer-container-width {
      background-image: url('../../img/promotion-premium-icon@2x.png');
      background-repeat: no-repeat;
      background-size: 45px 40px;
      background-position: 0 $su / 2;
      padding: $su $su $su 60px;
    }
  }
}



/* Promote a job */

.promote-job {
  padding-left: $su-large;
  padding-right: $su-large;
  
  h2 {
    @include font-size(21px);
    font-weight: 600;
    color: $black;
  }
  
  .promote-price {
    @include font-size(36px);
    font-weight: 600;
    color: $black;
    margin-top: $su-large;
    margin-bottom: $su-large;
    
    span {
      @include font-size(17px);
      font-weight: 300;
    }
  }
}