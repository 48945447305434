div.breadcrumb {
  background-color: $white;
  padding: 0;
  margin: 0;
  @include font-size(15px);
  position: relative;
  display: block;
  
  @include media-query('lap') {
    display: none;
  }
}

.breadcrumb-primary {
  margin: 0;
  padding: $su-small $su;
  background-color: $white;
  border-top: solid 1px $gray;
  cursor: pointer;
  position: relative;
  z-index: 7;
  
  @include media-query('phablet') {
    border-top: none;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
  }
  
  li {    
    display: inline-block;
    
    &:first-of-type {
      color: $bright-blue;
    }
  }
  
  li + li:before {
    content: ">";
    margin-left: 5px;
    margin-right: 8px;
    display: inline-block;
  }
  
  .toggle {
    margin-top: 3px;
    transition: transform 0.5s ease-in-out;
    float: right;
  }  
  
  .breadcrumb.active & {
    .toggle {
      transform: rotate(180deg);
    }
  }
}

.breadcrumb-secondary {
  width: 100%;
  background-color: $white;
  position: absolute;
  border-top: solid 1px $light-gray;
  z-index: 6;
  padding: $su-small 0 0 0;
  transition: all 0.7s ease;
  top: -500px;
  display: block;
  
  @include media-query('lap') {
    left: 0;
    max-width: 400px;
    border-right: solid 1px darken($light-gray, 5%);
  }
  
  .breadcrumb.active & {
    top: 40px;
  }
  
  .close {
    background-color: $light-gray;
    border-top: solid 1px darken($light-gray, 5%);
    border-bottom: solid 1px darken($light-gray, 5%);
    text-align: center;
    margin: $su 0 0 0;
    padding: 10px 0;
    cursor: pointer;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
  }
  
  li {    
    display: block;
    
    a {
      padding: $su-tiny $su;
      display: block;
      
      &:hover {
        background-color: $light-gray;
      }
    }
  }
}