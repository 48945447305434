/*------------------------------------*\
	 #BOX-SIZING
\*------------------------------------*/

/**
 * Set the global `box-sizing` state to `border-box`.
 *
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 * paulirish.com/2012/box-sizing-border-box-ftw
 */
html {
	 -webkit-box-sizing: border-box;
		 -moz-box-sizing: border-box;
				box-sizing: border-box;
}

* {

	 &,
	 &:before,
	 &:after {
		  -webkit-box-sizing: inherit;
			  -moz-box-sizing: inherit;
					 box-sizing: inherit;
		  }

}





// If we have included this file, set a variable to tell the rest of the
// framework that global `box-sizing: border-box` has been set.
$inuit-global-border-box: true;
