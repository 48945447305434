fieldset.legend {
  border-top: solid 2px $modal-light-gray;
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: block;
  
  legend {
    width: 200px;
    text-align: center;
    background-color: $white;
    color: $mid-blue;
    @include font-size(18px);
  }  
}

label {
  margin: 0;
  vertical-align: middle;
  display: block;
  
  &.inline {
    width: auto !important;    
    margin: 0 $su 0 halve($su);
    display: inline-block;
  }
  
  &.top {
    vertical-align: top;
    margin-top: quarter($su);
  }
  
  &.highlight {
    color: $black;
    font-weight: bold;
  }
  
  &.block {
    display: block !important;
  }
}

input[type="text"],
input[type="password"],
input[type="email"],
select, 
textarea {
  width: 90%;
  padding: 0.5em 1em;
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 1px $silver;
//  -webkit-appearance: none;
  
  &:hover {
    border-color: lighten($silver, 5%);
  }
  
  &:focus, &:active {
    outline: none;
    border-color: $bright-blue;
  }
  
  &.inline {
    width: auto !important;
    margin-right: $su;
    display: inline-block;
  }
  
  &.large {
    font-size: 1.1rem;
  }
}

select {
  padding: 0.5em 0.5em 0.5em 0.6em;
  
  &.inline {
    width: auto !important;
    margin-right: $su;
    display: inline-block;
  }
  
  &.date {
    margin-right: halve($su);
  }
  
  &[disabled],
  &.disabled {
    color: $silver;
    border-color: $silver;
    
    option {
      color: $silver;
    }
  }
}

option {
  padding: 0 1em;
}

optgroup {
  background-color: #f3f3f3;
}

optgroup.primary {
  background-color: $gray;
  color: $white;
  text-transform: uppercase;
}

fieldset {
  background-color: transparent;
  border: none;
  margin: 0;
}

form div.half {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

form {
  div.buttons {    
    &.right {
      margin-right: 10%;
      float: right;
    }
  }
  
  p.error {
    color: red;
  }
}

.form-transparency {
  background: rgba(255, 255, 255, 0.5);
  
  .form-transparency, fieldset.closed {
    background: transparent;
  }
  
  .btn--blue {
    border: solid 1px $white;
  }
}

.form-spacer {
  height: 1.5 * $su;
}

ul.errors li {
  color: red;
}

.req {
  color: red;
}

.entry .layout__item {
  vertical-align: middle;  
}

.form--register {
  padding-bottom: $su;
  
  .purpose {
    text-align: center;
    border: solid 2px transparent;
    padding: 20px 15px 30px 15px;
    box-sizing: border-box;

    &:hover {
      border: dashed 2px #5F8EFC;
    }

    &.selected {
      border: solid 2px #6bd563;
      background-color: #fff;
    }
    
    label {
      cursor: pointer;      
    }
    
    img {
      max-width: 140px;
      margin: 0 auto;
      display: block;
    }
    
    p {
      max-width: 90%;
      height: 70px;
      margin: 0 auto;
    }
    
    .title {
      height: auto;
      font-weight: bold;
      margin: $su auto 0 auto;
    }
    
    input[type="radio"] {
      display: none;
    }
  }
  
  .entry--captcha {
    .g-recaptcha {
      > div {
        width: 100% !important;
      }
    }
  }
}



/*   
** Profile form elements
*/

.form--profile {  
  .entry {
    min-height: 35px;
    margin-bottom: quarter($su);
    
    label {      
      @include media-query('phablet') {
        width: 30%;
        display: inline-block;
      }
    }
    
    .under-input {   
      @include media-query('phablet') {
        padding-left: 31%;
      }
    }
    
    input + .under-input {
      margin-top: 5px;
    }
  }
      
  .entry--title {
    color: $black;
    font-size: 1.3rem;
  }

  .entry--subtitle {
    color: $gray-blue;
    font-size: 1.1rem;
  }

  .entry--counter {
    color: $gray;
    text-align: right;
  }
  
  .entry--responsive {
    padding: $su;
    margin-bottom: 0;
    
    label {
      width: 100%;
      cursor: pointer;
      position: relative;
      display: block;
    }
    
    input[type="text"], textarea, select {
      width: 100%;
      margin-bottom: $su-small;
    }
    
    p {
      margin: 0;
    }
  }
  
  .entry--responsive + .entry--responsive {    
    border-top: dotted 1px $silver;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="password"],
  select,
  textarea {
    @include media-query('phablet') {
      width: 60%;
    }
  }

  input[type="checkbox"] {
    margin: halve($su) quarter($su) 0 0;
  }
  
  select.date.qualification {
    padding-left: 0.2em;
    padding-right: 0.2em;
    
    option {
      padding-right: 0;
    }
  }
  
  p.error {
    margin-left: 30%;
    padding-left: halve($su);
  }
  
  button.add, p.add {
    width: 100%;
    display: inline-block;
    margin-top: 2px;
    @include font-size(13px);
    @extend .btn--small;
    
    @include media-query('phablet') {
      width: 60%;
      margin-left: 30%;
      box-sizing: border-box;
    }
  }
  
  p.add {
    width: auto;
    margin-left: 0;
    margin-bottom: $su;
  }
  
  .note {
    font-style: normal;
    color: $bright-blue;
  }
  
  .list {           
    @include media-query('phablet') {
      width: 60%;
      display: inline-block;
      vertical-align: top;
    }
  }
  
  .job {
    label {
      opacity: 0;
    }
        
    &[data-job-index="0"] label {
      opacity: 1;
    }
    
    .delete {
      margin-left: halve($su);
      cursor: pointer;
    }
  }
  
  .language--fluent {
    label {
      opacity: 0;
    }
        
    &[data-fluent-index="0"] label {
      opacity: 1;
    }
    
    .delete {
      margin-left: halve($su);
      cursor: pointer;
    }
  }
  
  .language--basic {
    label {
      opacity: 0;
    }
        
    &[data-basic-index="0"] label {
      opacity: 1;
    }
    
    .delete {
      margin-left: halve($su);
      cursor: pointer;
    }
  }
  
  .qualification {
    label {
      opacity: 0;
    }
        
    &[data-index="0"] label {
      opacity: 1;
    }
    
    .delete {
      margin-left: halve($su);
      cursor: pointer;
    }
  }
  
  table.qualifications {
    th {
      text-align: center;
      display: none;
      
      @include media-query('lap') {
        display: table-cell;
      }
    }
    
    td {
      @extend .pt--;
      @extend .pb--;
      display: inline-block;
      vertical-align: top;
      
      @include media-query('lap') {
        display: table-cell;
      }
    }
    
    td.qualification {
      width: 100%;
      
      @include media-query('lap') {
        width: 50%;
        padding-right: halve($su);
      }
      
      select {
        width: 100%;
      }
      
      .link-action {
        float: right;
        margin-right: 15%;
      }
    }
    
    td.date {
      width: 55%;
      text-align: right;
      
      @include media-query('lap') {
        width: 25%;
        text-align: center;
      }
    }
    
    td.status {
      width: 45%;
      
      @include media-query('lap') {
        width: 15%;
      }
      
      select {
        width: 100%;
      }
    }
  }
  
  .new-skill {
    padding: $su;
    @extend .form-transparency;
    
    input {      
      vertical-align: top;
    }
    
    .btn {
      @extend .btn--small;     
      padding: 5px $su;
      margin-left: $su;
    }
  }
    
  .buttons {
    margin-top: 0;
    padding: $su;
  }
}

.profile--edit {
  background-color: transparent !important;
  
  fieldset {
    /*background-color: $white;*/
  }
}

.sea-miles-display { 
  width: 110px;
  height: 110px;
  background: url('../../img/icon-miles.png') no-repeat;
  text-align: center;
  color: $bright-blue;
  padding-top: 22%;
  margin-bottom: halve($su);
}

#MilesDisplay {
  /*
  h2 {
    font-size: 2rem;
    font-weight: 300;
    margin-top: $su-small;
    color: $blue;
  }
  p.content--toggle {
    background-color: $blue;
    color: $white;
    padding: $su-tiny $su;
    display: inline-block;
    
    &:hover, &:focus {
      background-color: $bright-blue;
    }
  }
  */
}

#MilesUpdate {
  .content--toggle {
    color: $red;
    margin-top: $su-tiny;
  }

  label {
    font-size: 1.3rem;
    font-weight: 300;
    margin-bottom: $su-tiny;
  }
  
  input[type="submit"] {
    padding-top: 7px;
    padding-bottom: 7px;
    border: none !important;
    position: relative;
    top: -1px;
  }
}

.form--profile-experience {
  .buttons {
    padding-left: 30%;
  }
  
  .note {
    font-size: 0.9em;
    
    @include media-query('phablet') {
      margin-left: 30%;
    }    
  }
}

.form--profile-reference {
//  .buttons {
//    @include media-query('phablet') {
//      padding-left: 30%;
//    }
//  }
  
  .note {
    font-size: 0.9em;
    
    @include media-query('phablet') {
      margin-left: 30%;
    }    
  }
}



/*
** Boat forms
*/

.form--profile-boat {  
  /*
  .entry {
    max-width: 600px;
    
    &.wide {
      max-width: 900px;
    }
  }
  */
  .note {    
    color: $note-color;
    
    @include media-query('phablet') {
      width: 30%;
      display: inline-block;
      vertical-align: top;
    }    
  }
}



/*
** Account forms
*/

.form--account {  
  .entry {
    min-height: 35px;
    margin-bottom: quarter($su);
  }
  
  p.active {
    padding: quarter($su) $su;
    margin-right: $su;
    display: inline-block;
    
    &.active--active {
      background-color: $green;
      color: $white;
    }
    
    &.active--suspended {
      background-color: $silver;
      color: $white;
    }
  }
    
  ul.active-options {
    list-style-type: none;
    margin: 0;
    padding: 0;
    
    li {
      text-transform: uppercase;
      margin-right: $su;
      display: inline-block;
    }
  }  
  
  .notifications-general {
    @extend .mt\+;
    @extend .mb\+;
    
    label {
      cursor: pointer;
      
      input[type="checkbox"] {
        margin-right: halve($su);
        display: inline-block;
        vertical-align: middle;
      }
      
      span {
        width: 90%;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  
  .notifications-list {
    @extend .mb\+;
        
    @include media-query('phablet') {
      padding-left: $su * 4;
    }
    
    label {
      cursor: pointer;
      line-height: 2.3em;
      
      input[type="checkbox"] {
        margin-right: halve($su);
      }
    }
  }
    
  .buttons {
    margin-top: 0;
    padding: $su;
  }
}

.form--notifications {
  fieldset {
    padding-left: 2 * $su;
  }  
  
  h1 {
    @include font-size(25px);
    font-weight: 700;
    color: $modal-dark-gray;
  }
  
  h2 {
    @include font-size(19px);
    font-weight: 700;
    color: $modal-dark-gray;
  }
}

.form--privacy {
  .entry {
    padding-top: halve($su);
    
    @include media-query('phablet') {
      padding-top: 0;
    }
  }
  
  label {      
    @include media-query('phablet') {
      width: 30%;
      display: inline-block;
    }
  }
  
  select {
    @include media-query('phablet') {
      width: 60%;
    }
    
    @include media-query('desk') {
      width: 40%;
    }
  }
}

.spotlight {
  margin-top: $su;
//  padding: $su;
  background-color: $white;

  .question {
    font-weight: 700;
    margin-bottom: 0;
  }

  .answer {
    font-style: italic;
  }
  
  .edit {
    display: none;
    padding: halve($su) 0 $su 0;
  }
  
  .links {
    a {
      margin-right: 2 * $su;
    }
  }
}


/*   Images used in profile form   */

img.profile--background {
  border: solid 3px $silver;
  cursor: pointer;
  margin-bottom: $su;

  &.selected {
    border-color: $bright-blue;
  }
}

img.profile--uploaded {
  margin-bottom: $su;
}

.form--photo {
  .title {
    @include font-size(19px);    
    color: $gray-blue;
  }
  
  label.btn {
    width: 180px;
    max-width: auto;
    padding-left: $su;
    padding-right: $su;
    font-weight: 700;
    
    &.fullwidth {
      width: auto;
    }
  }
  
  .btn--gallery {
    width: 180px;
    max-width: auto;
    padding-left: $su;
    padding-right: $su;
    font-weight: 700;
    margin-top: halve($su);
    border-width: 2px;
  }
  
  .form--crop {
    .btn {
      font-weight: 700;
      padding-left: $su;
      padding-right: $su;
    }
    
    .btn--primary {
      margin-bottom: $su;
      border: solid 2px $bright-blue;
      
      &:hover {
        border-color: $hover-blue;
      }
    }
  }
  
  .divider {
    width: 80%;
    height: 5px;
    line-height: 5px;
    background-color: $modal-light-gray;
    margin: 2 * $su auto;    
  }
}

.photo-tips {
  border: solid 5px $modal-light-gray;
  border-radius: 5px;
  padding: $su;
  margin-left: halve($su);
  
  p {
    padding-left: 30px;
  }
  
  .legend {
    width: 100px;
    color: $silver;
    background-color: $white;
    margin: -35px auto 20px auto;
    padding: 0;
    text-align: center;
    @include font-size(17px);
    font-weight: 700;
  }
  
  .correct {
    background: url('../../img/icon-correct.png') no-repeat 0 5px;
  }
  .incorrect {
    background: url('../../img/icon-incorrect.png') no-repeat 0 5px;
  }
  .format {
    background: url('../../img/icon-photo-format.png') no-repeat 0 5px;
  }
  .privacy {
    background: url('../../img/icon-privacy.png') no-repeat 0 2px;
  }
  .video {
    background: url('../../img/icons/video@2x.png') no-repeat 0 2px;
    background-size: 18px 18px;
  }
  .question {
    background: url('../../img/icons/question-mark@32px.png') no-repeat 0 3px;
    background-size: 14px 14px;
  }
}

.photo-previews {
  .layout__item {
    text-align: center;
    padding-top: $su;
  }
  
  img {
    max-width: 80%;
  }
  
  p {
    margin: 0 0 halve($su) 0;
    font-weight: 700;
    @include font-size(15px);
    color: $gray-blue;
  }
}

.photo-gallery {
  .layout__item {
    text-align: center;
    padding-bottom: $su;
  }
  
  form {
    margin: 0 quarter($su);
    display: inline-block;
  }
}

.photo-backgrounds {
  max-height: 230px;
  overflow-y: scroll;
  margin-bottom: $su;
  
  .background-container {
    padding: quarter($su);
    border: solid 2px $white;
    
    img {
      cursor: pointer;
      
      &.background-1 {
        border: solid 1px $silver;
      }
    }
    
    &.active {
      border: dashed 2px $bright-blue;
    }
  }
}

.form--video {
  input[type="text"] {
    width: 70%;
  }
  
  label.fullwidth {
    width: 100%;
    display: block;
  }
  
  .buttons {
    padding: 0;
  }
  
  p.error {
    margin-left: 0;
    padding-left: 0;
  }
}

.video-preview {
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;
  margin-bottom: $su;
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}



/*
** Job form
*/

.job-req--create {
  display: none;
  
  .layout {
    margin-bottom: $su;    
    cursor: pointer;
  }
  
  .layout__item {
    vertical-align: top;
  }
  
  .job-type {
    color: $black;
  }
  
  &.box {
    margin-bottom: 0 !important;
  }
}

.form--job {
  
  .entry {
    margin-bottom: 15px;
    
    label {
      width: 100%; 
      padding-right: 2%;
      padding-bottom: 8px;
      box-sizing: border-box;
      
      &.full {
        width: auto;
      }
      
      &.mob-hidden {
        display: none;
      }
    }
    
    .content {
      width: 75%;
      box-sizing: border-box;
      vertical-align: top;
      display: inline-block;
    }

    input[type="text"],
    input[type="password"],
    input[type="email"],
    select, 
    textarea {
      width: 100%;
    }
    
    button.add {
      width: 48%;
      margin-left: 25%;
    }
    
    p.instruction {
      width: 100%;
      font-size: 0.9em;
      padding-left: 2%;
      display: inline-block;
      color: $gray;
      vertical-align: top;
      box-sizing: border-box;
      margin-top: 10px;
      
      strong {
        font-style: italic;
      }
    }

    .note {    
      @include media-query('phablet') {
        margin-left: 25%;
      }    
    }
    
    @include media-query('phablet') {
      margin-bottom: 5px;
      
      label {
        width: 25%;
        padding-bottom: 0px;
        
        &.mob-hidden {
          display: inline-block;
        }
      }

      input[type="text"],
      input[type="password"],
      input[type="email"],
      select, 
      textarea {
        width: 48%;
      }
      
      p.instruction {
        width: 25%;
        margin-top: 0;
      }
    }
  }
  
  .ticks {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    padding-bottom: halve($su);
    
    label {
      width: 49%;
      display: inline-block;
      cursor: pointer;
      border-sizing: border-box;
    }
    
    @include media-query('phablet') {
      width: 70%;
      
      label {
        width: 100%;
      }
    }
  }
  
  .language {
    label {
      opacity: 0;      
    }
    
    &[data-language-index="0"] label {
      opacity: 1;
    }
  }
  
  select.inline.max-50 {
    max-width: 50%;
  }
  
  .qualification-req {
    margin-bottom: quarter($su);
  }
  
  .job-req {
    display: none;
    
    &.show {
      display: block;
    }
    
    h2 {
      margin-top: 2 * $su;
    }
  }
  
  fieldset {
    padding: $su;
//    padding: halve($su) $su $su $su;
    
    > .handle {
      font-size: 1.4em;
      height: 45px;
      line-height: 45px;
      padding: 0 0 0 80px;
      margin-bottom: 0;
      background-color: $bright-blue;
      background: url('../../img/fieldset-handle.gif') no-repeat left top $bright-blue;
      border: solid 1px $bright-blue;
      color: $white;
      cursor: pointer;
      display: none;
    }
    
    &.closed {
      border: none;
      padding: $su;
      @extend .form-transparency;
      
      legend, > div {
        display: none;
      }

      > .handle {
        display: block;
      }
    }
  }
    
  fieldset.closed + fieldset.closed {
    padding-top: 0;
  }
  
  legend {
    font-size: 1.4em;
    padding: 0 $su;
  }
}



/*
** Job promotion forms
*/

.form--promote {
  padding: $su $su * 1.5 $su $su;
  
  .price_display {
    color: $deep-blue;
    
    .price {
      @include font-size(40px);
      font-weight: bold;
    }
    
    .duration {
      @include font-size(20px);
      margin-left: halve($su);
    }
  }
  
  select {
    width: 100%;
  }
    
  .buttons {
    margin-top: $su;
  }
  
  label {
    @include header-font;
    @include font-size(19px);
    color: $ocean-blue !important;
    font-weight: 700 !important;
    padding-bottom: halve($su);
    display: block;
  }
}



/*
** Reference form
*/

.form--profile-reference {
  .thirds {
    label, input[type="text"], input[type="email"] {
      width: 95%;
    }        
  }
  
  textarea {
    min-width: 65%;
  }
  
  .note {
    margin: 0;
  }
  
  p.error {
    margin-left: 0;
    padding-left: 0;
  }
}



/*   
** Advert forms
*/

.form--advert {
  fieldset {
    padding: 0;
  }
  
  .entry--responsive {
    padding: $su;
    
    label {
      width: 100%;
      cursor: pointer;
      position: relative;
      display: block;
    }
    
    input[type="text"], textarea, select {
      width: 100%;
      margin-bottom: $su-small;
    }
    
    p {
      margin: 0;
    }
    
    .entry--title {
      color: $black;
      font-size: 1.3rem;
    }
    
    .entry--subtitle {
      color: $gray-blue;
      font-size: 1.1rem;
    }
    
    .entry--counter {
      color: $gray;
      text-align: right;
    }
  }
  
  .entry--responsive + .entry--responsive {    
    border-top: dotted 1px $silver;
  }
    
  .entry--contact {    
    padding-bottom: $su;
    
    .layout__item {
      vertical-align: top;
    }
    
    input[type="text"], textarea, select {
      max-width: 300px;
    }
    
    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
    }
    
    label.custom-checkbox {
      padding-top: 40px;
      
      @include media-query('mob') {
        padding-left: 16.66%;
        padding-top: 0;
      }
            
      .entry--icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: $su;
        background-color: $dusky-blue;
        color: $white;
        text-align: center;
        display: none;
        float: left;
        overflow: hidden;
        
        img {
          width: 50%;
          margin-top: 25%;
        }
        
        @include media-query('phablet') {
          display: inline-block;
        }
      }
    }
    
    label.custom-checkbox::before {
      width: 40px;
      height: 40px;
      content: "";
      background: url('/img/checkbox-unticked.jpg') no-repeat;
      background-size: 100% 100%;
      position: relative;
      left: -20px;
      display: inline-block;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
      
      @include media-query('flag') {
        left: 2%;
      }
    }
    
    input[type="checkbox"]:checked + label.custom-checkbox::before,
    label.custom-checkbox.checked::before {      
      background-image: url('/img/checkbox-ticked.jpg'); 
    }
    
    label.entry--contact-whatsapp {      
      padding: 3px 0;
      
      input[type="radio"] {
        margin-right: $su-small;
      }
    }
  }
    
  .entry--contact-content {
    clear: left;
    display: none;
    
    @include media-query('flag') {      
      padding-top: $su;
    }
  }
    
  .entry--contact.open .entry--contact-content {
    display: block;
  }
  
}

.fieldset-heading {
//  background-color: $silver;
  background-color: $dusky-blue;
  padding: $su;
  box-sizing: border-box;
  
  @include media-query('phablet') {
    padding: $su-small $su;
  }

  h2 {
    font-family: $font--primary;
    font-weight: 300;
//    color: $dark-gray;
    color: $white;
    margin: 0;
    padding: 0;
  }
}

.fieldset-heading--primary {
  background-color: $transparency-light;
  background-position: 200% 200%;
  background-repeat: no-repeat;
  background-size: 0px 0px;
  position: relative;
  border: solid 1px #add3e5;
  border-bottom: none;
    
  @include media-query('phablet') {
    height: 150px;
    padding-left: 160px;    
    background-position: $su-large center;
    background-size: auto 60%;
  }
    
  span.ti {
    font-size: 90px;
    color: $white;
    position: absolute;
    top: $su;
    left: $su-large;
    display: none;
    
    @include media-query('phablet') {
      display: inline-block;
    }
  }
  
  h2 {
    width: 100%;
    font-size: 2.8rem;
    font-weight: 600;
    color: $white;
    margin-top: $su;
    line-height: 1;
    display: inline-block;
    
    @include media-query('phablet') {
      margin-top: 30px;
    }
  }
  
  h3 {
    font-size: 1.3rem;
    font-weight: 400;
    color: $white;
    padding-bottom: $su;
    
    @include media-query('phablet') {
      padding-bottom: 0;
    }
  }
  
  .fieldset-tabs {
    margin: 0;
    padding: 0;
    list-style: none;
    
    @include media-query('phablet') {
      position: absolute;
      bottom: 0;
      right: $su;
    }
    
    li {
      background-color: rgba(255, 255, 255, 0.6);
      padding: $su-small $su;
      display: inline-block;
      
      &.active {
        background-color: $white !important;
      }
      
      &:hover, &:focus {
        background-color: rgba(255, 255, 255, 0.8);
      }
      
      a {
        color: $brand-secondary;
      }
    }
  }
}

.fieldset-introduction {
  background-color: $white;
  padding: $su $su 0 $su;
  
  h2 {
    color: $black;
    font-weight: 400;
    
    .highlight {
      color: inherit;
    }
  }
  
  p.fieldset-introduction--title {
//    font-size: 1.5rem;
    font-weight: 600;
    /*color: $brand-secondary;*/
    margin-bottom: $su-small;
  }
  
  p {
    font-size: 1.1rem;
    /*color: $brand-secondary;*/
    margin-bottom: 0;
  }
}

.fieldset-content {
  padding: 0;
  background-color: $white;
}

.fieldset-content--padded {
  padding: $su;
}



/*   
** Next actions
*/

.form--next {
  list-style: none;
  margin: 0;
  padding: 0;
  
  li {
    padding: $su $su;
    display: block;
    position: relative;
  }
  
  li + li {
    border-top: solid 1px $silver;
  }
  
  .layout__item {
    vertical-align: middle;
  }
  
  .next--icon {
    text-align: center;
    
    img {
      width: 60%;
    }
  }
    
  .next--title {
    font-size: 1.3rem;
    margin: 0;
  }

  .next--subtitle {
    margin: 0;
  }
}