.notification {
  @extend .box;  
  padding: halve($su) $su;
//  margin-bottom: $su;
  border: solid 1px transparent;
  
  p {
    padding: 0;
    margin: 0;
  }
}

.notification--info {
  background-color: $blue;
  color: $white;
}

.notification--success {
  background-color: $green;
  color: $white;
}

.notification--profile {
  
}

.notification--warning {
  background-color: $orange;
  color: $white;
}

.notification--error {
  background-color: $red;
  color: $white;
  
  &.notification--global {
    color: $black;
  }
}

.notification--reminder {
  background-color: $bright-blue;
  color: $white;
}

.notification--checkin {
  padding: 0;
  border: none;
  
  .head {
    background-color: $dark-blue;
    text-align: center;
    padding: $su 0;
    
    .btn {
      border-radius: 5px;
      border-color: $bright-blue;
      background-color: transparent;
      @include font-size(21px);
      line-height: 1.0em;
      font-weight: bold;
    }
    
    p {
      color: $bright-blue;
      
      .ago {
        color: $silver;
      }
    }
    
    p + p {
      margin-top: halve($su);
    }
  }
  
  .body {
    padding: halve($su) $su;
    
    p {
      color: $dark-blue;
    }
  }
}

.notification--sent {
  background-image: url('../../img/icon-submitted-white.png');
  background-repeat: no-repeat;
  background-position: 15px center;
  padding-left: 3 * $su;
  background-size: 30px 25px;
}

.notification.notification--global {
  text-align: center;
  margin-bottom: 0 !important;
}



//
// Global notifications
//

.notification-global {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  text-align: center;
  z-index: 9999;
  padding: $su-small $su;
  
  p {
    @include font-size(14px);
    font-weight: 700;    
    margin: 0;
  }
  
  &.notification-global--success {
    background-color: $green;
    
    p {
      color: $white;
    }
  }
}