/*
**    Manage jobs
*/

.job-manage {
  padding: halve($su) 0 halve($su) $su;
  position: relative;
  background-color: $white;
    
  p.job-reference {
    margin: 0;
    padding: quarter($su) 0;
//    background-color: $light-gray;
  }
  
  h2 {
    font-weight: bold;
    margin: quarter($su) 0;
  }
  
  ul.stats {
    margin: $su-tiny 0 0 0;
    list-style-type: none;
    
    li {
      margin-right: $su;
      padding: $su-tiny $su-small;
      vertical-align: top;
      
      @include media-query('phablet') {
        display: inline-block;
      }  
      
      &.stats--status {
        padding: $su-tiny 0 0 0;
      }
    }
    
    span {
      color: $black;
    }
  }
  
  .stats--status {
    width: 150px;
    border: solid 1px $green;
        
    > a {
      padding-left: $su-small;
      padding-right: $su-small;
      padding-bottom: $su-tiny;
      color: inherit;
      cursor: pointer;
      display: block;

      > span {
        text-transform: uppercase;
        font-weight: 700;
        padding-right: 15px;
        float: right;
      }      
    }     
    
    &.stats--status-0 {
      border-color: $red;
    }
  }  
  
  .stats--status-0 > a > span {
    color: $red;
    background: url('../../img/status-disabled.png') no-repeat right 7px;
  }  
  .stats--status-1 > a > span {
    color: $green;
    background: url('../../img/status-live.png') no-repeat right 7px;
  }
  
  .stats--status-switch {    
    a {
      text-align: center;
      padding: $su-tiny $su-small;
      display: block;
    }
  }
  
  .stats--status-enable {
    color: $green;
    
    &:hover {
      background-color: lighten($green, 40%);
    }
  }
  
  .stats--status-disable {
    color: $red;
    
    &:hover {
      background-color: lighten($red, 40%);
    }
  }
  
  .stats--status-cancel {
    background-color: $light-gray;
    color: $gray;
    display: block;
    cursor: pointer;
    
    &:hover {
      background-color: darken($light-gray, 4%);
    }
  }
  
  .actions {
    margin: 0;
    list-style-type: none;
    
    a {
      padding: halve($su) 0 halve($su) 2.5 * $su;
      color: $bright-blue;
      display: block;
      
      &:hover {
        background-color: $bright-blue;
        color: $white;
      }
    }
    
    li + li {
      border-top: solid 1px $pale-blue;
    }
  }
  
  .actions--applicants a {
    background: url('../../img/icon-jobs-applications.png') no-repeat 10px center;
    
    &:hover {
      background-image: url('../../img/icon-jobs-applications-on.png');
    }
  }
  .actions--promote a {
    background: url('../../img/icon-jobs-promote.png') no-repeat 12px center;
    
    &:hover {
      background-image: url('../../img/icon-jobs-promote-on.png');
    }
  }  
  .actions--edit a {
    background: url('../../img/icon-jobs-edit.png') no-repeat 14px center;
    
    &:hover {
      background-image: url('../../img/icon-jobs-edit-on.png');
    }
  }
  .actions--delete a {
    background: url('../../img/icon-jobs-delete.png') no-repeat 14px center;
    
    &:hover {
      background-image: url('../../img/icon-jobs-delete-on.png');
    }
  }
  
  form.delete {
    position: absolute;
    bottom: $su;
    right: 0;
  }
}

ul.requirements-list {
  margin-bottom: $su;
  
  li {
    padding-left: 15px;
    
    &.tick {
      background: url('../../img/icon-requirement-tick.gif') no-repeat 0 center;
    }
    
    &.cross {
      background: url('../../img/icon-requirement-cross.gif') no-repeat 0 center;
    }
    
    &.some {
      background: url('../../img/icon-requirement-some.gif') no-repeat 0 center;
    }
  }
}



/*
**    Job application
*/

.application--form {
  .entry.cv-share {
    padding: $su !important;
    background-color: $blue;
    
    label {
      font-size: 1.2em;
      font-weight: bold;
      color: $white;
      margin-bottom: halve($su);
      cursor: pointer;
    }
      
    input[type="checkbox"] {
      margin-right: halve($su);
      cursor: pointer;
    }
    
    p {
      margin: 0;
      color: $white;
    }
  }
}


.application--notification {
  .notification--success {
    background: url('../../img/icon-submitted-white.png') no-repeat $su center $green;
    font-size: 1.1em;
    font-weight: bold;
    height: 3 * $su;
    line-height: 3 * $su;
    padding: 0 0 0 (4 * $su);
  }
  
  .tips {
    padding: $su (2 * $su);
    
    p {
      font-size: 1.1em;
    }
    
    ul {
      list-style-type: none;
      margin-left: 0;
    }
    
    li {
      padding-left: 10px;
      text-indent: -7px;
    }
    
    li:before {
      content: "- ";
    }
  }
  
  .buttons {
    a {
      margin-right: $su;
    }
  }
}



/*
**    Applicant list
*/

.application {
  
  > .content {
    background-color: $white;
    padding: $su 0 2 * $su $su;
  }
  
  h2 {
    margin-bottom: halve($su);
  }
  
  ul {
    list-style-type: none;
    margin: 0;
  }
  
  .profile-image {
    max-width: 50%;
    margin: 0 auto;
    border-radius: 15px;
    
    @include media-query('phablet') {
      max-width: 30%;
      margin-right: $su;
      float: left;
    }
  }
  
  .profile-contact {
    margin: $su 0 0 50%;
    clear: left;    
    
    @include media-query('phablet') {
      margin-left: 30%;
      padding-left: $su;
    }
  }
  
  .notes {
    margin: $su 0;
    clear: left;
    
    &.limit {
//      max-height: 120px;
//      overflow: hidden;
      
      p.full {
        display: none;
      }
      
      p.summary {
        display: block;
        margin: 0;
      }
    }
    
    p {
      font-style: italic;
      margin: 0;
      
      &.summary {
        display: none;
      }
    }
    
    a {
      cursor: pointer;
    }
  }
  
  .notes--pro {
    p {
      background-color: $bright-blue;
      color: $white;
      border-radius: 15px;
      padding: $su / 2 $su;
    }
    
    a {
      color: $white;
      font-weight: 700;
    }
    
    .speech {
      margin-left: 15px;
      display: block;
    }
  }
  
  &.application--rec {
    .notes {
      margin-top: 0;
      
      &.limit {
//        max-height: 240px;
      }
      
      p {
        padding: halve($su);
        background-color: $light-gray;
      }
      
      .date {
        color: $black;
        background-color: $white;
        background: url('../../img/quote-grey.png') no-repeat halve($su) top;
        padding: 0;
        text-align: right;
      }
    }
  }
  
  // Statuses
  &.application--status-0 {
    display: none;
    
    .application--actions .shortlist_add {
      display: none;
    }
    
    .application--actions .decline {
      display: none;
    }
    
    .application--actions .restore {
      display: block;
    }
  }
  
  &.application--shortlist-1 {
    display: none;
    
    .application--actions .shortlist_add {
      display: none;
    }
  }  
  
  &.application--shortlist-0 {
    .application--actions .shortlist_remove {
      display: none;
    }
  }  
  
  &.application--replied .content {
    background-image: url('../../img/application-replied.png');
    background-position: left top;
    background-repeat: no-repeat;
  }
  
  &.application--new .content {
    background-image: url('../../img/application-new.png');
    background-position: left top;
    background-repeat: no-repeat;
  }
      
  .suitability {
    border: solid 1px $silver;
    border-radius: 15px;
    text-align: left;
    padding: halve($su) 0;
    margin-right: $su;
    position: relative;
    
    p {
      margin: 0;
      padding: 0 0 0 $su;
    }
    
    .c100 {
      float: none;
      position: absolute;
      right: -$su; top: -10px;
      font-size: 60px;
    }
  }
  
  .suitability--high {
    border-color: green;
  }
  .suitability--medium {
    border-color: orange;
  }
  .suitability--low {
    border-color: red;
  }
  
}

.application--list-header {
  text-align: center;
  background-color: rgba(0, 26, 99, 0.5);
  padding: $su;
    
  @include media-query('desk') {
    padding-left: $su * 4;
    padding-right: $su * 4;
  }
  
  h2 {
    color: $white;
    @include header-font;
    @include font-size(28px);
    font-weight: 400;
    margin: 0;
    overflow: hidden;
    position: relative;
    
    &:before, &:after {
      @include media-query('lap') {
        position: absolute;
        top: 51%;
        width: 50%;
        height: 2px;
        content: '\a0';
        background-color: $white;
        overflow: hidden;
      }
    }
    
    &:before {
      margin-left: -52%;
      text-align: right;
    }
    
    &:after {
      margin-left: 2%;
    }
  }
  
  p {
    color: $white;
    margin: 0;
  }
}

.application--list-tabs {
  background-color: rgba(0, 26, 99, 0.5);
  
  li {
    text-align: center;
    padding-top: $su / 2;
    cursor: pointer;
    
    &.active {
      padding-top: 0;
    }
  }
  
  p {
    height: $su * 4;
    padding: $su 0 0 0;
    margin-bottom: 0;
    color: $promotion-blue;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.5);
    
    img {
      margin-bottom: $su / 4;
    }
  }
  
  .active {
    p {
      height: $su * 4.5;
      padding-top: $su * 1.5;
      background-color: $white;
    }
  }
}

.application--list-ordering {
  background-color: $white;
  padding: $su;
  
  label {
    margin-right: $su / 2;
    display: inline-block;
  }
  
  select {
    width: auto;
    display: inline-block;
    
    &.disabled {
      color: $silver;
    }
  }
  
  .loading {
    margin-left: $su;
    display: none;
  }
}

.application--filter {
//  margin: 0 0 $su 0;
//  list-style: none;
  
  li {
//    display: inline-block;
//    padding: halve($su) $su;
//    margin-right: $su;
//    background-color: $silver;
    cursor: pointer;
    
//    &.active {
//      background-color: $pale-blue;
//    }
  }
}

.application--actions {
  margin: 0;
  list-style-type: none;

  li {
    border-bottom: solid 1px $bright-blue;
    background-color: $white;

    &:first-child {
      border-top: solid 1px $bright-blue;
    }
  }

  a {
    padding: halve($su) $su halve($su) 2.5 * $su;
    color: $bright-blue;
    display: block;

    &:hover {
      background-color: $bright-blue;
      color: $white;
    }
    
    &.active {
      font-weight: bold;
      background-color: $bright-blue;
      color: $white;
    }
    
    &.active:hover {
//      background-color: transparent;
//      color: $bright-blue;
    }
  }
  
  > .profile a {
    background-image: url('../../img/icons/icon-jobs-profile.png');
    background-repeat: no-repeat;
    background-position: 15px center;

    &:hover {
      background-image: url('../../img/icons/icon-jobs-profile-on.png');
    }
  }
  > .cv a {
    background-image: url('../../img/icons/icon-jobs-profile.png');
    background-repeat: no-repeat;
    background-position: 15px center;

    &:hover {
      background-image: url('../../img/icons/icon-jobs-profile-on.png');
    }
  }
  > .shortlist_add a,
  > .shortlist_remove a {
    background-image: url('../../img/icons/icon-jobs-shortlist.png');
    background-repeat: no-repeat;
    background-position: 12px center;

    &:hover {
      background-image: url('../../img/icons/icon-jobs-shortlist-on.png');
    }
  }
  > .contact a {
    background-image: url('../../img/icons/icon-jobs-contact.png');
    background-repeat: no-repeat;
    background-position: 9px center;

    &:hover {
      background-image: url('../../img/icons/icon-jobs-contact-on.png');
    }
  }
  > .decline a {
    background-image: url('../../img/icons/icon-jobs-decline.png');
    background-repeat: no-repeat;
    background-position: 12px center;

    &:hover {
      background-image: url('../../img/icons/icon-jobs-decline-on.png');
    }
  }
  > .restore a {
    background-image: url('../../img/icons/icon-jobs-restore.png');
    background-repeat: no-repeat;
    background-position: 12px center;

    &:hover {
      background-image: url('../../img/icons/icon-jobs-restore-on.png');
    }
  }

  .extra {
    padding: halve($su) $su halve($su) $su;
    background-color: $light-gray;
//    color: $white;
    display: none;
  }
  
  .restore {
    display: none;
  }
  
  p.contact {
    @include font-size(14px);
    margin-bottom: halve($su);
    font-weight: 700;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    span {
      font-weight: normal;
    }
    
    a {
      padding: 0;
      font-weight: normal;
      
      &:hover {
        background-color: transparent;
        color: $bright-blue;
        text-decoration: underline;
      }
    }
  }
}

.application--statuses {
  margin-top: $su !important;
  
  li {
    font-weight: bold;
    display: none;
  }
  
  .shortlist {
    padding-left: 2 * $su;
    background: url('../../img/icon-star.png') no-repeat left center;
  }
  
  .application--shortlist-1 & li.shortlist {
    display: block;
  }
}

.application .send-message {
  margin-top: $su;
  padding-right: 2 * $su;
  
  p.contact {
    @include font-size(14px);
    margin-bottom: halve($su);
    font-weight: 700;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    span {
      font-weight: normal;
    }
    
    a {
      padding: 0;
      font-weight: normal;
      
      &:hover {
        background-color: transparent;
        color: $bright-blue;
        text-decoration: underline;
      }
    }
  }
  
  textarea {
    width: 100%;
    display: block;
  }
  
  .btn--send {
    margin-top: halve($su);
    float: right;
  }
}

.application--spam {
  .content {
    padding: $su;
  }
  
  .spam-message {
    text-align: center;
    padding: 10px 0 10px 45px;
    margin-bottom: 0;
    background: url('../../img/alert-info.png') no-repeat left center;
  }  
}



/*
**    Promotion
*/

.job-promote {
  margin-bottom: $su;
  
  .lift {
    margin-top: -20px;
  }
}