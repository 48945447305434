$inuit-base-font-size:	13px;
$inuit-base-line-height: 20px;

$container-size: 			1020px;
$inuit-layout-gutter: 		10px;
// $page-margin: 				16px;
$header-height: 			120px;
$header-height-small: 			80px;
$mobile-alerts-height: 50px;

$brand-primary: #009FE1;
$brand-secondary: #2A3E87;

$black: #000;
$font-black: #575757;
$white: #fff;
$silver: #d8d8d8;
$gray: #898989;
$light-gray: #f5f5f5;
$dark-gray: #777;
$mid-gray: #a3a7a5;
$label-gray: #8e8c8c;
$bright-blue: #19a9e3;
$dark-blue: #293D87;
$deep-blue: #11435a;
$font-blue: #023763;
$light-blue: lightblue;
$dusky-blue: #2b6886;
$mid-blue: #6685a3;
$pale-blue: #e1f5fd;
$baby-blue: #81c8e2;
$gray-blue: #557397;
$hover-blue: #008bdc;
$indigo: #111d8f;
$green: #16bf00;
$orange: #D38D2A;
$aqua: #2adfff;
$teal: #708e9a;
$pale-green: #aeecc8;
$ocean-blue: #004d5d;
$purple: #0a0035;
$promotion-blue: #1e2a5e;
$promotion-transparent-hover: #1987be;

$font-color: #333;
$note-color: $mid-blue;

$modal-light-gray: #ebebeb;
$modal-dark-gray: #383838;

$placeholder-text:		#9b9b9b;
$hint-text:				#bdbdbd;
$color-text-blue: 	#60bfff;

$html-bg: #021c35;

$hr-color: 				#979797;
$table-border: 			#f5f5f5;
$hover-bg: #f2fbff;
$selected-bg: $pale-blue;

$nav-bg-color:			#0f0502;
$blue:					#3353C1;
$light-tab-blue:		#4a90e2;
$light-blue:			#e1f3fe;
$red:					#e91250;
$rust: #c0392b;

$font-color: 			$black;
$white:				 	#fff;
$bg--body:				#f8f8f8;
$text-color:			#787676;
$heading-color: #000;

$link-color:		  	$bright-blue;
$link-hover:		  	lighten($bright-blue, 5%);

$menu-color: $mid-blue;
$menu-hover-color: #eff3f6;
/*$menu-selected-color: #6685a3;*/
$menu-selected-color: $dusky-blue;

$button-hover-color: #137eab;

$dashboard-hover-color: #c6e7fb;

$modal-shadow: $purple;

$global-transition: 1s;

$transparency: rgba(255, 255, 255, 0.75);
$transparency-light: rgba(255, 255, 255, 0.3);

/**
* Menu Heights - for the margin below it as its position:fixed
**/

// $menu-height--lap: 	55px;
// $menu-height: 				79px;



$breakpoints: (
	 "below-mob"  		"screen and (max-width: 359px)",
	 "mob"  		"screen and (min-width: 360px)",
	 "flag"  		"screen and (min-width: 480px)",
	 "phablet"  	"screen and (min-width: 640px)",	 
	 "lap"	 		"screen and (min-width: 880px)",
	 "desk"			"screen and (min-width: 1024px)",
	 "huge"			"screen and (min-width: 1200px)",
) !default;

$inuit-widths-columns-responsive: (
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
);


/* New fonts setup */

$font--primary: 'Source Sans Pro', Arial, sans-serif;
$font--secondary: 'Roboto Condensed', sans-serif;
$font--fallback: Arial, sans-serif;

$font--regular: 	'Open Sans', 'Segoe UI', Tahoma, sans-serif;
/*$font--regular: $font--primary;*/

$font--italic: 		'bariol_regular_italic', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font--serif: Times New Roman, serif;
$font--home: 'Roboto Condensed', sans-serif;

$body-font: 		$font--regular;

@mixin body-font() {
	.fonts-loaded & {
		font-family: $font--regular;
	}
}

@mixin body-font-bold() {
	.fonts-loaded & {
		font-family: $font--regular;
	}
	font-weight: 700;
}

// $nav-font: 			Signika, Georgia, serif;

@mixin nav-font() {
	.fonts-loaded & {
		font-family: $font--regular;
	}
	@include font-size(18px);
}

$header-font: 		'Source Sans Pro', Arial, sans-serif;

@mixin header-font() {
  font-family: $header-font;
  font-weight: 300;
}

@mixin header-font-bold() {
	.fonts-loaded & {
		font-family: $header-font;
	}
	font-weight: 700;
}



/*
$inuit-btn-background: 		$dark-blue;
$inuit-btn-border-color:	$dark-blue;
$inuit-btn-radius: 			100px;
// $inuit-btn-border-width:	0;
$inuit-btn-color:			$white;
*/
// Spacing
$inuit-enable-margins: true;
$inuit-enable-margins--small: true;
$inuit-enable-margins--tiny: true;
$inuit-enable-margins--large: true;
$inuit-enable-margins--huge: true;
$inuit-enable-margins--none: true;

$inuit-enable-paddings: true;
$inuit-enable-paddings--small: true;
$inuit-enable-paddings--tiny: true;
$inuit-enable-paddings--large: true;
$inuit-enable-paddings--none: true;

// Layouts
$inuit-enable-layout--bottom: true;

// Buttons 
$inuit-btn-radius: 0;
$inuit-btn-background: $bright-blue;
$inuit-enable-btn--small: true;