/*------------------------------------*\
    #LISTS
\*------------------------------------*/

/**
 * Remove extra vertical spacing when nesting lists.
 */
li {

    > ul,
    > ol {
        margin-bottom: 0;
    }

}
