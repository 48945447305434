/*
**   Summary
*/

.search--summary {
  margin-bottom: 0;
  padding-bottom: 0;
  
  div.count {
    @extend .form-transparency;
    color: $black;
    padding: $su / 2 $su;
    
    p {
      margin: 0;
    }
    
    .heading {
      text-transform: uppercase;
    }
    
    .count {
      @include font-size(27px);
    display: none;
      
      span {
        font-weight: 600;
      }
    }
  }
  
  div.filters {
    background-color: $white;
    padding: $su / 2 $su;
  }  
}



/*
**   Results
*/

.search--listings {
  @extend .form-transparency;
  padding-top: $su;
  margin-bottom: 0;
  padding-bottom: 0;
  position: relative;
  
  .overlay {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: $su; bottom: 0;
    left: 0; right: 0;
    padding-top: 3 * $su;
    text-align: center;
    z-index: 2;
    display: none;
  }
}

.result {
  padding: $su / 2 $su;
  background-color: $white;
  margin-bottom: $su;
  
  h2 {
    @include font-size(21px);
    font-weight: bold;
    line-height: 1em;
//    color: $bright-blue;
    color: $black;
  }
  
  ul {
    margin: 0;
    list-style-type: none;
  }  
    
  .avatar {
    width: 100%;
    padding-bottom: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    @include media-query('flag') {
      width: 200px;
      padding-bottom: 200px;
      float: left;
    }

    @include media-query('lap') {
      width: 100%;
      padding-bottom: 100%;
      float: left;
    }
      
    img { 
      width: 100%;
      position: absolute;
      z-index: 1;
    }

    .urgent-banner {
      width: auto;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
    }

    .featured-banner {
      width: auto;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 3;
    }
  }
  
  ul.thumbs {
    li {
      width: 25%;
      height: 0;
      padding-bottom: 25%;
      display: inline-block;
      overflow: hidden;
      
      @include media-query('lap') {
        width: 20%;
        padding-bottom: 20%;
        float: left;
      }
    }
  }
  
  .result--thumbnail {
//    .placeholder {
//      text-align: center;
//      @include font-size(11px);
//      line-height: 1.2;
//      box-sizing: border-box;
//      color: $gray;
//      position: absolute;
//      top: 25%;
//      left: 10%; right: 10%;
//    }
  }
  
  .result--description {
    padding-top: $su;
    
    @include media-query('flag') {
      padding-top: $su / 2;
    }
    
    @include media-query('lap') {
      padding-top: 0;
    }
    
    strong a {
      color: $bright-blue;
    }
  }
  
  .btn--profile {
    width: 100%;
    @extend .btn--small;
    text-transform: uppercase;
    padding-left: $su;
    padding-right: $su;
    
    @include media-query('flag') {
      width: auto;
      float: right;
    }
  }
}

#More {
  text-align: center;
  padding-bottom: $su;
  
  .more {
    border: solid 1px $white;
  }
  
  .loading {
    display: none;
  }
}

#SearchOverlay {
  background: rgba(255, 255, 255, 0.8);  
  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0;
  padding-top: 30%;
  text-align: center;
  z-index: 1002;
  display: none;
  
  p {
    @include font-size(35px);
    margin-bottom: $su;
  }
}



/*
**   Crew
*/

.result--crew {
  h2 {
//    color: $dark-gray;
    color: $black;
    font-weight: 400;
  }
  
  ul.result--contacts {
    float: right;
    
    li {
      display: inline-block;
    }
  }
  
  ul.result--details {
    margin-top: $su !important;
    
    @include media-query('lap') {
      margin-top: 0 !important;
    }
    
    li {
      .label {
        width: 90px;
        font-weight: 700;
        color: $gray;
        text-align: right;
        display: inline-block;
      }
    }
  }
    
  ul.result--stats {
    border: solid 3px $silver;
    padding-bottom: 2px;
    display: none;
    
    @include media-query('lap') {
      display: block;
    }
    
    li {
      padding: 2px 0;
      
      span, img {
        vertical-align: middle;
      }
      
      .label {
        width: 50px;
        color: $mid-blue;
        margin-right: 5px;
        text-align: right;
        display: inline-block;
      }
      
      .icon {
        margin-right: 8px;
      }
      
      .rating {
        img {
          vertical-align: top;
          margin-top: 3px;
        }
      }
    }
  }
}

.result--crew-premium {
  h2 {
//    color: $bright-blue;
    color: $black;
    font-weight: 700;
  }
  
  .result--thumbnail {
    .container {
      padding-bottom: 100%;
      margin: $su 0 0 0;
      border: solid 2px $silver;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      
      @include media-query('lap') {
        margin: 0;
      }

      img {
        display: none;        
        min-height: 100%;
        position: absolute;
      }      
    }
  }
  
  .result--thumbnail + .result--thumbnail .container {
    @include media-query('lap') {
      margin-top: $su / 2;
    }    
  }
  
  .result--description {
    @include media-query('lap') {
      margin-top: $su / 2;
    }
  }  
  
  .result--stats {
    margin-top: $su;
    
    @include media-query('lap') {
      margin-top: 0;
    }
  }
}

.result--crew-basic {
  .avatar {
    max-height: 7 * $su;
    overflow: hidden;
  }
}




/*
**   Jobs
*/

.result--job {
  padding: 0;
  
  .title {
    padding: $su / 2 $su;
    
    h2 {
      margin: 0;
    }
  }
  
  .content {
    padding: $su / 2 $su;
  }
  
  &.featured {
    background-color: $pale-blue;
    
    .title {
      background-color: #a8ecff
    }
    
    .skipper {
      border-color: #a8ecff;
      
      > p {
        background-color: #a8ecff
      }
    }
  }
  
  ul.result--contacts {
    float: right;
    vertical-align: top;
    
    li {
      display: inline-block;
      vertical-align: top;
      
      img {
        vertical-align: top;
      }
    }
  }
  
  h2 {
    font-weight: 400;
    
    .position {
      font-weight: 700;
    }
  }
  
  .result--thumbnails {
    display: none;
    
    @include media-query('phablet') {
      display: block;
    }
  }
      
  .result--thumbnail {
    .container {
      padding-bottom: 100%;
      margin: $su 0 0 0;
      border: solid 1px $silver;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      
      @include media-query('phablet') {
        margin-top: 0;
        margin-bottom: $su / 2;
      }
      
      @include media-query('lap') {
        margin-bottom: 0;
      }

      img {
        min-height: 100%;
        position: absolute;
      }      
    }
  }
  
  .result--thumbnail + .result--thumbnail .container {
    @include media-query('lap') {
      margin-top: $su / 2;
    }    
  }
  
  .result--details {
    margin-top: $su !important;
    
    @include media-query('lap') {
      margin-top: 0 !important;
    }
    
    .label {
      width: 90px;
      text-align: right;
      margin-right: $su / 2;
      font-weight: 700;
      display: inline-block;
    }
  }
  
  .skipper {
    background-color: white;
    border: solid 2px $silver;
    margin-top: $su;
    display: none;
    
    @include media-query('lap') {
      margin-top: 0;
      display: block;
    }
    
    > p {
      background-color: $silver;
      margin: 0;
      padding-left: $su / 4;
    }
    
    .skipper--photo {
      width: 25%;
//      min-height: 70px;
      border-right: solid 2px $silver;
      display: inline-block;
      vertical-align: top;
    }
    
    .skipper--info {
      width: 75%;
//      min-height: 80px;
      padding-top: $su / 2;
      display: inline-block;
      box-sizing: border-box;
      vertical-align: top;
    }
    
    .label {
      width: 60px;
      color: $mid-blue;
      text-align: right;
      display: inline-block;
    }
  }
  
  .result--description {
    padding-top: 0;
    
    @include media-query ('flag') {
      padding-top: $su / 2;
    }
    
    strong a {
      color: $bright-blue;
      font-weight: 700;
    }
  }
  
  .result--stats {
    margin-top: $su;
    
    @include media-query('lap') {
      margin-top: 0;
    }
  }
}
	



/*
**   Summary
*/

#SearchSummary {
  .style {
    margin: 0;
    display: inline-block;
  }
  
  ul {
    display: inline-block;
  }
  
  li {
    background-color: $white;
    padding: 2px 4px;
  }
}




/*
**   Filters
*/

.box.filters {
  z-index: 999;
  
  .heading {
    background-color: rgba(0, 0, 0, 0.3);
    margin: 0 !important;
    padding: $su 0;
    color: $white;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    
    @include media-query('phablet') {
      cursor: default;
    }
  }
}

body.scrolled-filters .box.filters {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $dark-blue;
  
  @include media-query('phablet') {
	   width: 19%;
    max-width: 240px;
    min-width: 190px;
    background-color: transparent;
  }
}

#SearchFilters {
  display: none;
  
  @include media-query('phablet') {
    display: block;
  }
  
  .box.active & {
    display: block;
  }
  
  .box.active-init & {
    @include media-query('phablet') {
      display: block;
    }
  }
}

ul.filter--style {
  @extend .inline-list;
  
  li {
    width: 50%;
    background-color: $modal-light-gray;
    text-align: center;
    line-height: 4em;
  }
  
  .active {
    background-color: $white;
    font-weight: 700;
  }
  
  a:hover {
    text-decoration: underline;
  }
}

div.filter {
  background-color: $white;
  
  &.active {
    border-bottom: solid 1px $silver;
  }
  
  &.active-init {
    @include media-query('phablet') {
      border-bottom: solid 1px $silver;
    }
  }
  
  &.search {
  }
}

.filter--title {
  margin: 0;
  padding: 12px $su 12px $su / 2;
  border-bottom: solid 1px $silver;
  background: url('../../img/icon-plus.png') no-repeat 90% 50%;
  cursor: pointer;
    
  &:hover, 
  .filter.active &:hover, 
  .filter.active-init & {
    background-color: $pale-blue;
  }
  
  .filter.active & {
    background: url('../../img/icon-minus.png') no-repeat 90% 50%;
    border-bottom: none;
    font-weight: 700;
  }
    
  .filter.active-init & {
    @include media-query('phablet') {
      background: url('../../img/icon-minus.png') no-repeat 90% 50%;
      border-bottom: none;
      font-weight: 700;
    }
  }
  
  .filter.search & {
    background: none;
    font-weight: 700;
    border-bottom: none;
  }
}

.filter--content {
  padding: 0 $su / 2 $su $su / 2;
  display: none;
  
  .filter.active &, 
  .filter.search & {
    display: block;
  }
  
  .filter.active-init & {
    @include media-query('phablet') {
      display: block;
    }
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

ul.filter--list {
  li {
    padding-top: 3px;
    padding-bottom: 3px;
    
    @include media-query('phablet') {
      @include font-size(13px);
    }
  }
  
  li.full {
    cursor: pointer;
    color: $black;
    
    &:hover {
      color: $bright-blue;
      background-color: $pale-blue;
    }
  }
  
  li.empty {
    display: none;
    
    @include media-query('flag') {
      color: #aaa;
      display: block;      
    }
  }

  &.selected {
    margin-bottom: $su / 2;

    li {
      padding-top: 2px;
      padding-bottom: 2px;
      background: url('../../img/icon-cross.png') no-repeat 95% center $pale-blue;
    }
  }
  
  .filter.scrollable &.unselected {
    max-height: 250px;
    overflow-y: auto;
  }
}

input#search_keyword {
  width: 79%;
  height: 30px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0;
  display: inline-block;
  vertical-align: top;
  border-color: $bright-blue;
  border-right: none;
  box-sizing: border-box;
}

button.keyword-search {
  width: 20%;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  display: inline-block;
  vertical-align: top;
  padding: 0;
  box-sizing: border-box;
  background-color: $white;
  background-image: url('../../img/icon-search-blue.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 16px;
  
  &:hover {
    background-color: $bright-blue;
    background-image: url('../../img/icon-search-white.png');
  }
}

.filter--reset {
  background-color: $bright-blue;
  margin: 0 !important;
  padding: $su / 2 0;
  color: $white;
//  text-transform: uppercase;
  text-align: center;
//  font-weight: 700;
  cursor: pointer;

//  @include media-query('phablet') {
//    cursor: default;
//  }
  
  &:hover {
    background-color: lighten($bright-blue, 10%);
  }
}

/*
.filter {
  margin: 0 halve($su);
  padding-bottom: halve($su);
  
  & + & {
    padding-top: halve($su);
    border-top: solid 1px $silver;
  }
  
  .filter--title {
    font-weight: bold;
    margin: 0 0 quarter($su) 0;
    padding-left: quarter($su);
  }
}
  
ul.filter--list {
  margin: 0;
  list-style-type: none;
  
  &.selected {
    li {
      padding-top: 2px;
      padding-bottom: 2px;
      margin-bottom: 2px;
    }    
  }
    
  &.unselected {
    max-height: 300px;
    overflow-y: auto;
  }

  li {
    color: $bright-blue;
    padding-left: quarter($su);

    &.empty {
      color: $gray;
    }

    &.full {
      cursor: pointer;
    }

    &.selected {
      background-color: $pale-blue;
      cursor: pointer;
    }

    .filter--count {
      color: $gray;
    }
  }
}

.filter--reset {
  margin: halve($su) 0 $su 0;
  text-align: center;  
  cursor: pointer;
  color: $gray;
  
  &:hover {
    color: $dark-gray;
  }
}

.filter--style {
  @include font-size(17px);
  font-weight: bold;
  color: $dark-blue;
  background-color: darken($light-gray, 10%);
  cursor: pointer;
  text-align: center;
  border-top: solid 1px $silver;
  border-bottom: solid 1px $silver;
  padding: $su;
  margin-top: $su;
  
  &:hover {
    background-color: darken($light-gray, 12%);
  }
}

.filter--style-info {
  padding: 0 $su;
}
*/