fieldset.experience-log {
  
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: $white;

    thead {
      .table-heading {
        @include font-size(19px);
        font-weight: bold;
        background-color: $dark-blue;
        color: $white;
        border-left: solid 2px $dark-blue;
        border-right: solid 2px $dark-blue;
        text-align: left;
        padding: halve($su) $su;
      }

      th {      
        background-color: $modal-light-gray;
        border-left: solid 2px $modal-light-gray;
        border-right: solid 2px $modal-light-gray;
        color: $dark-blue;
        font-weight: normal;
        padding: halve($su) $su;
        text-align: left;
      }
    }

    tbody {
      border-bottom: solid 2px $modal-light-gray;
      border-right: solid 2px $modal-light-gray;

      td {      
        border-left: solid 2px $modal-light-gray;
        padding: quarter($su) $su;
      }

      tr + tr td {
        border-top: solid 1px $modal-light-gray;
      }

      tr:first-child td {
        padding-top: halve($su);
      }

      tr:last-child td {
        padding-bottom: halve($su);
      }
    }
  }
  
  .link-action--edit {
    text-transform: uppercase;
  }
  
  .link-action--delete {
    color: $gray;
    
    &:hover {
      background-color: transparent;
      color: $bright-blue;
    }
  }
}

// Fake tables

.td {
  display: inline-block;
  vertical-align: middle;
  padding: halve($su) $su;
  box-sizing: border-box;
}