html {
  min-height: 100%;
  position: relative;
  
  &.modal-open {
    overflow: hidden;
    margin: 0;
  }
}

body {
  width: 100%;
  color: #777;
  
  &.menu-open {
    position: fixed;
  }
}

main {  
  > .centered {
    padding-top: $inuit-layout-gutter;
    
    @include media-query('phablet') {
      padding-top: $inuit-layout-gutter * 2;
    }
  }
}

div.centered {
  max-width: 1300px;
  margin: 0 auto;
}

.floating-box {
  max-width: 800px;
  margin: 0 auto;
}

.hidden {
  display: none;
}

section {
  @extend .clearfix;
  padding-bottom: $su;
  margin-bottom: $su;
}

#ModalShadow {
  background-color: $modal-shadow;
  opacity: 0.75;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0; 
  z-index: 1050;
  display: none;
}

#ModalContent {
  background-color: transparent;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0; 
  z-index: 1051;
  display: none;
}

#ModalContent.photo-gallery {
  background-color: transparent;
  
  .content {
    width: 80%;
    height: 60%;
    margin: 100px auto 0 auto;
    padding: 1.5 * $su;
    overflow-y: scroll;
    background-color: $white;
  }
  
  img {
    margin-bottom: halve($su);
    cursor: pointer;
  }
}

.mfp-bg {
  z-index: 1052 !important;
}

.mfp-wrap {
  z-index: 1053 !important;
}