.dashboard-link {
  background-color: $bright-blue;
  background-color: $brand-primary;
  border: solid 2px $white;
  
  a {
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    padding: $su / 2 0;
    color: $white;
    display: block;
  }
  
  img {
    margin-right: $su / 4;
  }
}

.activity-heading {
  @extend .form-transparency;
  height: 3 * $su;
  text-align: center;
  
  img {
    display: inline-block;
    vertical-align: middle;
  }  
}

.activity-entry {
  padding: $su / 2 0;
  border-bottom: solid 1px $silver;
  position: relative;
  
  &:hover {
    background-color: $pale-blue;
  }
}



/*
** Alerts
*/

.activity-alert {
  p {
    margin: 0;
  }
  
  .from {
    font-weight: 700;
  }

  .alert--image {
    img {
      border: solid 1px $black;
    }
  }

  .alert--icon {
    text-align: center;
    vertical-align: middle;
  }

  .alert--date {
    text-align: center;
    vertical-align: middle;
  }
}



/*
** Favourites
*/

.activity-favourite {
  .favourite--details {
    p {
      margin: 0;
    }
  }
}



/*
** Browsing History
*/

.activity-history {
  .history--details {
    p {
      margin: 0;
    }
  }
  
  .history--notes {
    p {
      margin: 0;
    }
    
    .advert-title {
      font-weight: 700;
    }
  }
}