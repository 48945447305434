/**
 * CONTENTS #todo
 *
 * SETTINGS
 * Global...............Globally-available variables and config.
 *
 * TOOLS
 * Mixins...............Useful mixins.
 *
 * GENERIC
 * Normalize.css........A level playing field.
 * Box-sizing...........Better default `box-sizing`.
 *
 * BASE
 * Headings.............H1–H6 styles.
 *
 * OBJECTS
 * Wrappers.............Wrapping and constraining elements.
 *
 * COMPONENTS
 * Page-head............The main page header.
 * Page-foot............The main page footer.
 * Buttons..............Button elements.
 *
 * TRUMPS
 * Text.................Text helpers.
 */

/**
 * Inuitcss Source Order:
 *
 * Settings: 	Global variables, site-wide settings, config switches, etc.
 * Tools: 		Site-wide mixins and functions.
 * Generic: 	Low-specificity, far-reaching rulesets (e.g. resets).
 * Base: 		Unclassed HTML elements (e.g. a {}, blockquote {}, address {}).
 * Objects: 	Objects, abstractions, and design patterns (e.g. .media {}).
 * Components: 	Discrete, complete chunks of UI (e.g. .carousel {}). This
 * 				is the one layer that inuitcss doesn’t get involved with.
 * Trumps: 		High-specificity, very explicit selectors. Overrides and helper
 * 				classes (e.g. .hidden {}).
 *
 **/

$ie8-lower: false;

	@import "settings.crewbay";
@import "bower_components/inuit-defaults/settings.defaults";
@import "bower_components/inuit-responsive-settings/settings.responsive";

// TOOLS:
@import "bower_components/inuit-functions/tools.functions";
@import "bower_components/inuit-mixins/tools.mixins";
@import "bower_components/inuit-responsive-tools/tools.responsive";
@import "bower_components/inuit-tools-widths/tools.widths";
	@import "tools.mixins";
	@import "tools.aliases";

// GENERIC:
@import "bower_components/inuit-normalize/generic.normalize";
@import "bower_components/inuit-box-sizing/generic.box-sizing";
@import "bower_components/inuit-reset/generic.reset";
@import "bower_components/inuit-shared/generic.shared";

// BASE:
$inuit-heading-size-1: 48px;
$inuit-heading-size-2: 30px;
$inuit-heading-size-3: 24px;
$inuit-heading-size-4: 20px;
$inuit-heading-size-5: 18px;
$inuit-heading-size-6: 16px;
@import "bower_components/inuit-headings/base.headings";

// $inuit-lede-size: 1.125;
// @import "bower_components/inuit-paragraphs/base.paragraphs";
@import "bower_components/inuit-lists/base.lists";
@import "bower_components/inuit-images/base.images";
@import "bower_components/inuit-page/base.page";
	@import "base.fonts";
	@import "base.links";
	@import "base.page";
	@import "base.headings";
	@import "base.images";

// OBJECTS:
@import "bower_components/inuit-block/objects.block";
// $inuit-enable-box--flush: true;
// @import "bower_components/inuit-box/objects.box";

@import "bower_components/inuit-buttons/objects.buttons";

$inuit-enable-flag--rev: true;
$inuit-enable-flag--responsive: true;
$inuit-flag-collapse-at:        450px;
@import "bower_components/inuit-flag/objects.flag";

$inuit-enable-layout--flush: true;
$inuit-enable-layout--middle: true;
$inuit-enable-layout--rev: true;
$inuit-enable-layout--center: true;
// $inuit-enable-layout--bottom: true;
@import "bower_components/inuit-layout/objects.layout";
	@import "objects.buttons";

@import "bower_components/inuit-list-bare/objects.list-bare";
@import "bower_components/inuit-list-block/objects.list-block";
@import "bower_components/inuit-list-inline/objects.list-inline";
@import "bower_components/inuit-list-ui/objects.list-ui";
@import "bower_components/inuit-media/objects.media";
@import "bower_components/inuit-pack/objects.pack";
$inuit-enable-table--cosy: true;
$inuit-enable-table--fixed: true;
@import "bower_components/inuit-tables/objects.tables";
@import "bower_components/inuit-tabs/objects.tabs";

// COMPONTENTS:
// > Your components here <
	@import "block.link";

	@import "components.activity";
	@import "components.advertising";
	@import "components.auth";
	@import "components.breadcrumb";
	@import "components.box";
	@import "components.buttons";
	@import "components.checkin";
	@import "components.circle";
	@import "components.dashboard";
	@import "components.home";
	@import "components.header";
	@import "components.footer";
	@import "components.fonts";
	@import "components.forms";
	@import "components.jobs";
	@import "components.menu";
	@import "components.messaging";
	@import "components.nav";
	@import "components.notifications";
	@import "components.premium";
	@import "components.profile";
	@import "components.promotion";
	@import "components.search";
	@import "components.stripe";
	@import "components.structure";
	@import "components.support";
	@import "components.tabs";
	@import "components.table";
	@import "components.themify";
	@import "components.viewer";
	@import "components.welcome";
	@import "components.wrap";

// TRUMPS:
@import "bower_components/inuit-clearfix/trumps.clearfix";
@import "bower_components/inuit-headings-trumps/trumps.headings";
$inuit-enable-margins--none: true;
@import "bower_components/inuit-spacing/trumps.spacing";
@import "bower_components/inuit-spacing-responsive/trumps.spacing-responsive";

@import "bower_components/inuit-widths/trumps.widths";
	@import "trumps.widths-responsive";
@import "bower_components/inuit-widths-responsive/trumps.widths-responsive";

@import "bower_components/inuit-print/trumps.print";
@import "trumps.utility";
@import "trumps.layout";
