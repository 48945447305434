/**
 * Block Link
 *
 * A Faux block-level link. Used for when you need a block-level link with
 * clickable areas within it as directly nesting a tags breaks things.
 */


.block-link {
    position: relative;

    .c-inner {
        h2,h3 {
            transform: scale(1);
            transition: transform $global-transition;
        }
    }

    @include hocus() {
      .c-btn {

        &:before {
			opacity: 1;
			transition: opacity $global-transition;
		}
      }

      .c-inner {
        h2,h3 {
        transform: scale(1.1);
        }
      }
    }
}

// If this block-link has child block-links then steal the positioning context
// from them so that their block-link covers the entire page
.block-link--steal {
    .block-link {
        position: static;
    }
}

// Links within the block that we want to sit on top of the overlay link

.block-link a,
.block-link abbr[title],
.block-link__link {
    position: relative;
    z-index: 1;
}

.block-link__overlay-link {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    text-indent: 200%;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.1); // IE9 fix
    // @if $ie8-lower == true {
    //     // IE8 fix, rgba doesn't work so needs to be background image
    //     background:url(../images/transparent-ie8.png);
    // }
}


// Increased specificity so it trumps ".block-link a"
a.block-link__overlay-link {
    position: absolute;
    // I changed this to z-index 1, so that it works for me in this project...
    z-index: 1;
    // this line is needed as all elements have a solid black
    // background in high contrast mode
    opacity: 0;
}

// .block-link {
//     @include hocus() {
//         a:not(.c-btn) {
//             color: $link-hover;
//             text-decoration: underline !important;
//         }
//         .c-btn {
//             background: $light;
//         }
//     }
// }
