section.support {
  max-width: 1000px;
  margin: 0 auto;
}

.support--heading {
  height: 208px;
  text-align: center;
  background: url('../../img/support-heading.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
  
  h1 {
    @include font-size(61px);
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    color: $white;
    margin: 0;
  }
}

.support--content {
  background-color: $modal-light-gray;
}

ul.support--menu {  
  li {    
    a {
      @include font-size(29px);
      color: $modal-light-gray;
      background-color: $bright-blue;
      text-align: center;
      padding: $su 0;
      display: block;
    }
  }
  
  li.active {    
    a {
      font-weight: 700;
      color: $bright-blue;
      background-color: $modal-light-gray;
    }
  }
}



/*
** How It Works
*/

.support--how {
  padding: 2 * $su 3 * $su;
  
  > .layout {
    padding: $su 0 $su * 2 0;
  }
  
  p {
    @include font-size(16px);
    line-height: 1.5em;
  }
  
  .heading {
    margin-bottom: 0;
    font-weight: 700;
  }
}

ul.how-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: block;
  margin-bottom: 2 * $su !important;
  
  @include media-query('phablet') {
    border-bottom: solid 1px $gray;
    margin-bottom: $su !important;
  }  
    
  li {
    margin: 0 $su / 2 0 0;
    
    @include media-query('phablet') {
      display: inline-block;
      margin: 0 $su;
    }    
  }
  
  a {
    @include font-size(19px);
    padding: $su / 2 $su;
    display: block;
  }

  li.active a {
    color: $gray;
    border: solid 1px $gray;
    
    @include media-query('phablet') {
      border-bottom: none;
    }    
  }  
}

.support--testimonials {
  padding: 2 * $su;
  
  .testimonial--form {    
    .entry {
      padding-bottom: $su / 4;
    }
    
    label {
      vertical-align: top;
      
      @include media-query('phablet') {
        width: 30%;
        display: inline-block;
      }
    }
    
    input[type="text"], 
    input[type="email"],
    textarea {
      @include media-query('phablet') {
        width: 70%;
        display: inline-block;
      }
    }
    
    .buttons {
      @include media-query('phablet') {
        padding-left: 30%;
      }
    }    
  }
  
  .testimonial--handle {
  }
  
  .testimonial {
    margin-top: $su;
  }
  
  .testimonial--content {
    background-color: $white;
    padding: $su 2 * $su;
    border-radius: $su;
    
    p {
      font-family: $font--serif;
      @include font-size(21px);      
      text-align: center;
      color: $black;
      margin: 0;
    }
  }
  
  .testimonial--info {
    .avatar {
      max-width: 80px;
      margin: 0 auto $su auto;
      
      img {
        margin-top: $su;
        
        @include media-query('phablet') {
          margin-top: 0;
        }
      }
    }
    
    p {
      font-family: $font--serif;
      text-align: center;
      margin: 0;
    }
  }
}



/*
** Help Centre 
*/

.support--content {
  h2.help--heading {
    padding: $su;
    margin: 0;
  }
}

.help--menu {
  margin: 0;
  padding: 0;
  
  h3 {
    background-color: transparent;
    border: none;
    
    &.ui-accordion-header-active {
      background-color: $bright-blue;
      color: $white;
    }
  }
  
  .ui-widget-content {
    background-color: transparent;
    border: none;
    padding: 0;
  }
  
  ul {
    margin: 0;
    padding: 0;
    display: block;
    
    .active {
      background-color: $white;
    }
  }
  
  li {
    padding: $su / 2 $su;
  }
}

.help--content {
  background-color: $white;
  padding: $su 2 * $su;
}



/*
** Contact
*/

.support--contact {
  padding: 2 * $su;
  
  .entry {
    vertical-align: top;
    padding-bottom: $su / 2;
  }
  
  .entry--captcha {
    @include media-query('phablet') {
      padding-left: 30%;
    }
  }
  
  label {
    vertical-align: top;
    
    @include media-query('phablet') {
      width: 30%;
      display: inline-block;
    }
  }
  
  select,
  input[type="email"],
  textarea {
    @include media-query('phablet') {
      width: 60%;
      display: inline-block;
    }
  }
  
  .buttons {
    padding-top: $su;
    
    @include media-query('phablet') {
      padding-left: 30%;
    }
  }
}