.box--membership {
  background-color: $white;
}

.membership-header {
  background-color: #0176c6;
  background-image: linear-gradient(to right, #d10083, #0176c6);
  padding: 0 $su-large;
  margin-bottom: 0;
  
  .icon-bg {
    background: url('../../img/icons/premium-wheel@2x.png') no-repeat left center;
    /*background-size: 67px 59px;*/
    background-size: 134px 118px;
    padding: 50px 0 50px 150px;
  }
  
  h2 {
    font-family: $header-font;
    @include font-size(31px);
    color: $white;
    font-weight: 300;
    margin: 0;
    padding: 0;
    
    span {
      font-weight: 700;
    }
  }
  
  p {
    font-family: $header-font;
    @include font-size(20px);
    font-weight: 300;
    color: $white;
    margin: 0;
    padding: 0;
  }
}

.membership-details {
  padding: $su-large;
  margin-bottom: 0;
  border-bottom: dotted 1px $silver;
  color: $deep-blue;
  
  @include media-query('lap') {
    background: url('../../img/bg-premium.jpg') no-repeat top right $white;
    background-size: cover;
  }
    
  p, li {
    font-family: $header-font;
    @include font-size(16px);
    line-height: 1.5em;
    color: $deep-blue;
    
    .bold {
      font-weight: 600;
    }
  }

  .membership-price {
    font-family: $header-font;
    @include font-size(41px);
    font-weight: 700;
    color: $ocean-blue !important;
    margin: $su 0;

    span {
      @include font-size(17px);
      font-weight: 400;
//      display: block;
    }
  }
  
  .membership-offer {
    @include font-size(35px);
    font-weight: 600;
    margin-top: $su;
    margin-bottom: 0;
  }
}

.membership-accordion {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: dotted 1px $silver;
  
  .membership-title {    
    background: url('../../img/icons/handle-viewer-closed@2x.png') no-repeat 96% center;
    background-size: 40px 40px;
    background-color: #f5f5f5;
    padding: $su;
    cursor: pointer;
    
    h3 {
      @include header-font();
      @include font-size(21px);
      font-weight: 300;
      padding-left: 60px;
    }
  }
  
  .membership-content {
    max-height: 0;
    opacity: 0;  
    overflow: hidden;
  }
  
  &.open {
    .membership-title {
      background-image: url('../../img/icons/handle-viewer-open@2x.png');
    }
    
    .membership-content {
      padding: $su-large;
      height: auto;
      max-height: 999999px;
      opacity: 1;
    }
  }
}

.membership-verification {
  h3 {
    background: url('../../img/verification-shield-green.png') no-repeat 10px center;    
    background-size: 20px 24px;
  }
}

.membership-comparison {
  h3 {
    background: url('../../img/icons/scales@2x.png') no-repeat 6px center;    
    background-size: 28px 24px;
  }
}

.membership-questions {
  h3 {
    background: url('../../img/icons/membership-questions@2x.png') no-repeat 6px center;    
    background-size: 27px 24px;
  }
}

.membership-payment-options {
  span {
    height: 40px;
    line-height: 37px;
    padding: 0 $su;
    background-color: $white;  
    text-align: center;
    vertical-align: middle;
    display: block;
    
    @include media-query('flag') {
      display: inline-block;
    }
    
    &.btn--payment {
      border: solid 1px $gray;
      border-radius: 5px;
      color: $font-blue;
      font-weight: 700;
      cursor: pointer;
    }
    
    &.btn--payment-cards {
      padding-left: 50px;
      background-image: url('../../img/icons/icon-payment-cards@2x.png');
      background-size: 20px 14px;
      background-position: 18px center;
      background-repeat: no-repeat;
    }
    
    &.or {
      margin: 0 $su-small;
      background-color: transparent;
    }
    
    svg {
      position: relative;
      top: 6px;
    }
  }
}
/*
.membership-plans {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.membership-plan {
  display: flex;
  flex-direction: column;
  border: solid 7px $dark-blue;
  border-radius: 0 0 40px 0;
  padding: 0;
  margin-bottom: $su;
  
  h4 {
    background-color: $dark-blue;
    color: $white;
    padding: $su-tiny $su $su-small $su-small;
    margin-bottom: 0;
    font-family: $header-font;
    @include font-size(19px);
  }
  
  ul {
    list-style-type: none;
    margin: $su;
    padding: 0;
  }
  
  li {
    padding-bottom: $su-small;
  }
  
  .introduction {
    font-size: 1.1em;
    color: $gray;
    font-style: italic;
    margin: $su $su 0 $su;
  }
}

.membership-plan--premium {
  
  @include media-query('lap') {
    width: 55%;
    margin-right: 2%;
  }
  
}

.membership-plan--free {
  border-color: $mid-blue;
  
  @include media-query('lap') {
    width: 40%;
  }
  
  h4 {
    background-color: $mid-blue;
  }
}
*/

section.membership-comparison.open {
  .membership-content {
    padding-top: $su;
    padding-bottom: $su;
    padding-left: 0;
    padding-right: 0;
    
    @include media-query('phablet') {
      padding-left: $su;
      padding-right: $su;
    }
  }
}

table.comparison-table {
  width: 100%;
  
  thead {
    border-bottom: solid 1px $silver;
    
    td {
      padding-bottom: $su-tiny;
    }
  }
  
  tbody {
    td {
      padding: $su 0;
    }
  }
  
  .feature {
    padding-left: $su;
    
    @include media-query('phablet') {
      padding-left: 0;
    }
    
    p {
      font-family: $header-font;
      @include font-size(16px);
      margin: 0;
    }
  
    .bold {
      font-weight: 600;
    }
  }
  
  .compare {
    width: 60px;
    text-align: center;
  }
  
  .compare--free {
    border-left: solid 1px $silver;
    padding-right: $su-small;
    
    @include media-query('phablet') {
      padding-right: 0;
    }
  }
  
}

/*
.membership-comparison {
  width: 100%;
  display: flex;
  flex-direction: column;
  
  .row {
    list-style: none;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin: 0;
    
    li {
      font-family: $header-font;
      @include font-size(16px);
      flex: 1;
      align-self: center;
      display: flex;
    }
    
    .bold {
      font-weight: 600;
    }
    
    .feature {
      width: 70%;
      flex-basis: 70%;
    }
    
    .compare {
      width: 15%;
      flex-basis: 15%;
      text-align: center;
    }
    
    .compare--free {
      @include font-size(13px);
      border-left: solid 1px red;
    }
  }
  
  img.sticker {
    margin-bottom: $su-small;
  }
}
*/

ul.membership-faq {
  margin: 0 0 0 0;
  padding: 0;
  list-style-type: none;
  
  li {
    margin-bottom: $su;
  }
  
  p {
    color: $promotion-blue;
    @include font-size(17px);
  }
  
  .highlight {
    font-weight: 400;
    color: inherit;
    text-decoration: underline;
  }
}

.membership-faq--question {
  @extend .font-bold;
  margin-bottom: $su-tiny;
}

.membership-faq--answer {
  @extend .font-regular;
}