header {
  background-color: $dark-blue;
  color: white;
  text-align: center;
  position: relative;
  z-index: 100;
  
  @include media-query('lap') {    
    text-align: right;
  }
  
  .logo {
    background-color: $white;
    text-align: center;
    padding: halve($su) 0;
    
    @include media-query('lap') {
      padding: 0;
    }
    
    img {
      max-width: 80%;
      max-height: 90%;
      
      @include media-query('lap') {
        margin-top: 5%;
      }
    }
  }
}

header.small {
  min-height: auto;
  height: $header-height-small;
  
  .navbar--center {
    height: $header-height-small;
    
    img {
      width: auto;
      height: 100%;
    }
  }
}