.advertising--vertical {
  vertical-align: top;
}

.advert--horizontal {  
  .search--listings & {
    margin-bottom: $su;
  }
}

.advert--sticky {
  body.scrolled & {
    position: fixed;
    top: 0;
  }
}