header {
  background-color: $white;
  text-align: center;
  
  @include media-query('flag') {
    min-height: $header-height;
  }
  
  @include media-query('phablet') {
    border-bottom: solid 1px $dark-blue;
  }
  
  .centered {
    height: 100%;
  }
  
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    vertical-align: middle;
    height: 100%;
  }
  
  li {  
    @include media-query('flag') {
    }
    
    @include media-query('lap') {
      height: $header-height;
      display: inline-block;
    }
  }
  
  .pages {
    display: none;
    
    @include media-query('flag') {
      display: block;
    }
    
    li {
      color: $dark-gray;
      text-transform: uppercase;
      @include font-size(15px);
      display: inline-block;
      
      @include media-query('flag') {
        line-height: $header-height;
      }
      
      @include media-query('phablet') {
        @include font-size(17px);
        line-height: $header-height;
      }
      
      &.active {
        background-color: $bright-blue;
        color: $dark-blue !important;
      }
      
      &:hover {
        color: $bright-blue;
      }
    }
    
    a {
      color: $dark-gray;
      vertical-align: middle;
    }
      
    li:hover a {
      color: $bright-blue;
    }
  }
}
  
.navbar--left {
  text-align: center;
  
  @include media-query('flag') {
    text-align: left;
  }  
  
  .pages {
    li {
      padding: 0 $su / 2;
      
      @include media-query('phablet') {
        padding: 0 $su;
      }      
    }
    
    span {
      display: none;
      
      @include media-query('lap') {
        display: inline-block;
      }
    }
  }
}

.navbar--center {
  padding: $su / 2 0;
  box-sizing: border-box;
  
  @include media-query('flag') {
    height: $header-height;
  }
  
  img {
    display: inline-block;
    vertical-align: middle;
    width: 120px;
    
    @include media-query('flag') {
      width: 212px;
    }
  }
}
  
.navbar--right {
  text-align: center;
  
  @include media-query('flag') {    
    text-align: right;
  }
  
  .pages {
    li {
      padding: 0 $su / 2;
      
      @include media-query('phablet') {
        padding: 0 $su;
      }   
    }
    
    .how {
      padding-left: 0;
      display: none;
      
      @include media-query('desk') {
        display: inline-block;
      }
    }
  }
}

[data-menu] {
  cursor: pointer;
}

#MobileSearch {
  margin-top: 30px;
  cursor: pointer; 
  
  @include media-query('flag') {
    display: none;
  }
}

#MobileLinks {
  margin-top: 30px;
  cursor: pointer; 
  
  @include media-query('flag') {
    display: none;
  }
}

.navmenu {
  display: none;
  
  &.active {
    display: block;
  }
  
  .close {
    color: $white;
    cursor: pointer;
  }
  
  .loading {
    text-align: center;
    background-color: $white;
    padding: $su-small;
  }
}

.menu--floating {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999; // Higher than tawk.to
  padding: $su;
  display: none;
  
  &.active {
    display: block;
  }
  
  @include media-query('flag') {
    top: $header-height;
    bottom: auto;
    height: auto;
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  li {    
    &:hover {
      background-color: lighten($bright-blue, 10%);
    }
    
    a {
      @include font-size(21px);
      color: $white;
      display: block;
      padding: 0 $su * 5 0 0;
    }
  }
}

.menu--blue {
  background-color: $bright-blue;
}

.menu--full {
  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0; 
  z-index: 200;
  
  .layout__item {
    text-align: center;
  }
  
  .hit-area {
    padding-top: $su;
    padding-bottom: $su;
  }
  
  p {
    color: $black;
    text-transform: uppercase;
    font-weight: 700;
    @include font-size(23px);
  }
  
  .close {
    text-align: right;
    padding: $su;
    @include font-size(17px);
  }
}

.menu--category {  
  p.label {
    color: $white;
    @include font-size(19px);
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 $su / 2 5px 0;
    
    @include media-query('desk') {
//      display: inline-block;
//      float: left;
//      clear: left;
    }
  }
  
  ul.links {
    margin: 0;
    padding: 0;
    display: inline-block;    
  }
  
  li {
    padding: $su / 4 0;
    display: block;
    
    @include media-query('phablet') {
      display: inline-block;
      margin-right: $su / 2;
      padding-right: $su / 2;
      padding-top: 0;
      padding-bottom: 0;
    }
    
    a {
      @include font-size(17px);
      color: $white;
      
      @include media-query('desk') {
        line-height: 1.2em;
      }
    }
  }
}

.menu--category + .menu--category {
  margin-top: 12px;
  padding-top: 15px;
  border-top: dotted 1px $pale-blue;
}

#MobileMenu { 
}

#MobileCrew {
  z-index: 201;
}

#MobileBoats {
  z-index: 201;
}

header .menu--profile {
  height: 100%;
  @extend .inline-list;
  display: inline-block;
  
  .handle-mobile {
    text-align: center;
    margin: 30px $su 0 0;
    
    @include media-query('flag') {
      display: none;
    }
    
    img {
    }
  }
  
  .handle {
    padding-right: $su;
    margin-right: $su;
    background: url('../../img/nav-profile-arrow.png') no-repeat right center;
    cursor: pointer;
    display: none;
    
    @include media-query('flag') {
      line-height: $header-height;
      display: inline-block;
    }
  }
    
  .avatar {
    border-radius: $su;
    width: $su * 2;
    height: $su * 2;
    line-height: 1em;
    position: relative;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
  }
}

.menu--alerts {
  @extend .inline-list;
  
  @include media-query('phablet') {
    display: inline-block;
  }  
  
  li {
    padding-right: $su;
  }
  
  a {    
    text-align: center;
    position: relative;
  }
  
  .count {
    width: $su;
    height: $su;
    border-radius: $su / 2;
    line-height: $su;
    position: absolute;
    left: 50%;
    top: 36%;
  }
  
  .alerts {
    .count {
      background-color: $green;
      color: $white;
    }
  }
  
  .messages {
    .count {
      background-color: $red;
      color: $white;
    }
  }
  
  .applications {
    .count {
      background-color: $ocean-blue;
      color: $white;
    }
  }
}

header .menu--alerts {
  display: none;
  
  @include media-query('phablet') {
    display: inline-block;
  }
  
  a {
    display: block;
    height: $header-height;
    line-height: $header-height;
  }
}


/*   Applied to alerts / messages / applications   */

.menu--notifications {  
  padding-top: 0;
  
  .centered {
    text-align: right;
  }
  
  .content {
    width: 400px;
    background-color: $modal-light-gray;
    border: solid 5px $modal-light-gray;
    border-radius: $su / 4;
    text-align: left;
    display: inline-block;
    position: relative;
    -webkit-box-shadow: 0px 9px 18px -3px rgba(112,112,112,0.53);
    -moz-box-shadow: 0px 9px 18px -3px rgba(112,112,112,0.53);
    box-shadow: 0px 9px 18px -3px rgba(112,112,112,0.53);
  }
  
  .connector {
    width: 100%;
    height: 8px;
    position: absolute;
    top: -13px;
    background: url('../../img/notifications-menu-connector.png') no-repeat center bottom transparent;
  }
  
  .title {
    @include font-size(15px);
    padding: $su / 3 $su / 2;
    border-bottom: solid 1px $modal-light-gray;
    background-color: $white;
  }
  
  .all {
    @include font-size(15px);
    font-weight: 700;
    color: $bright-blue;
    text-align: center;
    padding: $su / 3 0;
  }
  
  &.menu--floating ul {
    padding: 0;
  }
  
  li {
    display: block;
    margin-bottom: 1px;
    background-color: $white;
    position: relative;
    
    &:hover {
      background-color: darken($white, 5%);
    }
    
    &.read-0 {
      background-color: $light-blue;
      
      &:hover {
        background-color: darken($light-blue, 5%);
      }
    } 
  }
  
  .avatar {
    width: 16%;
    padding: $su / 4;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
  }
  
  .info {
    width: 84%;
    padding: $su / 4;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
  }
  
  p {
    @include font-size(13px);
    line-height: 1.1em;
    margin: 0;
    
    span {
      font-weight: bold;
    }
  }
  
  .icon {
    @include font-size(11px);
    padding-left: $su;
    background-position: 0 center;
    background-repeat: no-repeat;
  }
}



/*   Crew Menu For Mobile   */

#DesktopCrew {
  padding: 0;
  
  .content {
    padding: $su 0;   
    
    @include media-query('flag') { 
      width: auto;
      display: inline-block;
    }
    
    li {
      padding-left: $su;
      padding-right: $su;
    }
  }
}



/*   Boats Menu For Mobile   */

#DesktopBoats {
  padding: 0;
  
  .content {
    width: auto;
    padding: $su 0; 
    
    @include media-query('flag') { 
      width: auto;
      margin-left: 70px;
      display: inline-block;
    }
    
    @include media-query('phablet') {
      margin-left: 90px;
    }
    
    @include media-query('lap') {
      margin-left: 132px;
    }
    
    li {
      padding-left: $su;
      padding-right: $su;
    }
  }
}



/*   Alerts / Messages / Applications Icon Bar For Mobile   */

#MobileAlerts {  
  background-color: $white;
  border-bottom: solid 1px $dark-blue;
  border-top: solid 1px $light-gray;
  
  @include media-query('phablet') {
    display: none;
  }
  
  li {
    padding: 0;
  }
  
  .half {
    width: 50%;
  }
  
  .third {
    width: 33%;
  }
  
  .count {
    top: 12%;
  }
  
  a {
    display: block;
    height: $mobile-alerts-height;
    line-height: $mobile-alerts-height;
  }
}



/*   Profile Flyout   */

#ProfileMenu {
  padding: 0;
  bottom: 0;
  
  @include media-query('flag') {
    bottom: auto;
  }
  
  .centered {
    height: 100%;
  
    @include media-query('flag') {
      height: auto;
    }
  }  
    
  .content {
    background-color: $light-gray;
    text-align: center;
    padding: $su;
    height: 100%;
  
    @include media-query('flag') {
      height: auto;
    }

    @include media-query('phablet') {
      text-align: left;
      float: right;
    }
  }
  
  .close {
    background-color: $dark-gray;
    margin-top: 2 * $su;
    padding: $su / 2 $su;
    
    @include media-query('phablet') {
      display: none;
    }
  }
  
  .avatar {
    width: $su * 4;
    height: $su * 4;
    border-radius: $su * 2;
    border: solid 1px $silver;
    overflow: hidden;
    margin: 0 auto $su auto;
    
    @include media-query('flag') {
      display: none;
    }
  }
  
  p {
    @include font-size(15px);
  }
  
  p.screenname {
    @include font-size(19px);
    font-weight: 700;
    font-style: italic;
    margin: 0;
  }
  
  ul {
    padding: 0;    
    margin: $su;
    
    @include media-query('phablet') {
      margin: 0;
    }
  }
  
  li {
    border-top: solid 1px $silver;
    padding: 0;
    display: block;
    
    &:hover {      
      background-color: transparent;
      
      a {
        font-weight: 700;
      }
    }
  }
  
  a {
    @include font-size(15px);
    color: $bright-blue;
    display: block;
    padding: $su / 2 0;
  }
  
  .btn--blue {
    color: $white;
  }  
}



/*   General Links Flyout For Mobile  */

#LinksMenu {
  padding: 0;
  bottom: 0;
  
  @include media-query('flag') {
    bottom: auto;
  }
  
  .centered {
    height: 100%;
  
    @include media-query('flag') {
      height: auto;
    }
  }  
    
  .content {
    background-color: $light-gray;
    text-align: center;
    padding: $su;
    height: 100%;
  
    @include media-query('flag') {
      height: auto;
    }

    @include media-query('phablet') {
      text-align: left;
      float: right;
    }
  }
  
  .close {
    background-color: $dark-gray;
    margin-top: 2 * $su;
    padding: $su / 2 $su;
    
    @include media-query('phablet') {
      display: none;
    }
  }
    
  p {
    @include font-size(15px);
  }
  
  ul {
    padding: 0;    
    margin: $su;
    
    @include media-query('phablet') {
      margin: 0;
    }
  }
  
  li {
    border-top: solid 1px $silver;
    padding: $su / 2 0;
    display: block;
  }
  
  a {
    @include font-size(15px);
    color: $bright-blue;
  }
}



/*   Alerts Flyout   */

#AlertsMenu {
  .connector {
    background-position: 230px bottom;
    
    @include media-query('huge') {
      background-position: 210px bottom;
    }
  }
  
  body.login--owner & .connector {
    background-position: 180px bottom;
    
//    @include media-query('huge') {
    @media screen and (min-width: 1335px) {
      background-position: 160px bottom;
    }
  }
  
  .icon--alert-favourite {
    background-image: url('../../img/alert-favourite-small.png');
  }
  .icon--alert-info {
    background-image: url('../../img/alert-info-small.png');
    background-position: 5px center;
  }
  .icon--alert-profile {
    background-image: url('../../img/alert-profile-small.png');
  }
  .icon--alert-shortlist {
    background-image: url('../../img/alert-shortlist-small.png');
  }
  .icon--alert-team {
    background-image: url('../../img/alert-team-small.png');
  }
  .icon--alert-verified {
    background-image: url('../../img/alert-verified-small.png');
  }
}



/*   Messages Flyout   */

#MessagesMenu {
  .connector {
    background-position: 280px bottom;
    
//    @include media-query('huge') {
    @media screen and (min-width: 1335px) {
      background-position: 260px bottom;
    }
  }
  
  body.login--owner & .connector {
    background-position: 230px bottom;
    
//    @include media-query('huge') {
    @media screen and (min-width: 1335px) {
      background-position: 210px bottom;
    }
  }
  
  .icon--read-0 {
    background-image: url('../../img/icon-message-unread.png');
  }
  .icon--read-1 {
    background-image: url('../../img/icon-message-read.png');
  }
}



/*   Applications Flyout   */

#ApplicationsMenu {
  .connector {
    background-position: 280px bottom;
    
//    @include media-query('huge') {
    @media screen and (min-width: 1335px) {
      background-position: 265px bottom;
    }
  }
  
  .icon {
    padding-left: 15px;
  }
  
  .icon--read-0 {
    background-image: url('../../img/icon-candidate.png');    
  }
  .icon--read-1 {
    background-image: url('../../img/icon-candidate.png');
  }
}
