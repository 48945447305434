.o-wrapper {
	transition: transform 0.3s;
}

.wrap {
	margin: 0 auto;
	padding: 0 $inuit-base-spacing-unit;

	@include media-query('lap') {
		max-width: $container-size;
	}
}

.wrap--left {
	padding: 0 0 0 $su;
}

.wrap--flush {
	padding: 0;
}