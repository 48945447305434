body.home {
  height: 100%;
  margin-bottom: $header-height * -1;
}

section.home {
  text-align: center;  
  margin: 0;
  padding: 4 * $su 0 3 * $su 0;
  background-position: center center;
//  background-attachment: fixed;
  background-size: cover;
  
  @include media-query('phablet') {
    min-height: 100vh;
    padding: 7 * $su 0 5 * $su 0;
  }  
  
  .centered {
    width: 90%;
    max-width: 1046px;
  }
    
  h1 {
    color: $black;
    text-transform: uppercase;
    font-family: $font--home;
    font-weight: 700;
    margin: 0;
    @include font-size(45px);
    
    @include media-query('flag') {
      @include font-size(60px);
    }    
    
    @include media-query('phablet') {
      @include font-size(75px);
    }    
  }
  
  h2 {
    width: 100%;
    max-width: 820px;
    color: #1b2132;
    text-transform: uppercase;
    margin: 0 auto 2 * $su auto;
    font-weight: 300;
    overflow: hidden;
    @include font-size(21px);
    
    @include media-query('flag') {
      @include font-size(26px);
    }    
    
    @include media-query('phablet') {
      @include font-size(31px);
      margin-bottom: 4 * $su;
    }    
    
    &:before, &:after {
      content: "";
      display: inline-block;
      width: 50%;
      vertical-align: middle;
      border-bottom: solid 1px $black;
      margin: 0 0.5em 0 -55%;
    }
    
    &:after {
      margin: 0 -55% 0 0.5em;
    }    
  }
  
  .button-container {
    margin-top: 2 * $su;
  }
  
  .btn {
    min-width: 240px;
    text-transform: uppercase;
    background-color: $bright-blue;
    border: solid 1px $white;
    box-sizing: border-box;
    padding-top: $su / 1.5;
    padding-bottom: $su / 1.5;
  }
  
  .u-1\/2-phablet {
    padding-bottom: 2 * $su;
    
    @include media-query('phablet') {
      padding-bottom: 0;
    }
  }
}

/* Backgrounds */

section.home--welcome {
  background-color: #0e99ff;
}

section.home--professional {
  background-color: #f2f7f9;
  
  @include media-query('phablet') {
    background-image: url('../../img/backgrounds/home-professional.jpg');
  }
}

section.home--recreational {
  background-color: #c9e3fa;
  
  @include media-query('phablet') {
    background-image: url('../../img/backgrounds/home-recreational.jpg');
    background-position: center center;
  }
}

section.home--stats {
  background-color: #002545;
}



/* Search */

section.home--search {
  padding-top: $header-height;
  padding-bottom: 0;
  margin-top: $header-height * -1;
  
  @include media-query('phablet') {
    padding-bottom: 5 * $su;
  }
  
  .centered {
    width: 100%;
  }
  
  fieldset {
    background-color: rgba(255, 255, 255, 0.4);
    padding: $su * 2 $su $su * 4 $su;
    
    @include media-query('phablet') {
      margin-top: 18%;
    }
    
    @include media-query('lap') {
      max-width: 826px;
      margin: 18% auto 0 auto;
      border-radius: $su;
      padding-bottom: $su * 2;
    }
  }
  
  .label {
    text-transform: uppercase;
    color: $white;
    @include font-size(31px);
    font-weight: 300;
    
    @include media-query('lap') {
      display: inline-block;
      vertical-align: middle;
      margin-right: $su;
      margin-bottom: 0;
    }
  }
  
  .entry {
    padding-bottom: $su;
    
    @include media-query('phablet') {
      padding-bottom: 0;
      display: inline-block;
      vertical-align: middle;
    }
  }
  
  label {
    height: 3 * $su;
    background: rgba(9, 46, 121, 0.5);
    border: solid 1px $white;
    border-radius: $su / 2;
    display: block;
    cursor: pointer;
    position: relative;
    
    @include media-query('phablet') {
      width: auto;
      margin-right: $su;
    }
    
    &:hover {
      background: rgba(9, 46, 121, 0.8);
    }
  }
  
  input[type="text"] {
    color: $white;
    @include font-size(21px);
    height: 3 * $su;
    line-height: 3 * $su;
    background-color: transparent;
    border: none;
    padding: 0 0 0 $su;
    margin: 0;
    display: inline-block;
    cursor: pointer;
    float: left;
    
    @include media-query('phablet') {
      width: 230px;
    }
  }
  
  span.switch {
    width: 60px;
    height: 100%;
    background-color: $bright-blue;
    border-radius: 0 $su / 2 $su / 2 0;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    
    img {
      vertical-align: middle;
      transition: transform 0.5s ease;
    }
  }
  
  label:hover .switch img {
    transform: rotate(180deg);
  }
  label:active .switch img {
    transform: rotate(0deg);
  }
  
  input[type="submit"] {
    width: 100%;
    height: 3 * $su;
    border: solid 1px $white;
    border-radius: $su / 2;
    background-color: rgba(9, 46, 121, 0.5);
    background-repeat: no-repeat;
    background-position: center center;
    @include header-font();
    @include font-size(21px);
    color: $white;
    text-transform: uppercase;
    display: block;
    
    @include media-query('phablet') {
      width: 4 * $su;
      display: inline-block;
      vertical-align: middle;
      background-image: url('../../img/home-search.png');
      color: transparent;
      font-size: 1px;
    }
    
    &:hover {
      background-color: rgba(9, 46, 121, 0.8);
    }
  }
}



/* Welcome */

section.home--welcome {
  padding-top: 4 * $su;
  min-height: 0;
  
  h2 {
    margin-bottom: 3 * $su;
  }
  
  p {
    max-width: 680px;
    margin: 0 auto;
    color: $white;
    @include font-size(19px);
    font-weight: 300;
    line-height: 1.7em;
  }
  
  .btn {
    background-color: transparent;
    padding-left: 2 * $su;
    padding-right: 2 * $su;
  }
}



/* Professional */

section.home--professional {
  p {
    color: $black;
    @include font-size(17px);
    font-weight: 300;
    line-height: 1.7em;
    padding: 0 $su;
  }
  
  .title {
    font-weight: 700;    
    @include font-size(19px);
    text-transform: uppercase;
  }
  
  .button-container {
    padding: 0;
  }
}



/* Recreational */

section.home--recreational {
  h1 {
    color: $white;
  }
  
  p {
    color: $black;
    @include font-size(17px);
    font-weight: 300;
    line-height: 1.7em;
    padding: 0 $su;
  }
  
  .title {
    font-weight: 700;    
    @include font-size(19px);
    text-transform: uppercase;
  }
  
  .button-container {
    padding: 0;
  }
}

  
  
/* Stats */

section.home--stats {
  .title {
    color: #0e99ff;
    text-transform: uppercase;
    font-family: $font--home;
    font-weight: 700;
    @include font-size(45px);
    
    @include media-query('phablet') {
      @include font-size(75px);
    }    
  }
  
  .subtitle {
    color: #0e99ff;
    font-weight: 300;
    @include font-size(17px);
  }
  
  .bubbles {
    margin-top: 5 * $su;
  }
  
  .bubble {
    width: 204px;
    height: 204px;
    border-radius: 102px;
    background-color: #184871;
    margin: 0 auto $su;
    padding: 14% 12% 0 12%;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    
    p {
      color: $white;
      margin: 0;
    }
    
    .count {
      @include font-size(60px);
      font-weight: 700;
      margin: $su / 2 0;
    }
    
    .label {
      @include font-size(17px);
      text-transform: uppercase;
    }
  }
  
  .button-container {
    margin-top: 3 * $su;
  }
}