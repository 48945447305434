@include media-query('desk') {
	// .layout--normal-desk {
	// 	direction: ltr;
	// 	text-align: left;

	// 	.layout__item {
	// 		direction: rtl;
	// 		text-align: left;
	// 	}
	// }
}


.gutter {
	display: none;

	@include media-query('huge') {
		display: inline-block !important;
	}
}

.hide-no-js {
	display: none;
}

.hide-mobile {
  display: none;
  
  @include media-query ('phablet') {
    display: inline-block;
  }
}
.mobile-only {
  @include media-query ('phablet') {
    display: none;
  }
}



.hide--lap {
	@include media-query(lap) {
		display: none;
	}
}
.hide--desk {
	@include media-query(desk) {
		display: none;
	}
}

.show--mobile {
	display: block;
}
.show--lap {
	@include media-query(lap) {
		display: block;
	}
}
.show--desk {
	@include media-query(desk) {
		display: block;
	}
}

/**
* All images within a figure use picturefill and will always
* look good enough to show at 100%, when in the grid
**/

// figure .image-link img {
// 	width: 100%;
// }

/**
* Video container stuff (16:9)
**/
.responsive-container { 
	position: relative; 
	padding-bottom: 56.25%; 
	height: 0; 
	overflow: hidden; 
	margin-bottom: $inuit-base-spacing-unit;

		iframe, object, embed { 
			position: absolute; 
			top: 0; 
			left: 0; 
			width: 100%; 
			height: 100%; 
		}
}

.columns {
	column-count: 2;
	column-width: 320px;
	column-fill: balance;
	margin: 0;
}