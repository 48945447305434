$width-profile: 250px;

$hover-colour: darken($light-gray, 10%);

@include media-query('phablet') {
  body.menu-open {
    header {
      width: 100%;
      position: relative;
      z-index: 2147483638; // max
    }
  }
}

.global-menu {
  width: 100%;
  height: 100vh;
  background-color: $light-gray;
  position: fixed;
  /*left: -100%;*/
  top: -1000px;
  z-index: 2147483637; // max - 1
  transition: all 0.4s ease;
  
  &.active {
    top: 0;
  }
  
  .centered {
    position: relative;
    
    @include media-query('phablet') {
      height: 100%;
      padding-right: $width-profile;
    }
  }
  
  .menu-close-container {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
    text-align: center;
  }
  
  .menu-close {
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: $mid-gray;
    text-align: center;
    font-size: 30px;
    cursor: pointer;
    transition: all 0.2s linear;
    
    &:hover {
      color: $bright-blue;
    }
  }
  
  &.not-logged-in {
    .centered {
      padding-top: 90px;
    }
  }
  
  @include media-query('phablet') {
    height: auto;    
    background-color: $bright-blue;
    box-shadow: 0 1px 50px 10px #333;
    
    &.active {
      top: $header-height;
    }
    /*
    .centered {
      padding-left: $su;
      padding-right: $su;
    }
    */
    .menu-close-container {
      width: 40px;
      height: 40px;
      line-height: 40px;
      top: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.7);
      color: $white;
      box-sizing: border-box;
      position: absolute;
    }
    
    .menu-close {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 25px;
      color: $white;
    }
    
    .centered {
      min-height: 480px;
      overflow-y: auto;
    }
  }
}

.global-menu--profile {
  padding: 20px 0 0 0;
  text-align: center;
  overflow: hidden;
  
  .avatar {
    position: relative;
    
    .image-container {
      width: $su * 4;
      height: $su * 4;
      border-radius: $su * 2;
      border: solid 1px $silver;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      overflow: hidden;
      margin: 0 auto $su auto;

      @include media-query('flag') {
        width: $su * 8;
        height: $su * 8;
        /*border-color: transparent;*/
      }
      
      @include media-query('phablet') {
        width: 100%;
        padding-bottom: 100%;
        margin-bottom: 0;
        border-radius: 0;
        border: none;
      }
    }
  }
  
  p {
    @include font-size(13px);
  }
  
  p.screenname {
    @include font-size(19px);
    font-family: $font--primary;
    font-weight: 600;
    color: $font-color;
    margin: 0 0 $su-tiny 0;
  }
  
  p.membership-status {
    margin-bottom: 10px;
  }
  
  .profile-links {
    list-style: none;
    margin: $su auto 0 auto;
    padding: 0;
    display: none;
    
    li {      
      text-align: center;
      display: block;
    }
    
    li + li {
      margin-top: $su;
    }
    
    a {
      @include font-size(15px);
      background-color: $white;
      color: $dark-blue;
      padding: 8px $su;
      text-transform: uppercase;
      display: block;
      transition: all 0.2s ease;
      
      &:hover {
        background-color: $light-blue;
      }
    }
  }
  
  @include media-query('phablet') {
    width: $width-profile;
    height: 100%;
    padding: 40px $su $su $su;
    padding: 0;
    background-color: $dusky-blue;
    background-color: $light-gray;
    position: absolute;
    top: 0; 
    bottom: 0;
    right: 0;
    z-index: 2;
    
    .overlay {
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      position: absolute;
      bottom: 0;
      padding-top: 7px;

      p {
        color: $white;
      }
    }

    .avatar {
      margin-bottom: $su-small;
      
      .image-container {
        overflow: hidden;
      }
      
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    
    p.screenname {
      @include font-size(19px);
      margin-bottom: 0;
    }
  
    p.membership-status {
      @include font-size(13px);
      margin-bottom: 7px;
    }
    
    .profile-links {
      display: block;
    }
  }
}

@media screen and (max-height: 550px) and (max-width: 500px) {
  .global-menu--profile {
    .avatar {
      .image-container {
        display: none;
      }
      
      .overlay {
        text-align: left;
        padding-left: $su;
      }
    }
  }
}



/* 
Menu
*/

$menu-icon-width: 40px;

.global-menu--menu {
  position: relative;
  overflow: hidden;
  margin-bottom: $su;
  
  @include media-query('phablet') {
    /*
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    align-items: stretch;
    justify-content: flex-start;
    */
    padding-top: $su;
    padding-bottom: 0;
    padding-left: $su;
    padding-right: $su;
    margin-bottom: 0;
    text-align: right;
    direction: rtl;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .primary-menu {
    @include media-query('phablet') {
      display: none;
    }
    
    .icon {
      width: $menu-icon-width;
      display: inline-block;
      
      a {
        width: 100%;
        height: 100%;
        font-size: 17px;
        line-height: 100%;
        text-align: center;
        color: $black;
        display: block;
      }
    }
    
    .icon-close {
      height: $menu-icon-width;
      position: absolute;
      left: -100%;
      top: 0;
      background-color: $hover-colour;
      cursor: pointer;
      transition: left 0.1s linear;
      font-size: 17px;
      text-align: center;
      
      &.active {
        left: 0;
      }
      
      span {
        color: $font-color;
        position: relative;
        top: 7px;
      }
    }
    
    .icon-chevron {
      width: $menu-icon-width;
      background: transparent;
      text-align: center;
      font-size: 17px;
      color: $black;
    }
    
    li {
      height: $menu-icon-width;
      position: relative;
      
      &:hover {
        background-color: $hover-colour;
      }        
      
      &.active {
        background-color: $bright-blue !important;
        
        a {
          color: $white;
        }
      }
    }
    
    .link {
      width: calc(100% - 80px);
      display: inline-block;
      
      a {
        @include font-size(17px);
        line-height: $menu-icon-width;
        color: $font-color;
        display: block;
      }
    }
  }
  
  .sub-menu {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    transition: all 0.1s linear;
    background-color: $bright-blue;
    padding: 2px $su;
    padding-right: $su + $menu-icon-width;
    text-align: left;
    direction: ltr;
    
    &.active {
      left: $menu-icon-width;
    }
    
    a {
      @include font-size(17px);
      color: $white;
      padding: 9px 0;
      display: block;      
    }
    
    li + li {
      border-top: solid 1px rgba(255, 255, 255, 0.5);      
    }
    
    p {
      @include font-size(18px);
      font-weight: 600;
      color: $white;
      border-bottom: solid 1px $white;
      padding-bottom: $su-small;
      margin-bottom: $su-small;
      display: none;
      
      span {
        width: $su;
        font-size: 15px;
        margin-right: 2px;
        /*color: rgba(255,255,255,0.8);*/
        color: $white;
        display: inline-block;
      }
    }
    
    @include media-query('phablet') {     
      /*flex: 30%;*/
      height: auto;
      position: relative;
      top: auto;
      left: auto;
      padding: 0;
      margin-top: 0;
      margin-bottom: $su-large;
      /*margin-left: 4%;*/
      margin-right: 4%;
      display: inline-block;
      width: 47%;
      vertical-align: top;
      
      &:nth-of-type(odd) {
        /*margin-left: 0;*/
        margin-right: 0;
      }
      
      &.active {
        left: 0;
      }
      
      li + li {
        border-top: none;
        /*margin-top: 7px;*/
      }
      
      li:first-of-type {
        padding-left: 0;
      }
      
      li:last-of-type {
        padding-right: 0;
      }
      
      p {
        display: block;
      }
      
      a {
        @include font-size(15px);
        padding: 3px 0 3px $su;
        
        &:hover {
          /*text-decoration: underline;*/
          background-color: darken($bright-blue, 5%);
        }
      }
    }
  }
}
/*
@media screen and (min-height: 741px) {
  @include media-query('phablet') {
    #sub-menu-profile {
      width: 90%;
    }
  }
    
  @include media-query('lap') {
    #sub-menu-profile {
      width: 45%;
    }
  }
}
*/
@media screen and (min-width: 640px) and (max-height: 740px) {
  #sub-menu-promote {
    display: none;
  }
}

@media screen and (min-width: 900px) and (max-height: 740px) {
  #sub-menu-promote {
    display: inline-block;
  }
}

@media screen and (min-width: 1200px) {
  .global-menu--menu {
    .sub-menu {
      width: 34%;
    }
  }
}

@media screen and (min-width: 900px) {
  .global-menu--menu {
    .sub-menu {
      width: 31%;
      margin-right: 2%;
      
      &:nth-of-type(odd) {
        margin-right: 2%;
      }
      
      &:nth-of-type(1), &:nth-of-type(4) {
        margin-right: 0;
      }
    }
  }
}



/* 
Links
*/

.global-menu--links {  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
    
  li {
    @include font-size(17px);
    margin-bottom: 2px;
    
    a {
      width: 100%;
      @include font-size(17px);
      color: $font-color;
      line-height: $menu-icon-width;
      text-align: right;
      color: $font-color;
      display: inline-block;
    }
      
    &:hover {
      background-color: $hover-colour;
    }
  }
  
  li.highlight { 
    background-color: $red;
      
    &:hover {
      background-color: #FD4040;
    }
    
    a, .icon {
      color: $white;
    }
  }
  
  .icon {
    width: $menu-icon-width;
    color: $font-color;
    @include font-size(17px);
    text-align: center;
    display: inline-block;
    vertical-align: middle;
  }
  
  @include media-query('phablet') {
    width: $width-profile;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 3;
    
    li {
      /*background-color: darken($dusky-blue, 5%);*/
      margin-bottom: 0;
      /*
      &:hover {
        background-color: lighten($dusky-blue, 5%);
      }
      
      a, .icon {
        color: $white;
      }
      */
    }    
    
    li + li {
      margin-top: 1px;
    }
    
    li.highlight {      
      font-weight: 400;
      background-color: transparent;
      
      &:hover {
        background-color: $hover-colour;
      }
      
      .word-premium {
        color: $red;
        font-weight: 600;
      }
      
      a, .icon  {
        color: $font-color;
      }
      
      .ti-anchor {
        padding: 4px;
        background-color: $red;
        color: $white;
      }
    }
  }
}