.no-border {
  border: none !important;
}

.no-cursor {
  cursor: auto !important;
}

.silver {
  color: $silver;
}

.content--toggle {
  cursor: pointer;
}

.content--hidden, .u-hidden {
  display: none;
}

.u-inline-block {
  display: inline-block;
}

.u-block {
  display: block;
}

.u-ta-center {
  text-align: center;
}
.u-ta-right {
  text-align: right !important;
}

.status-indicator {
  padding: quarter($su) $su;
  margin-right: $su;
  text-transform: uppercase;
  display: inline-block;

  &.status--confirmed {
    background-color: $green;
    color: $white;
  }

  &.status--unconfirmed {
    background-color: $silver;
    color: $dark-gray;
  }
}

.inline-shift {
  // extra bit of shift required due to line breaks
  position: relative;
  left: 3px;
}

ul.inline-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  
  li {
    display: inline-block;
  }
}

hr {
  height: 1px;
  border: none;
  background-color: $silver;
}

.gutter-right {
  @include media-query('lap') {
    padding-right: $inuit-padding--small;
  }
}

.vert-aligner {
  height: 100%;
  vertical-align: top;
  display: inline-block;
  line-height: 0;
}

.u-pointer {
  cursor: pointer;
}

.u-pad {
  padding: $su;
}
.u-pad-h {
  padding-left: $su;
  padding-right: $su;
}

.u-nopad {
  padding: 0 !important;
}
.u-nopad-top {
  padding-top: 0 !important;
}

.u-nomar-bottom {
  margin-bottom: 0 !important;
}

/* Probably a better way to do this but let's hack for now until spec. is clearer */
.show-phablet {
  display: none;
  
  @include media-query('phablet') {
    display: inline-block;
  }
}
.show-phablet-block {
  display: none;
  
  @include media-query('phablet') {
    display: block;
  }
}

.show-lap {
  display: none;
  
  @include media-query('lap') {
    display: inline-block;
  }
}
.show-lap-block {
  display: none;
  
  @include media-query('lap') {
    display: block;
  }
}

.show-desk {
  display: none;
  
  @include media-query('desk') {
    display: inline-block;
  }
}
.show-desk-block {
  display: none;
  
  @include media-query('desk') {
    display: block;
  }
}

.ghost {
  width: 0;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.overflowable {
  overflow: hidden;
  position: relative;
  
  .more, .less {
    color: $bright-blue;
    padding: $su-tiny 0 0 $su-small;
    background-color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
  }
  
  .less {
    display: none;
  }
  
  &.open {
    height: auto !important;
    
    .more {
      display: none;
    }
    
    .less {
      display: inline-block;
    }
  }
}


/* Colours */

.bg-bright-blue {
  background-color: $bright-blue;
}

.bg-dark-blue {
  background-color: $dark-blue;
}

.bg-deep-blue {
  background-color: $deep-blue;
}

.bg-font-blue {
  background-color: $font-blue;
}

.bg-light-blue {
  background-color: $light-blue;
}

.bg-dusky-blue {
  background-color: $dusky-blue;
}

.bg-mid-blue {
  background-color: $mid-blue;
}

.bg-pale-blue {
  background-color: $pale-blue;
}

.bg-gray-blue {
  background-color: $gray-blue;
}

.bg-hover-blue {
  background-color: $hover-blue;
}

.bg-ocean-blue {
  background-color: $ocean-blue;
}

.bg-promotion-blue {
  background-color: $promotion-blue;
}

.bg-light-tab-blue {
  background-color: $light-tab-blue;
}


/* Tabs */

ul.info-tabs {
  list-style-type: none;
  margin: 0;
  
  li {
    cursor: pointer;
  }
}

.privacy--locked {
  padding-top: 70px;
  text-align: center;
  background: url('../../img/privacy-locked.png') no-repeat center top;
  
  p {
    @include font-size(14px);
    margin: 0;
  }
}