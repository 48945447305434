//@font-face {
//    font-family: 'icomoon';
//    src:    url('../fonts/icomoon.eot?u4019n');
//    src:    url('../fonts/icomoon.eot?u4019n#iefix') format('embedded-opentype'),
//        url('../fonts/icomoon.ttf?u4019n') format('truetype'),
//        url('../fonts/icomoon.woff?u4019n') format('woff'),
//        url('../fonts/icomoon.svg?u4019n#icomoon') format('svg');
//    font-weight: normal;
//    font-style: normal;
//}
//
//[class^="icon-"], [class*=" icon-"] {
//    /* use !important to prevent issues with browser extensions that change fonts */
//    font-family: 'icomoon' !important;
//    speak: none;
//    font-style: normal;
//    font-weight: normal;
//    font-variant: normal;
//    text-transform: none;
//    line-height: 1;
//
//    /* Better Font Rendering =========== */
//    -webkit-font-smoothing: antialiased;
//    -moz-osx-font-smoothing: grayscale;
//}

.content--toggle {
  color: $bright-blue;
}