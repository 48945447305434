$viewer-container-width: 1000px;

html.viewer {
  background-image: none;
  background-color: white;
  padding-top: $su * 2;
}

section.viewer-section {
  position: relative;
}

.viewer-blank {
  background-color: $white;
  position: relative;
  z-index: 2;
  
  @include media-query('flag') {
    padding-top: $su * 2;
  }
  
  @include media-query('lap') {
    padding-top: $su * 3;
  }
}

section.viewer-container {
  max-width: $viewer-container-width;
  background-color: $white;
  padding: 0 0 $su-large 0;
  margin: 0 auto;  
  z-index: 5;
  position: relative;
  
  > section {
    margin: 0;
    padding: 0;
  }  
}

.viewer-container-width {
  max-width: $viewer-container-width;
  margin: 0 auto;  
}

.viewer-section-title {
  background-color: $pale-blue;
  border-bottom: dotted 1px $dusky-blue;
  padding: $su-small $su;
  position: relative;
  
  &[data-toggling="true"] {
    cursor: pointer;
  }
    
  h3 {    
    @include font-size(20px);
    font-family: 'Source Sans Pro', Arial, sans-serif;
    font-weight: 700;
    color: $dusky-blue;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    
    @include media-query('phablet') {
      @include font-size(24px);
    }
  }
  
  .count {
    @include font-size(20px);
    font-family: 'Source Sans Pro', Arial, sans-serif;
    font-weight: 300;
    color: $dusky-blue;
    border-radius: 50%;
    margin-left: $su-small;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    
    @include media-query('phablet') {
      @include font-size(26px);
    }
    
    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
  }
  
  .open & {  
    .toggle-show {
      display: none;
    }

    .toggle-hide {
      display: inline-block;
    }
  }
  
  .toggle {
    width: 30px;
    height: 30px;
    background-color: $white;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: $su;
    transform: translateY(-50%);
  }
  
  .toggle-show {
    display: inline-block;
  }
  
  .toggle-hide {
    display: none;
    
    img {
//      transform: rotate(90deg);
    }
  }
}

.viewer-section-title--icon {
  width: 26px;
  display: inline-block;
  margin-right: $su-small;
  vertical-align: middle;
    
  @include media-query('phablet') {
    width: 30px;
    margin-right: $su;
  }
  
  img {
    width: 100%;
  }
}

.viewer-section-content {
  max-height: 0;
  opacity: 0;  
  overflow: hidden;
//  border-top: solid 1px $silver;
  
  .open & {
    padding: $su;
    height: auto;
    max-height: 999999px;
    opacity: 1;
  }
  
  p {
    @include font-size(14px);
  }
}

.viewer-section--warning {
  border: solid 7px $modal-light-gray;
  border-radius: 5px;
  margin-bottom: $su;
  padding: $su;
  text-align: center;
  
  img {
    margin-bottom: $su-small;
  }
  
  p {
    margin-bottom: 0;
  }
}

.viewer--back-to-top {
  width: 54px;
  height: 54px;
  position: fixed;
  bottom: $su;
  left: $su;
  z-index: 99;
  display: none;
  cursor: pointer;
  
  @include media-query('phablet') {
    body.scrolled-page & {
      display: block;
    }
  }
}



/*
** Content lists
*/

ul.viewer-list {
  list-style-type: none;
  margin: 0;
  padding: $su-small 0;
  
  p {
    margin: 0;
    padding: 0;
  }
  
  .label {
    display: block;
    color: $dusky-blue;
    vertical-align: top;
    
    @include media-query('phablet') {
      width: 30%;
      display: inline-block;
    }
  }
  
  .value {
    display: block;
    vertical-align: top;
    margin-bottom: $su / 2;
    color: $font-black;
    
    @include media-query('phablet') {
      width: 70%;
      display: inline-block;
      margin-bottom: $su / 3;
    }
  }
}

.viewer-list + .viewer-list {
  border-top: solid 1px $modal-light-gray;
}

ul.viewer-gallery {
  list-style-type: none;
  margin: 0;
  padding: 0;
  
  li {
    width: 50%;
    display: inline-block;
    margin: 0;
    
    @include media-query('phablet') {
      width: 50%;
    }
    
    @include media-query('lap') {
      width: 25%;
    }
  }
  
  .viewer-gallery--container {
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
  
    figure {
      width: 100%;
      height: 100%;
      margin: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      display: block;
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
    }
  }
}

ul.viewer-experience--log {
  list-style-type: none;
  margin: 0;
  padding: 0;
  
  li {
    padding-bottom: $su;
  }
  
  p {
    margin: 0;
  }
  
  .experience--header {
    p {
      color: $black;
    
      span {
        color: $gray;
      }
    }
  }
  
  .experience--text {
    font-style: italic;
  }
  
  .viewer-experience--date {
    color: $black;
  }
  
  .viewer-experience--subject {
    color: $black;
  }
}

ul.viewer-actions {
  list-style-type: none;
  margin: 0 0 $su 0;
  padding: 0;
  
  li {
    padding: $su-tiny 0;
    margin-right: $su-small;
    text-align: center;
    display: inline-block;
    
    &.u-hidden {
      display: none;
    }
  }
  
  a {
    cursor: pointer;
  }
  
  .circle {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
  }
  
  .label {
    @include font-size(12px);
    color: $bright-blue;
//    text-transform: uppercase;
    margin-top: $su-small;
    display: block;
  }  
  
  .viewer-actions--cv {
    .circle {
      background-color: #c4e2ec;      
      padding-top: 9px;
      
      &:hover {
        background-color: #879cb0;
      }
    }
  }
  
  .viewer-actions--cv-locked {
    .circle {
      background-color: #a3bbd4;
      padding-top: 9px;
    }
    
    a {
      cursor: default;
    }
  }
  
  .viewer-actions--copy {
    .circle {
      background-color: #d6e6c4;
      background-color: #c4e2ec;
      padding-top: 11px;
      
      &:hover {
        background-color: #a6b298;
        background-color: #a3bbd4;
      }
    }
  }
  
  .viewer-actions--print {
    .circle {
      background-color: #c4e2ec;
      padding-top: 10px;
      
      &:hover {
        background-color: #a5bec6;
        background-color: #a3bbd4;
      }
    }
  }
  
  .viewer-actions--share {
    .circle {
      background-color: #c4cae6;
      background-color: #c4e2ec;
      padding-top: 8px;
      
      &:hover {
        background-color: #a2a7be;
        background-color: #a3bbd4;
      }
    }
  }
  
  .viewer-actions--save {
    .circle {
      background-color: #e4c4e6;
      background-color: #c4e2ec;
      padding-top: 11px;
      
      &:hover {
        background-color: #b79eb9;
        background-color: #a3bbd4;
      }
    }
  }
  
  .viewer-actions--unsave {
    .circle {
      background-color: #e4c4e6;
      background-color: #c4e2ec;
      padding-top: 11px;
      
      &:hover {
        background-color: #b79eb9;
        background-color: #a3bbd4;
      }
    }
  }
    
  .viewer-actions--phone {
    .circle {
      background-color: #c4e2ec;
      padding-top: 12px;
      
      &:hover {
        background-color: #a3bbd4;
      }
    }
  }
  
  .viewer-actions--phone-locked {
    .circle {
      background-color: #a3bbd4;
      padding-top: 9px;
    }
    
    .icon {
      position: relative;
      left: -1px;
    }
    
    a {
      cursor: default;
    }
  }
  
  .viewer-actions--skype {
    .circle {
      background-color: #c4e2ec;
      padding-top: 10px;
            
      &:hover {
        background-color: #a3bbd4;
      }
    }
  }
  
  .viewer-actions--skype-locked {
    .circle {
      background-color: #a3bbd4;
      padding-top: 10px;
    }
    
    .icon {
      position: relative;
      left: 1px;
    }
    
    a {
      cursor: default;
    }
  }
  
  .viewer-actions--url {
    .circle {
      background-color: #c4e2ec;
      padding-top: 9px;
      
      &:hover {
        background-color: #a3bbd4;
      }
    }
  }
  
  .viewer-actions--url-locked {
    .circle {
      background-color: #a3bbd4;
      padding-top: 9px;
    }
    
    a {
      cursor: default;
    }
  }
}

ul.viewer-tags {
  list-style-type: none;
  margin: 0;
  padding: 0;
  
  li {
    padding: $su-tiny $su-small;
    margin-bottom: $su-small;
    display: inline-block;
    
    &:first-child {
      padding-left: 0;
    }
  }
  
  li + li {
    margin-left: $su-small;
  }
  
  .viewer-tags--tag {
    background-color: $modal-light-gray;
  }
  
  a {
    color: inherit;
  }
}

ul.viewer-team {
  list-style-type: none;
  margin: 0 0 $su 0;
  padding: 0;
  
  li {
    margin-bottom: $su-small;
    display: inline-block;
    margin-right: $su-small;
    
    &:first-child {
      padding-left: 0;
    }
  }
  
  li + li {
  }
  
  a {
    color: inherit;
  }

  .viewer-team--name {
    @include inuit-font-size(16px);
  }

  .viewer-team--details {
    @include inuit-font-size(12px);
  }
}

.viewer-team--member {
  position: relative;
}

.viewer-team--image {
  width: 60px;
  height: 60px;
  display: inline-block;
  vertical-align: middle;
}

.viewer-team--info {
  height: 60px;
  background-color: $modal-light-gray;
  padding: $su-small $su-small 0 $su-small;
  display: inline-block;
  vertical-align: middle;
  
  p {
    margin: 0;
  }
}
  


/*
** Privacy
*/

.viewer-privacy {
  text-align: center;
  padding-top: $su;
  padding-bottom: $su;
  position: relative;
  
  p {
    max-width: 500px;
    margin: 0 auto;
    z-index: 1;
    position: relative;
  }

  .viewer-privacy--description {
    margin-bottom: $su;
  }
}

.viewer-privacy--icon {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  z-index: 0;
  opacity: 0.2;
  display: none;
  
  img {
    max-width: 100px;
  }
}

.viewer-section-content {
  .viewer-privacy--title {
    padding-left: 52px;
    @include font-size(16px);
    line-height: 32px;
    text-align: left;
    background-image: url('../../img/icons/privacy-padlock@2x.png');
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position: left center;
  }
  
  .viewer-privacy--premium {
    background-image: url('../../img/icons/privacy-padlock-premium@2x.png');
  }
}
  


/*
** Modals
*/

html.viewer {
  #ModalContent {
    background-color: transparent;
  }
}

.viewer-modal {
  max-width: 400px;
  margin: 0 auto;
  padding: $su-large;
  background-color: $white;
  
  @include media-query('phablet') {
    margin-top: 15%;
  }
  
  .close {
    float: right;
    cursor: pointer;
  }
    
}

.viewer-modal--sharing {
  list-style: none;
  margin: $su-large 0 0 0;
  padding: 0;
  
  li {
    position: relative;
  }
  
  li + li {
    margin-top: $su;
  }
  
  img {
    margin-right: $su-small;
    vertical-align: middle;
  }
  
  span {
    @include font-size(14px);
    font-weight: 700;
    vertical-align: middle;
  }
}

.viewer-modal--content {
  max-width: 800px;
  margin: 0 auto;
  color: $white;  
  background-color: $purple;
  padding: $su-large;
  
  @include media-query('phablet') {
    margin-top: 15%;
  }
  
  h3 {
    color: $white;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: $su;
    @include font-size(31px);
  }
  
  .close {
    float: right;
    cursor: pointer;
  }
}

.viewer-modal--menu {    
  max-width: 360px;
  background-color: $bright-blue;
  padding: $su 0;
  position: relative;
  
  .viewer-modal--menu-title {
    color: $white;
    text-transform: uppercase;
    margin: $su $su $su-small $su;
    @include font-size(15px);
  }  
  
  .close-icon {
    width: 18px;
    height: 18px;
    float: none;
    position: absolute;
    top: $su + 10px;
    right: $su + 10px;
    display: inline-block;
    
    path {
      fill: $white !important;
    }
  }
  
  h3 {
    font-weight: 500;
  }
  
  .close-button {
    max-width: 60%;
    margin: $su-large auto 0 auto;
    padding: $su-small 0;
    float: none;
    text-align: center;
    background-color: $white;
    color: $font-color;
    text-transform: uppercase;
    
    img {
      margin-right: $su-small;
      position: relative;
      top: -2px;
    }
  }
}
  
.viewer-modal--menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  
  li {
    @include font-size(20px);
    font-weight: 300;
    text-align: center;
  }
  
  a {
    font-family: 'Source Sans Pro';
    color: $white;
    padding: $su-tiny 0;
    cursor: pointer;
    display: block;
    
    &:hover {
      background-color: $indigo;
    }
  }
}

#viewer-modal--menu-crewbay {
  margin-top: $su;
}

.viewer-modal--login {
  max-width: 400px;
  
  input[type="email"], 
  input[type="password"] {
    width: 100%;
    color: $font-color;
  }
  
  input[type="submit"] {
    width: 100%;
  }
  
  form {
    margin-bottom: $su;
  }
  
  .form-entry {
    margin-bottom: $su-small;
    margin-top: $su-small;
  }
  
  .form-messages {    
    p {
      margin-bottom: 0;
      color: $red;
    }
    
    p:last-of-type {
      margin-bottom: $su;
    }
  }    
    
  .login-note--register {
    @include font-size(16px);
    margin-bottom: 0;
    
    a {
      margin-left: $su;
    }
  }
}

.viewer-modal--copy {
  max-width: 600px;
  
  input[type="text"] {
    margin-bottom: $su;
    padding-left: $su-tiny;
    width: 100%;
    
    @include media-query('phablet') {      
      @include font-size(20px);
    }
  }
}

.viewer-modal--phone {
  max-width: 600px;
  
  input[type="text"] {
    margin-bottom: $su;
    padding-left: $su-tiny;
    width: 100%;
    
    @include media-query('phablet') {      
      @include font-size(20px);
    }
  }
}
  



/*
** Nav
*/

section.viewer-nav {
  text-align: center;
  border-bottom: solid 1px #888;
  background-color: $white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  padding-bottom: 0;
  
  @include media-query('phablet') {
    box-shadow: 0 2px 10px #888;
  }
  
  p {
    margin: 0; 
    padding: 0;
    line-height: $su * 2;
  }
}

.viewer-nav--menu {
//  max-width: $viewer-container-width;
  height: $su-large;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  
  li {
    height: 100%;
    vertical-align: middle;
    display: inline-block;
    box-sizing: border-box;
  }
}

.viewer-nav--menu-left {
  width: 35%;
  text-align: left;
  padding-left: $su;
}

.viewer-nav--menu-right {
  width: 35%;
  text-align: right;
  padding-right: $su;
}
.viewer-nav--menu-logo {
  width: 30%;
  text-align: center;
  padding: $su-small 0;
}

.viewer-nav--contact {
  height: 100%;
  background-color: $bright-blue;
  color: $white;
  padding: 0 $su;
  cursor: pointer;
  display: inline-block;
  
  span {
    vertical-align: middle;
  }
    
  &:hover {    
    background-color: $button-hover-color;
  }
  
  .icon {
    display: inline-block;
    position: relative;
    top: 8px;
    
    @include media-query('phablet') {
      margin-right: $su-tiny;
      top: -1px;
    }
  }
  
  .label {
    font-weight: 700;
    line-height: $su-large;
    text-transform: uppercase;
    display: none;
    
    @include media-query('phablet') {
      display: inline-block;
    }
  }
}

.viewer-nav--hamburger {
  height: 100%;
  padding: 0 $su;
  cursor: pointer;
  display: inline-block;
  
  span {
    vertical-align: middle;
  }
    
  &:hover {    
    color: $bright-blue;
  }
  
  .icon {
    display: inline-block;
    position: relative;
    top: 8px;
    
    @include media-query('phablet') {
      margin-right: $su-tiny;
      top: -2px;
    }
  }
  
  .label {
    font-weight: 700;
    line-height: $su-large;
    text-transform: uppercase;
    display: none;
    
    @include media-query('phablet') {
      display: inline-block;
    }
  }
}
  



/*
** Background
*/

section.viewer-background {  
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;  
  display: none;
  
  @include media-query('flag') {
    height: 360px;
    display: block;
  }
  
  @include media-query('lap') {
    height: 500px;
  }
}
  



/*
** Intro
*/

section.viewer-intro {
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  
  @include media-query('flag') {
//    margin-bottom: $su * 3;
  }
  
  @include media-query('lap') {
    padding-top: 120px;
  }
  
  .viewer-container {
    max-width: $viewer-container-width;
    margin: 0 auto;
    z-index: 5;
    position: relative;
  }
  
  h1 {
    color: $white;
    @include font-size(31px);
    
    @include media-query('lap') {
      @include font-size(51px);
    }
    
    &.long {
      @include font-size(25px);
      
      @include media-query('lap') {
        @include font-size(41px);
      }
    }
  }
  
  p {
    margin: 0; 
    padding: 0;
    color: $white;
  }
  
  li {
    color: $white;
  }
}

.viewer-intro--actions {
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 6;
  position: relative;
  text-align: right;
  display: none;
  
  @include media-query('flag') {
  }
  
  @include media-query('phablet') {
    margin: 0 $su 0 0;
    position: absolute;
    right: $su;
    top: $su-large;
    display: inline-block;
  }
  
  @include media-query('desk') {
    margin-right: 0;
  }
  
  li {
    width: 50%;
    display: inline-block;
    cursor: pointer;
    
    @include media-query('phablet') {
      width: auto;
    }
    
    &.u-hidden {
      display: none;
    }
    
    img.on {
      display: none;
    }
    
    &:hover {
      img.on {
        display: inline-block;
      }
      
      img.off {
        display: none;
      }
    }
    
    .icon {
      height: 14px;
      margin-right: $su-tiny;
      vertical-align: middle;
      display: inline-block;
    }
    
    .label {
      font-weight: 700;
      vertical-align: middle;
    }
  }
  
  li + li {
    border-left: solid 1px $silver;
    
    @include media-query('phablet') {
      margin-left: $su-small;
      border-left: none;
    }
  }

  .viewer-intro--share {
    width: 100%;
    height: $su-large;
    line-height: $su-large;
    background-color: $white;
    color: $text-color;
    padding: 0 $su-small;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    
    @include media-query('phablet') {
      width: auto;
      height: auto;
      line-height: 1;
//      padding: $su-tiny $su-small;
      padding: $su-small $su;
      border-radius: 5px;
    }

    &:hover {
      color: $bright-blue;
    }
    
    .icon {
      position: relative;
      top: -2px;
    }
  }
  
  .viewer-intro--favourite, 
  .viewer-intro--unfavourite {
    width: 100%;
    height: $su-large;
    line-height: $su-large;
    background-color: $white;
    color: $text-color;
    padding: 0 $su-small;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    
    @include media-query('phablet') {
      width: auto;
      height: auto;
      line-height: 1;
//      padding: $su-tiny $su-small;
      padding: $su-small $su;
      border-radius: 5px;
    }
    
    &:hover {
      color: $bright-blue;
    }
  }
  
  .viewer-intro--unfavourite {
    .icon {
      position: relative;
      top: -1px;
    }
  }
}

.viewer-intro--image-square {
  position: relative;
}

.viewer-intro--image-square:after {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.viewer-intro--image {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
  background-color: $white;
  
  @include media-query('flag') {
    bottom: $su * -2;
    border: solid 1px $black;
    left: $su;
  }
  
  @include media-query('lap') {
    border: solid $su rgba(200, 200, 200, 0.5);
    left: 0;
    bottom: $su * -3;
  }
  
  figure {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: block;
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
  }
  
  .banner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    
    &.banner-urgent {
      top: auto;
      left: auto;
      bottom: 0;
      right: 0;
    }
  }
  
  .viewer-intro--image-more {
    position: absolute;
    z-index: 3;
    top: 48%;
    left: 5%;
  }
  
  .viewer-intro--image-prev {
    transform: rotate(90deg);
  }
  
  .viewer-intro--image-next {
    left: auto;
    right: 5%;
    transform: rotate(270deg);
  }
}

.viewer-intro--info {
  padding: $su;
  
  @include media-query('flag') {
    margin-left: $su-small;
  }
  
  @include media-query('lap') {
    margin-left: 0;
  }
}

ul.viewer-intro--details {
  margin: 0;
  padding: 0;
  list-style-type: none;
  
  li {
    padding-left: $su;
    background-repeat: no-repeat;
    background-position: left center;
  }
}

.viewer-intro--fade {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: 0;
  z-index: 1;
  
  @include media-query('flag') {
    height: $su * 7;
  }
  
  @include media-query('lap') {
    height: $su * 10;
  }
}

.viewer-intro--personal {
  background-image: url('../../img/icons/icon-viewer-intro-personal@2x.png');
  background-size: 12px 12px;
}

.viewer-intro--location {
  background-image: url('../../img/icons/icon-viewer-intro-location@2x.png');
  background-size: 12px 12px;
}

.viewer-intro--checkin {
  background-image: url('../../img/icons/icon-viewer-intro-checkin@2x.png');
  background-size: 12px 12px;
}

.viewer-intro--vessel {
  background-image: url('../../img/icons/icon-viewer-intro-vessel@2x.png');
  background-size: 13px 13px;
}



/*
** Advert
*/

section.viewer-advert {    
  h3, .gamma {
    @include font-size(20px);
    font-weight: 400;
    line-height: 1.3em;
    background-color: transparent;
    border: none;
    padding: 0;
    margin-bottom: $su;
  }
  
  .viewer-section-content {
    border-top: none;
  }
}



/*
** Photos
*/

section.viewer-photos {
  .viewer-gallery--count {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.3);
    line-height: 100%;
    text-align: center;
    position: relative;
    
    span {
      color: white;
      @include inuit-font-size(40px);
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      display: block;
    }
  }
}



/*
** Qualifications
*/

section.viewer-qualifications {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  li {    
    padding: $su 0;
    
    & + li {
      border-top: solid 1px $modal-light-gray;
    }
    
    p {
      margin: 0;
      padding: 0;
    }
    
    span {
      display: block;
      
      @include media-query('flag') {
        display: inline-block;
      }
    }
  }
  
  .viewer-qualifications--date {
    @include media-query('flag') {
      width: 100%;
    }    
    
    @include media-query('phablet') {
      width: 25%;
    }    
  }
  
  .viewer-qualifications--title {
    @include media-query('flag') {
      width: 50%;
    }     
  }
  
  .viewer-qualifications--progress {
    @include media-query('flag') {
      width: 50%;
      text-align: right;
    }    
    
    @include media-query('phablet') {
      width: 25%;
    }    
  }
  
  .qualification--status-complete {
    color: $green;
  }
  
  .qualification--status-incomplete {
    color: $orange;
  }
}



/*
** Skills
*/

section.viewer-skills {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  
  li {
    display: inline-block;
    margin: 0 $su-small $su-small 0;
    padding: $su-small $su;
    background-color: $dusky-blue;
    color: $white;
  }
}



/*
** Q & A
*/

section.viewer-qa {
  .viewer-qa--entry {
    margin-bottom: $su-large;
  }
  
  .viewer-qa--question {
    color: $black;
    display: block;
  }
}



/*
** Experience
*/

section.viewer-experience {
}



/*
** Reviews
*/

section.viewer-reviews {
  .review--form {
    padding-top: $su;
    padding-bottom: $su;
    margin-bottom: $su;
    border-bottom: solid 1px $modal-light-gray;
    
    h3 {
      @include font-size(18px);
    }
  }
  
  .review--form-subintro {
    margin: 0;
  }
  
  .review--form-trigger {
    cursor: pointer;
  }
  
  input[type="text"], 
  textarea, 
  select {
    width: 100%;
  }
  
  .review {
    margin-bottom: $su;
    
    .review--title {
      font-style: italic;
      margin-bottom: 0;   
      color: $bright-blue;
    }
    
    .review--review {
      margin-bottom: 0;      
    }
    
    .review--date {
      border-top: solid 1px $modal-light-gray;
      margin-top: halve($su);
      padding-top: halve($su);
      color: $gray;    
    }
    
    .review--reviewer {
      text-align: center;
    }
  }
    
  .review--image {
    width: 80%;
    height: 80%;
    margin: 0 auto halve($su) auto;
    overflow: hidden;
    border-radius: 15px;
  }
  
  .notification {
    margin-bottom: $su;
  }
  
  .viewer-privacy + .review {
    margin-top: $su;
  }
}

.review--rating-selection {
  width: 320px;
  height: 50px;
  margin-top: $su;
//  background: url('../../img/stars-0.png') no-repeat 0 0;
  /*
  &.lock-stars-1 {
    background-image: url('../../img/stars-2.png');
  }
  &.lock-stars-2 {
    background-image: url('../../img/stars-4.png');
  }
  &.lock-stars-3 {
    background-image: url('../../img/stars-6.png');
  }
  &.lock-stars-4 {
    background-image: url('../../img/stars-8.png');
  }
  &.lock-stars-5 {
    background-image: url('../../img/stars-10.png');
  }
  
  &.stars-1 {
    background-image: url('../../img/stars-2.png') !important;
  }
  &.stars-2 {
    background-image: url('../../img/stars-4.png') !important;
  }
  &.stars-3 {
    background-image: url('../../img/stars-6.png') !important;
  }
  &.stars-4 {
    background-image: url('../../img/stars-8.png') !important;
  }
  &.stars-5 {
    background-image: url('../../img/stars-10.png') !important;
  }
  */
  .review--star {
    width: 50px;
    height: 50px;
    background: url('../../img/icons/review-star-off@2x.png') no-repeat 0 0;
    background-size: 50px 50px;
    display: inline-block;
    cursor: pointer;
  }
  
  &.stars-1, &.lock-stars-1 {
    [data-star="1"] {
      background-image: url('../../img/icons/review-star-on@2x.png');
    }
  }
  
  &.stars-2, &.lock-stars-2 {
    [data-star="1"], [data-star="2"] {
      background-image: url('../../img/icons/review-star-on@2x.png');
    }
  }
  
  &.stars-3, &.lock-stars-3 {
    [data-star="1"], [data-star="2"], [data-star="3"] {
      background-image: url('../../img/icons/review-star-on@2x.png');
    }
  }
  
  &.stars-4, &.lock-stars-4 {
    [data-star="1"], [data-star="2"], [data-star="3"], [data-star="4"] {
      background-image: url('../../img/icons/review-star-on@2x.png');
    }
  }
  
  &.stars-5, &.lock-stars-5 {
    [data-star="1"], [data-star="2"], [data-star="3"], [data-star="4"], [data-star="5"] {
      background-image: url('../../img/icons/review-star-on@2x.png');
    }
  }
  
  .review--star + .review--star {
    margin-left: 5px;
  }
}

.review--rating-description {
  padding: $su-tiny 0 $su 0;
  margin-bottom: 0;
  
  span {
//    padding: $su-tiny $su;
//    background-color: $dark-blue;
//    color: $white;
    @include font-size(32px);
    font-weight: 700;
    color: $dusky-blue;
    box-sizing: border-box;
    display: none;
    
    &.lock {
      display: inline-block;
    }
  }
}

.review--rating-form {
  display: none;
  
  .entry + .entry {
    margin-top: $su-small;
  }
}



/*
** References
*/

section.viewer-references {
  .reference--form {
    padding: $su;
    background-color: $pale-blue;
    display: none;
        
    label {
      @include inuit-font-size(14px);
      margin-bottom: $su-tiny;
    }
    
    input[type="text"] {
      padding-top: $su-small;
      padding-bottom: $su-small;
      margin-bottom: $su;
    }
  }
  
  .reference--form-intro {
    padding-top: $su;
    text-align: center;
  }
  
  .reference--form-trigger {
    margin-top: $su;
    cursor: pointer;
    display: block;
    
    img {
      margin-right: $su;
    }
  }
    
  input[type="text"], 
  textarea, 
  select {
    width: 100%;
  }
  
  .buttons {
    text-align: right;
  }

  .reference {
    margin-bottom: $su;
    border-bottom: solid 1px $light-gray;
    border-top: none;
    padding-top: 0;
    background-color: transparent;

    .reference--header {
      background-color: transparent;
      padding: 0 halve($su) quarter($su) 0;

      p {
        color: $black;
        margin: 0;

        span {
          color: $gray;
        }
      }
      
      p.status-indicator {
        margin-bottom: $su-small;
      }
      
      .status--confirmed {
        color: $white;
      }
    }

    > p {
      padding: quarter($su) halve($su) quarter($su) 0;
      font-style: italic;
    }
  }
  
  .reference--text {
    color: $font-black;
    font-style: italic;
  }
  
}



/*
** Skipper
*/

section.viewer-skipper {
  .viewer-skipper--image {
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
  
    figure {
      width: 100%;
      height: 100%;
      margin: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      display: block;
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
    }
  }
}



/*
** Contact
*/

section.viewer-contact, section.viewer-conversation {
  
  form {
    margin-bottom: $su;
  }
  
  .form-entry {
    margin-bottom: $su;
  }
  
  input[type="text"], textarea {
    width: 100%;
  }
    
  .form-buttons {
    text-align: right;
  }
  
  .contact-point {
    border: solid 1px $dusky-blue;
    padding: 0;
    line-height: 1;
    text-align: center;
    
    @include media-query('flag') {
      text-align: left;
    }
    
    span {
      @include font-size(18px);
      margin: 0;
      padding: $su-small;
      vertical-align: middle;
      display: block;
      
      @include media-query('flag') {
        display: inline-block;
      }
    }
    
    .label {
      background-color: $dusky-blue;
      background-repeat: no-repeat;
      background-position: 10px center;      
      color: $white;
      
      @include media-query('flag') {
        width: 180px;
      }
      
      @include media-query('phablet') {
        width: 240px;
        
        padding-left: $su-large;
      }
    }
    
    .value {
      color: $font-black;
    }
  }
    
  .contact-point--skype {
    .label {      
      @include media-query('phablet') {
        background-image: url('../../img/icons/icon-contact-skype@2x.png');
        background-size: 22px 22px;
      }
    }
  }
    
  .contact-point--phone {
    .label {      
      @include media-query('phablet') {
        background-image: url('../../img/icons/icon-contact-phone@2x.png');
        background-size: 22px 22px;
      }
    }
  }
    
  .contact-point--url {
    .label {      
      @include media-query('phablet') {
        background-image: url('../../img/icons/icon-contact-url@2x.png');
        background-size: 24px 24px;
        background-position: 9px center;
      }
    }
  }
  
  .countdown-crew {
    margin-top: $su;
    padding-right: 0;
  }
  
  .contact-option {
    padding: $su 0 $su-small 0;
    
    p {
      margin: 0;
    }
  }
  
  .contact-option + .contact-option {
    border-top: solid 1px $silver;
  }
  
  .contact--icon {
    text-align: center;
  }
  
  .contact--icon-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: $su;
    background-color: $dusky-blue;
    color: $white;
    text-align: center;
    display: inline-block;
    overflow: hidden;

    img {
      width: 50%;
      margin-top: 25%;
    }
  }
      
  .contact--title {
    font-size: 1.3rem;
  }
      
  .contact--subtitle {
    font-size: 1.1rem;
  }
      
  .contact--number {
    font-size: 1.2rem;
  }
  
  .form-info + .form-entry {
    margin-top: $su;
  }
  
  .form-entry {
    label {
      margin-bottom: $su / 2;
    }
    
    input[type="text"], textarea {
      border-radius: 0;
      border-color: $mid-gray;
      padding: $su-small $su;
      
      &:focus, &:hover {
        border-color: $mid-gray;
      }
    
      &.error {
        border: solid 1px $red;
      }
    }
    
    input[type="text"] {
      font-size: 1.3rem;
      /*background-color: $light-gray;
      border-bottom: none;*/
      margin-bottom: $su;
    }
    
    textarea {
      font-size: 1.1rem;
    }
  }
  
  p.error {
    margin: $su-tiny 0 $su 0;
  }
  
  p.contact--skype {
    margin-top: $su-tiny;
    
    img {
      margin-right: $su-tiny;
    }
  }
  
  p.contact--whatsapp {
    margin-top: $su-small;
    
    img {
      margin-right: $su-tiny;
    }
  }
}



/*
** Conversation
*/

section.viewer-conversation {
  
  .contact-point {
    border: solid 1px $dusky-blue;
    padding: 0;
    line-height: 1;
    
    span {
      @include font-size(18px);
      margin: 0;
      padding: $su-small;
      vertical-align: middle;
      display: block;
      
      @include media-query('flag') {
        display: inline-block;
      }
    }
    
    .label {
      background-color: $dusky-blue;
      background-repeat: no-repeat;
      background-position: 10px center;      
      color: $white;
      
      @include media-query('flag') {
        width: 180px;
      }
      
      @include media-query('phablet') {
        width: 240px;
        
        padding-left: $su-large;
      }
    }
    
    .value {
      color: $font-black;
    }
  }
    
  .contact-point--skype {
    .label {      
      @include media-query('phablet') {
        background-image: url('../../img/icons/icon-contact-skype@2x.png');
        background-size: 22px 22px;
      }
    }
  }
    
  .contact-point--phone {
    .label {      
      @include media-query('phablet') {
        background-image: url('../../img/icons/icon-contact-phone@2x.png');
        background-size: 22px 22px;
      }
    }
  }
    
  .contact-point--url {
    .label {      
      @include media-query('phablet') {
        background-image: url('../../img/icons/icon-contact-url@2x.png');
        background-size: 24px 24px;
        background-position: 9px center;
      }
    }
  }
}

.viewer-conversation--history {
  .message--byline {
    margin: 0 0 $su / 2 0;
  }
  
  .message--description {
    width: 90%;
    padding: halve($su) $su;
    box-sizing: border-box;
    border-radius: $su;
    
    @include media-query('flag') {
      width: 80%;
    }
    
    @include media-query('lap') {
      width: 70%;
    }
  }
  
  .message--image {
    display: none;
    
    @include media-query('flag') {
      display: inline-block;
    }
  }
  
  .message--you {
    img {
      float: none;
    }
    
    .message--byline {
      text-align: right;
    }
    
    .message--description {
      margin-left: auto;
      background-color: $bright-blue;
      color: $white;
    }
  }
  
  .message--you + .message--you .profile-image,
  .message--them + .message--them .profile-image {
    display: none;
  }
  
  .message--them {
    img {
      float: none;
    }
    
    .message--description {
      margin-right: auto;
      background-color: $modal-light-gray;
    }
  }
}

.viewer-conversation--reply {
  padding: $su;
  margin-bottom: $su;
  background-color: $pale-blue;
  
  textarea {
    width: 100%;
  }
  
  .form-buttons {
    text-align: right;
    padding-top: $su;
  }
  
  .countdown {
    padding-right: 0;
  }
}

.viewer-conversation--older {
  text-align: center;
  
  @include media-query('flag') {
    margin-bottom: $su;
  }
  
  @include media-query('lap') {
    margin-bottom: 0;
  }
  
  .btn {
  }
}



/*
** Application form
*/

section.viewer-apply {
  .entry {
    margin-bottom: $su-small;
    min-height: 20px;
    
    &.team {
      select {
        width: auto;
      }
    }
    
    &.notes {      
      label {
        @include inuit-font-size(18px);
      }
    }
  }
    
  .apply--required-list + .entry.notes,
  .apply--preferred-list + .entry.notes {
    margin-top: $su-large;
  }
      
  .cv-share, .name-share {
    background-color: $blue;
    color: $white;
    padding: $su-small $su;

    label {
      margin: 0;
      cursor: pointer;
    }

    input[type="checkbox"] {
      margin-right: $su-small;
    }
  }
  
  textarea {
    width: 100%;
  }
  
  label, .label {
    @include font-size(14px);
    margin-bottom: $su-small;
  }
  
  .label-req {
    color: $red;
  }
  
  .label-pref {
    color: $orange;
  }
  
  select {
    width: 100%;
    margin-top: $su-small;
    
    @include media-query('flag') {
      width: auto;
    }
    
    @include media-query('phablet') {
      margin-top: 0;
    }
  }
  
  .buttons {
    .btn {
      margin-right: $su;
    } 
  }
  
  .apply--required-list, 
  .apply--preferred-list {
    .entry {
      margin-bottom: $su;
    }
    
    @include media-query('phablet') {
      .entry {
        margin-bottom: $su-small;
      }
    }
    
    p {
      margin: 0;
    }
  }
  
  .apply--requirement {
    padding: 3px 0;
//    border-style: solid;
//    border-width: 1px;
    margin-top: $su-small;
    color: $black;
    font-weight: 700;
    display: inline-block;
    
    @include media-query('phablet') {
      margin-top: 0;
    }
  }
  
//  .apply--requirement-yes {
//    color: $green;
//    border-color: $green;
//  }
//  
//  .apply--requirement-no {
//    color: $red;
//    border-color: $red;
//  }
}

ul.viewer--application-summary {
  margin: 0 0 $su 0;
  list-style: none;
  
  li {    
    @include inuit-font-size(14px);
    line-height: 1.5em;
    
    &::before {
      content: "-";
      margin-right: $su-small;
    }
  }
}

.viewer--application-details {
  padding: $su;
  background-color: $modal-light-gray;
  margin-bottom: $su;
}

.viewer--application-cover {
  color: $black;
  border-bottom: solid 1px $font-black;
}

#AlreadyApplied {
  .buttons {
    .countdown {
      padding: $su-small 0;
      float: left;
    }
  }
}




/*
** Related crew
*/

section.viewer-related {
  background-color: $light-blue;
  margin-bottom: 0;
  padding: $su $su $su-small $su;
  display: none;
  
  @include media-query('phablet') {
    display: block;
  }
  
  @include media-query('lap') {
    padding: $su 0 $su-huge 0;
  }
}

.viewer-related--title {
  @include inuit-font-size(22px);
  font-weight: 300;
  text-align: center;
  color: $deep-blue;
}

.viewer-related--entry {
  background-color: $white;
  position: relative;
  padding-bottom: $su-small;
  margin-bottom: $su-small;
  
  p {
    margin: 0;
    padding: 0 $su-small;
  }  
}

.viewer-related--image {
  padding-bottom: 100%;
  position: relative;
  margin-bottom: $su-small;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  
  img {
    width: 100%;
    position: absolute;
    top: 0; left: 0;
  }
}



/*
** Job Adverts
*/

section.viewer-job-adverts {    
  margin-top: $su-large !important;
}

.viewer-job--advert {
  margin-bottom: $su;
  
  @include media-query('phablet') {
    margin-bottom: 0;
  }
  
  p {
    @include inuit-font-size(14px);
  }
  
  .viewer-job--advert-title {
    color: $black;
    margin: 0;
    
    a {
      color: inherit;
    }
  }
}

.viewer-job--advert-link {
  @include media-query('phablet') {
    text-align: right;
  }
}



/*
** Videos
*/

.viewer--video {
  margin-bottom: $su-small;
  
  @include media-query('phablet') {
    position: relative;
    left: $su * -1;
  }
  
  p {
    margin: 0;
    padding: $su-small $su;
    display: inline-block;
    border: solid 1px transparent;
    cursor: pointer;
    line-height: 25px;
    
    img {
      margin-right: $su-tiny;
      display: inline;
      vertical-align: middle;
    }
    
    span {
      color: $rust;
      font-weight: 700;
    }
    
    &:hover {
      border: solid 1px $mid-blue;
      background-color: $pale-blue;
    }
  }
}

.viewer-modal--video {
  max-width: 800px;
  margin: 10% auto;
  padding: $su;
  background-color: $white;
  
  .video-container {
    padding-bottom: 57%;
    position: relative;
    margin-bottom: $su;
        
    iframe {
      width: 100% !important;
      height: 100% !important;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  
  p {
    font-weight: 700;
    margin: 0;
    
    span.close {
      cursor: pointer;
      float: right;
      
      img {
        margin-right: $su-tiny;
      }
    }
  }
}




/*
** Print styles
*/

@media print {
  
section.viewer-nav,
.viewer-intro--fade,
.viewer-intro--actions, 
.viewer-intro--image-square, 
.viewer-advert .viewer-actions,
.viewer-photos, 
.toggle, 
.viewer-private, 
form, 
.message--image, 
.viewer-contact
.viewer-conversation--older {
  display: none !important;
}

html.viewer {
  padding-top: 0;
}

.viewer-section-title {
  background-color: transparent;
}

.viewer-section-content {
  display: block !important;
}

section.viewer-intro {
  background-image: none !important;
  margin-bottom: 0;
  
  h1, li {
    color: $black;
  }
  
  .layout__item {
    width: 100% !important;
  }
  
  .viewer-intro--info {
    padding-top: 0;
  }
}

section.viewer-advert {
  .viewer-tags--tag {
    background-color: transparent;
  }
}
  
  .viewer-conversation--history {
    .message--you {
      .message--description {
        background-color: transparent;
        color: $black;
        text-align: right;
        padding-right: 0;
      }
    }
    
    .message--them {
      .message--description {
        background-color: transparent;
        color: $black;
        padding-left: 0;
      }
    }
  }

  a[href]:after {
    content: none !important;
    display: none !important;
    visibility: hidden !important;
  }
  
}