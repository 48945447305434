/*------------------------------------*\
    #LIST-INLINE
\*------------------------------------*/

/**
 * The list-inline object simply displays a list of items in one line.
 */

// Predefine the variables below in order to alter and enable specific features.
$inuit-list-inline-namespace:           $inuit-namespace !default;

$inuit-enable-list-inline--delimited:   false !default;
$inuit-list-inline-delimit-character:   ",\00A0" !default;

.#{$inuit-list-inline-namespace}list-inline,
%#{$inuit-list-inline-namespace}list-inline {
    margin:  0;
    padding: 0;
    list-style: none;

    > li {
        display: inline-block;
    }

}





@if ($inuit-enable-list-inline--delimited == true) {

    /**
     * Comma delimited list to semantically mark up lists of tags, etc.
     *
     * N.B. This component requires that you remove the whitespace between LIs.
     *      The simplest (and valid) way to achieve this is by omitting the
     *      closing `</li>` tag.
     */

    .#{$inuit-list-inline-namespace}list-inline--delimited,
    %#{$inuit-list-inline-namespace}list-inline--delimited {

        > li + li {

            &:before {
                content: "#{$inuit-list-inline-delimit-character}";
            }

        }

    }

}
