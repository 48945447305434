/*------------------------------------*\
    #LIST-BLOCK
\*------------------------------------*/

/**
 * The list-block object creates blocky list items out of a `ul` or `ol`.
 */

// Predefine the variables below in order to alter and enable specific features.
$inuit-list-block-namespace:        $inuit-namespace !default;

$inuit-list-block-padding:          $inuit-base-spacing-unit !default;
$inuit-list-block-padding--tiny:      quarter($inuit-list-block-padding) !default;
$inuit-list-block-padding--small:       halve($inuit-list-block-padding) !default;
$inuit-list-block-padding--large:      double($inuit-list-block-padding) !default;
$inuit-list-block-padding--huge:    quadruple($inuit-list-block-padding) !default;

$inuit-enable-list-block--tiny:     false !default;
$inuit-enable-list-block--small:    false !default;
$inuit-enable-list-block--large:    false !default;
$inuit-enable-list-block--huge:     false !default;

.#{$inuit-list-block-namespace}list-block,
%#{$inuit-list-block-namespace}list-block {
    margin:  0;
    padding: 0;
    list-style: none;

    > li {
        @extend %#{$inuit-list-block-namespace}list-block__item;
    }

}

    .#{$inuit-list-block-namespace}list-block__item,
    %#{$inuit-list-block-namespace}list-block__item {
        padding: $inuit-list-block-padding;
    }





@if ($inuit-enable-list-block--tiny == true) {

    /**
     * Tiny list-blocks.
     */

    .#{$inuit-list-block-namespace}list-block--tiny,
    %#{$inuit-list-block-namespace}list-block--tiny {

        > .#{$inuit-list-block-namespace}list-block__item,
        > %#{$inuit-list-block-namespace}list-block__item {
            padding: $inuit-list-block-padding--tiny;
        }

    }

}

@if ($inuit-enable-list-block--small == true) {

    /**
     * Small list-blocks.
     */

    .#{$inuit-list-block-namespace}list-block--small,
    %#{$inuit-list-block-namespace}list-block--small {

        > .#{$inuit-list-block-namespace}list-block__item,
        > %#{$inuit-list-block-namespace}list-block__item {
            padding: $inuit-list-block-padding--small;
        }

    }

}

@if ($inuit-enable-list-block--large == true) {

    /**
     * Large list-blocks.
     */

    .#{$inuit-list-block-namespace}list-block--large,
    %#{$inuit-list-block-namespace}list-block--large {

        > .#{$inuit-list-block-namespace}list-block__item,
        > %#{$inuit-list-block-namespace}list-block__item {
            padding: $inuit-list-block-padding--large;
        }

    }

}

@if ($inuit-enable-list-block--huge == true) {

    /**
     * Huge list-blocks.
     */

    .#{$inuit-list-block-namespace}list-block--huge,
    %#{$inuit-list-block-namespace}list-block--huge {

        > .#{$inuit-list-block-namespace}list-block__item,
        > %#{$inuit-list-block-namespace}list-block__item {
            padding: $inuit-list-block-padding--huge;
        }

    }

}
