
html.auth {
  background-image: url('../../img/bg-auth.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

body.auth {
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
  
  main {
    padding: 0 !important;
    min-height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    
    @include media-query('flag') {
      background-color: transparent;
    }
  }
}

.box--auth {
  width: 100%;
  max-width: 456px;
  margin: 0 auto;
  padding: $su $su * 2 $su * 2 !important;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent !important;
  
  @include media-query('flag') {
    background-color: rgba(255, 255, 255, 0.9) !important;
  }
  
  .logo + .notification {
    margin-top: $su;
  }
  
  .entry {
    margin-bottom: 10px;
  }
  
  label {
    text-align: left;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
    border-radius: 0;
    border-color: #b6b6b6;
    padding: $su / 2 $su;
    
    &:hover {
      border-color: #b6b6b6 !important;
    }
  }
  
  .btn {
    width: 100%;
    text-align: center;
  }
  
  .btn--blue {
    border: solid 2px $white;
  }
  
  .errors {
    padding-bottom: $su;
    
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    
    li {
      color: $red;
    }
  }
  
  p.note {
    margin-top: $su;
    font-weight: 700;
  }
}



//
// Registration
//

.box--auth-register {
  label.profile-type {
    border: solid 2px $white;
    color: $gray-blue;
    text-align: center;
    margin-bottom: $su;    
    padding: $su / 2;
    display: block;
    cursor: pointer;
    
    &:hover {
      border-color: $pale-blue;
    }
    
    &.active {
      border-color: $bright-blue !important;
    }
    
    span {
      @include font-size(17px);
      font-weight: 700;     
      margin-bottom: $su / 4;
      display: block;
    }
    
    input[type="radio"] {
      display: none;
    }
  }
  
  label.conditions {
    color: mid-gray;
    cursor: pointer;
    
    input[type="checkbox"] {
      margin-right: 10px;
    }
  }
}



//
// Upgrade from the old site
//

.box--auth-upgrade {
  max-width: 800px;
  
  h1 {
    margin: 2 * $su 0;
  }
  
  form {
    max-width: 400px;
    margin: 2 * $su auto;
    display: block;
  }
}