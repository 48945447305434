a {
	color: $link-color;
	transition: 0.3s;
	text-decoration: none;

	/* on hover */
	&:hover, &:focus {
		color: $link-hover;
	}

	[class^="icon-"], [class*=" icon-"] {
		padding-right: halve(quarter($su));
		vertical-align: middle;
	}


}

.link--plain,
%link--plain {
	border-bottom: none;
	text-decoration: none;

	&:hover, &:focus {
		text-decoration: none;
		border-bottom: none !important;
	}
}

.link--white {
	color: $white;

	&:hover, &:focus {
		color: $white;
	}
}

.link-action {
  padding: 2px halve($su);
  cursor: pointer;
  display: inline-block;
}

.link-action--edit {
  color: $white;
  background-color: $bright-blue;
  
  &:hover {
    background-color: $hover-blue;
    color: $white;
  }
}

.link-action--delete {
  color: $red;
  
  &:hover {
    background-color: $red;
    color: $white;
  }
}

.link-action--soft-delete {
  color: $gray;
  padding: quarter($su) halve($su) quarter($su) 25px;
  transition: none;
  background: none;
  border: none;
  
  &:hover {
    color: $red;
    background: url('../../img/icon-error.gif') no-repeat 5px 50%;
  }
}