.box {
	 background: $white;
	 padding: $su;
	 margin-bottom: $su / 2;

 	> :last-child {
 	 	margin-bottom: 0;
	 }
}

.box--blue {
  background-color: $light-blue;
}

.box--transparent-blue {
  background-color: rgba(0, 136, 251, 0.75);
  color: $white;
}

.box.menu {
  padding: 0;
  @include font-size(15px);
  font-weight: 300;
  background-color: transparent;
  margin-bottom: 1px;
  
  .heading {
    background-color: rgba(255, 255, 255, 0.3);
    padding: 0 $su 0 4 * $su;
    margin: 0;
    color: $white;
    font-weight: 700;
    height: 56px;
    line-height: 56px;
    cursor: pointer;
    
    &:hover {
      background-color: rgba(0, 139, 220, 0.3);
    }
    
    &.menu--profile {
      background-image: url('../../img/icon-activity-menu-profile@2x.gif');
      background-repeat: no-repeat;
      background-position: 0 center;
      background-size: 56px 56px;
    }
    &.menu--settings {
      background-image: url('../../img/icon-activity-menu-settings@2x.gif');
      background-repeat: no-repeat;
      background-position: 0 center;
      background-size: 56px 56px;
    }
    &.menu--messaging {
      background-image: url('../../img/icon-activity-menu-messaging@2x.gif');
      background-repeat: no-repeat;
      background-position: 0 center;
      background-size: 56px 56px;
    }
    &.menu--activity {
      background-image: url('../../img/icon-activity-menu-activity@2x.gif');
      background-repeat: no-repeat;
      background-position: 0 center;
      background-size: 56px 56px;
    }
  }
  
  .content {
    display: none;
    padding-top: 1px;
  }
  
  &.menu--active {
    .heading {
      background-color: $pale-blue;
      color: $black;      
    
      &.menu--profile {
        background-image: url('../../img/icon-activity-menu-profile@2x.png');
      }
      &.menu--settings {
        background-image: url('../../img/icon-activity-menu-settings@2x.png');
      }
      &.menu--messaging {
        background-image: url('../../img/icon-activity-menu-messaging@2x.png');
      }
      &.menu--activity {
        background-image: url('../../img/icon-activity-menu-activity@2x.png');
      }
    }
  }
  
  &.menu--open {    
    .heading {
      background-color: $pale-blue;
      color: $black;
    
      &.menu--profile {
        background-image: url('../../img/icon-activity-menu-profile@2x.png');
      }
      &.menu--settings {
        background-image: url('../../img/icon-activity-menu-settings@2x.png');
      }
      &.menu--messaging {
        background-image: url('../../img/icon-activity-menu-messaging@2x.png');
      }
      &.menu--activity {
        background-image: url('../../img/icon-activity-menu-activity@2x.png');
      }
    }
  }
  
  ul {
    background-color: $white;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      @include font-size(13px);
      padding: 1px 0;

      a {        
        color: $menu-color;
        display: block;
        padding: 0 halve($su);
        height: 44px;
        line-height: 44px;
        transition: none;
        
        &:hover {
          background-color: $menu-hover-color;
          background-image: url('../../img/menu-hover.gif');
          background-repeat: no-repeat;
          background-position: right center;
        }
      }
      
      &.selected {
        a, a:hover, a:focus {
          background-color: $menu-selected-color;
          background-image: url('../../img/menu-on.gif');
          background-repeat: no-repeat;
          background-position: right center;
          background-size: auto 100%;
          color: $white;
          /*font-weight: 700;*/
          font-weight: 400;
          @include font-size(15px);
          line-height: 44px;
        }
      }
    }

    li + li {
      border-top: solid 1px $silver;
    }
  }
}

.box.filters {
  padding: 0;
  @include font-size(15px);
  font-weight: 300;
  background-color: transparent;
  margin-bottom: 0;
}

.box--info {
  padding-left: 3 * $su;
  background-image: url('../../img/icon-info.png');
  background-repeat: no-repeat;
  background-position: $su $su;
}

.box--success {
  @extend .floating-box;
  text-align: center;
  background: url('../../img/checkin-tick-black.png') no-repeat center 2 * $su $white;
  padding-top: 4.5 * $su;
  padding-bottom: 2 * $su;
  
  h1 {
    @include header-font-bold();
    @include font-size(41px);
    line-height: 1.1em;
    text-transform: uppercase;
  }
}

.box--activity {
  padding: $su;
}

.box--no-results {
  text-align: center;
  padding: 2 * $su 0;
  
  p {
    @include font-size(17px);
  }
}

.box--membership {
  padding: 0;
  
  .content {
    padding: $su;
  }
  
  .alternative {
    background-color: #f6fefa;
  }
}

.box--error {
  opacity: 0.8;
  padding-bottom: $su * 3;
  text-align: center;
  
  .links {
    margin-top: $su * 3;
    
    a {
      @include font-size(20px);      
      
      img {
        margin-bottom: $su;
      }
    }
  }
  
  p.link {
    @include font-size(20px);
    line-height: 1.6;
    padding: $su * 3;
  }
}