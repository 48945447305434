html {
	 font-family: $font--regular;
  background-image: url('../../img/bg-html.jpg');
  background-color: $html-bg; 
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  
  &.error {
    background-image: url('../../img/404.jpg');
  }
}

body {
	color: $font-color;
}