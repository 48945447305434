form.stripe-form {
  .stripe-entry {
    padding-bottom: $su-small;
  }
  
  label {
    padding-left: 4px;
    margin-bottom: 4px;
  }
  
  input[type="text"], .stripe-input {
    width: 100%;
    @body-font();
    color: $font-color;
    background-color: $white;
    padding: 7px 10px;
    border: solid 1px $gray;
    border-radius: 5px;
    font-size: 16px;
    line-height: 24px;
    display: block;
    
    &::placeholder {
      color: #475d7a;
      -webkit-text-fill-color: currentColor;
    }
        
    &.error {
      color: $rust;
      border-color: $rust;
    }
  }
}

.stripe-errors {
  color: $rust;
  display: none;
  
  &.visible {
    display: block;
  }
}

#StripeFormContainer {
  padding-bottom: $su;
  position: relative;
  
  .overlay {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding-top: 100px;
    display: none;
  }
}